import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AlarmOptions, IAlarmSearchRequest } from '../browse/alarmpage';
import { AppState } from '../core';
import { StatusForApiCall } from '../core/apiCall';
import { convertListOfPlantObjectsToListOfPlantIds } from '../core/components/Header';
import { IPlantOverview } from '../plant';

export const useServer = () => useSelector((state: AppState) => state.settings.server);
export const useServerAPI = () => useServer() + '/api';
export const useServerOdata = () => useServer() + '/odata';

export const useApiUrl = () => useServer() + '/api';

export const useRedirectUrl = () => useSelector((state: AppState) => state.settings.redirectUrl);

export const useLocale = () => useSelector((state: AppState) => state.settings.locale);
export const useLocales = () => useSelector((state: AppState) => state.settings.locales);

export const useCurrentPlant = () => useSelector((state: AppState) => state.settings.currentPlant);

export const useCurrentOperator = () => useSelector((state: AppState) => state.settings.currentOperator);

export const useFetchActiveAlarmsFromCosmosDB = (plants: IPlantOverview[], url: string, interval: number) => {
  const [data, setData] = useState([]);
  const [status, setApiCallStatus] = useState<StatusForApiCall>(StatusForApiCall.LOADING);
  const [error, setError] = useState();

  var plantsToString = convertListOfPlantObjectsToListOfPlantIds(plants);

  var payload: IAlarmSearchRequest = {
    plants: [...plantsToString],
    searchText: '',
    locale: 'ENG',
    fetchTotalCount: true,
    pagination: {
      pageNumber: 0,
      pageSize: 3,
    },
    isActive: AlarmOptions.ActiveAlarms,
  };

  const fetchData = () =>
    axios
      .post(url, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        setApiCallStatus(StatusForApiCall.SUCCESS);
        setData(response.data);
      })
      .catch(err => {
        setError(err);
        setApiCallStatus(StatusForApiCall.FAILURE);
      });

  useEffect(() => {
    if (plants.length > 0) {
      fetchData();

      const intervalId = setInterval(() => {
        fetchData();
      }, interval);

      return () => clearInterval(intervalId)
    }
  }, [plants]);

  return { status, data, error };
};
