import React from "react";
import { useParams } from "react-router-dom";
import { SettingList } from "../components/SettingList";
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";

import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid, Paper } from "@mui/material";
import { translationWithCapitalization } from "../../core";

const AccountSettingsPage = () => {
  const {i18n} = useTranslation()
  const changeLanguage  = (language:any) =>{
    i18n.changeLanguage(language)
  }


  return (<Grid container justifyContent={"space-between"} >
      <Grid  
            item
            container xs={12} 
            md = {9} 
            direction="row"
            justify="flex-start"
            alignItems="flex-start">
          <Grid item xs = {12}  component = {Paper} 
          sx  = {{
            padding:"50px"
          }}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            value={i18n.language}
            onChange={(e)=>{changeLanguage(e.target.value)} }
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value={"en"}>English</MenuItem>
            <MenuItem value={"nno"}>Nynorsk</MenuItem>
            <MenuItem value={"nob"}>Bokmål</MenuItem>
          </Select>
        <FormHelperText>{translationWithCapitalization("languages")}</FormHelperText>
      </FormControl>
    </Grid>
    </Grid>
  </Grid>

  );
};


export { AccountSettingsPage };
