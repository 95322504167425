import { useSelector } from "react-redux";
import { AppState } from "../core";

export const useUserPlants = () =>
  useSelector((state: AppState) => state.user.plants);

export const useUser = () => useSelector((state: AppState) => state.user);

export const usePlantsForOperationRoles = () => useSelector((state: AppState) => state.user.plantsForOperationsRole);

export const useRoles = () => useSelector((state: AppState) => state.user.roles);

export const useAPIIsConnected = () => useSelector((state: AppState) => state.user.roles);

export const useUserHasLoaded = () => useSelector((state: AppState) => state.user.loading);

export const useAzureMapsKey = () => useSelector((state: AppState) => state.user.azureMapsKey);

export const useUserRoles = () => useSelector((state: AppState) => state.user.userRoles);

export const selectRolesFromUserRoles = () => useSelector((state: AppState) => state.user.userRoles);

export const selectCustomers = () => useSelector( (state: AppState)=> state.user.customers  );