import React, { useCallback, useState, useMemo, useEffect } from 'react';

import axios from 'axios';
import { isNil } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Box, Paper, Button, Popover, IconButton, Typography, Grid, Tabs } from '@mui/material';

import { IUserToCreate } from './interface';
import { useApiUrl, useRedirectUrl } from '../../settings/hook';
import { StyledButton } from './components/TabPanel';
import { translationWithUppercase } from '../../core';
import { ExpandableTable } from '../Components/tables';
import { USER_MANAGEMENT_ENDPOINTS } from './endpoints';
import { UserView } from './components/showAllUsersTable';
import { AddUserDialog } from './table/dialog/addUserDialog';
import { PlantUsersTableWrapper } from './components/plantUsersTable';

export const  UserManagementPage = ()=>{
  
  const [allUsers, setAllUsers] = useState([]);
  const [customersForSuperuser, setCustomersForSuperuser] = useState([]);
  const [loading, setLoading] = useState(false);

  const redirectUrl = useRedirectUrl();

  const [tabValue, setTabValue] = useState(0);

  const apiURL = useApiUrl();

  const PLANTS = translationWithUppercase("plants")
  const USERS = translationWithUppercase("users")

  const handleAddUserClick = (data:IUserToCreate)=>{

    setLoading(true);

    var postData = {
      email:data.email,
      firstName:data.givenName,
      lastName:data.familyName,
      returnUrl:redirectUrl
    }

    axios.post(`${apiURL}/Access/user`,  
    postData,
    {
      headers: {
      'Content-Type': 'application/json'
      }
  })
  .then((response) =>
  {
    setAllUsers(response.data.value);
  })
  .catch((error) =>
   {
    alert(error);
   } )
   .finally(()=>setLoading(false));
  }

  const handleTabChange = (event:any, newValue:any)=>{
    setTabValue(newValue);
  }

   useEffect( ()=>{

    axios
      .get(`${apiURL}${USER_MANAGEMENT_ENDPOINTS.GET_ALL_USERS}`)
      .then( response => { 
      setAllUsers(response.data)})
      setLoading(true)
    axios
      .get(`${apiURL}${USER_MANAGEMENT_ENDPOINTS.GET_ALL_CUSTOMERS_WITH_PLANTS_FOR_SUPERUSER}`)
      .then( response => { 
        setCustomersForSuperuser(response.data)
        setLoading(false)
      })

   },[apiURL] )

      return (  
      <Grid container  
      >
        <Grid
          item
          container 
          xs={12} 
          direction="row"
          justify="flex-start"
          alignItems="flex-start">

          <Grid item xs = {12}  
            component = {Paper} sx = {{
            padding:"50px",
            overflow:'auto',
            height: '50vw'
          }} >
            <Grid container xs = {12} item  justifyContent="end">
              <Grid item >

                {allUsers.length>0&&<AddButton
                  customersList = {customersForSuperuser}
                  handleAddUserClick = {handleAddUserClick}
                />}
              </Grid>
            </Grid>
            
           <Grid
           item
           xs = {12}
           >
               <Tabs
                value={tabValue}
                onChange={handleTabChange}
                sx = {{
                  justifyContent:"space-between"
                }}
            >
                <StyledButton handleClick={setTabValue} tabValue={tabValue} displayText={PLANTS} activeValue={0} />
                <StyledButton handleClick={setTabValue} tabValue={tabValue} displayText={USERS} activeValue={1} />
              </Tabs>
            <TabPanel value={tabValue} index={0} >
              {loading?<span>loading</span>:<PlantView customersForSuperuser = {customersForSuperuser}/>}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <UserView users={allUsers}/>
            </TabPanel>
           </Grid>
          </Grid>
        </Grid>
    </Grid>    
      )
  }


const TabPanel = (props:any) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      sx = {{marginLeft:"auto"}}
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

const PlantView = (props:any)=>{

  const {customersForSuperuser} = props;
    const customerColumns = useMemo(
      ()=> [
        {
          Header: () => null,
          id: "expander",
          accessor: 'name',
          Cell: ({ row, value }) => (
              <Box alignItems="center" display="flex" {...row.getToggleRowExpandedProps()}>
                <IconButton
                  aria-label="expand row"
                  size="small"
              >
                 {!row.isExpanded ? (
                   <KeyboardArrowDownIcon/>
                  ) : (
                    <KeyboardArrowUpIcon/>
                  )}
                  </IconButton>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '21.3838px',
                        letterSpacing: '0.1em'
                      }}>
                        <span>{value.toUpperCase()}</span>
                    </Typography>
                </Box>
          )
        }
        
      ],[]
    )
  
      //Aka table with plants
      const plantTable = useCallback(
        ({ row }) =>
        customersForSuperuser.filter( p=> p.id === row.original.id).length > 0 ? (
          <PlantUsersTableWrapper 
            data={Array.isArray(customersForSuperuser.filter( p=> p.id === row.original.id)[0].plants) &&  customersForSuperuser.filter( p=> p.id === row.original.id)[0].plants}
          />
         ) : (
           "NO DATA"
         ),
       []
     );
  
      // Plant and user view
      const CustomerTable = ()=> !isNil(customersForSuperuser)&&customersForSuperuser.length>=1?
        <ExpandableTable
            expandRows
            showSearchFilter
            columns = {customerColumns}
            renderRowSubComponent = {plantTable}
            data = {customersForSuperuser} />
        :<span>NO Data</span>

  return <CustomerTable/>
}

export const AddButton = (props: any)=>{
  const {handleAddUserClick} = props;
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);

  const handleAddUserDialogClose = ()=>{ setAddUserDialogOpen(false)}

  const INVITE_USER = translationWithUppercase("inviteUser");

  return <>
      <Button 
        onClick = {()=>{setAddUserDialogOpen(!addUserDialogOpen)}}
        sx = {{marginLeft:"auto", backgroundColor:"#D2E5EB", paddingLeft:"50px", paddingRight:"50px", paddingTop:"10px", paddingBottom:"10px"}}>
          <AddIcon/>
          {INVITE_USER}
      </Button>

      <AddUserDialog
        open={addUserDialogOpen}
        handleClick = {handleAddUserClick}
        handleClose= {handleAddUserDialogClose}
        customersList={props.customersList}
        plantsList={props.plantsList}
      /> 
</>
}