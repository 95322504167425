import { CircularProgress, Typography } from "@mui/material";
import React from "react";
import { translationWithCapitalization } from "../../../core";
import { useApiUrl } from "../../../settings/hook";
import { useUser } from "../../../user/hooks";
import { useGetInvoices } from "../../finance/hooks/useInvoicesForPlants";
import { SummaryCard } from "./SummaryCard";

export const FinanceSummary = ()=>{
  const lastTransactions = translationWithCapitalization("lastTransactions");
  const plants = useUser().plants;
  const URL = useApiUrl();

  const {invoices, error, loading:invoicesAreLoading} = useGetInvoices(URL, plants);

  const finance = translationWithCapitalization("finance");

 return (<SummaryCard>
     {invoicesAreLoading&&<CircularProgress/>} 
     {!invoicesAreLoading&&<>
      <Typography gutterBottom variant="h5" component="div">
        {finance} 
      </Typography>
      <Typography variant="body2" color="text.primary">
        {lastTransactions}: {<br/>}
        {
        invoices.length>0&&
        invoices.slice(0,5).map( (invoice:any, key:number) =><span key = {key}>
            {invoice.shipName}, {invoice.sessionEnd},  <b>{invoice.plant}</b> - {invoice.line}
            {<br/>}
          </span>
        )}
      </Typography>
      <Typography variant="body2" color="text.primary">
      </Typography>
      </>}
      </SummaryCard>)
}