export const DEAD_LINE = {
    "name": "Dead line",
    "type": "Data",
    "prototype": [
        "ConnectedShip"
    ],
    "tags": [],
    "tag": "",
    "branchTag": "=Missing-Data",
    "alarm": null,
    "command": null,
    "commandInfo": null,
    "alarmInfo": null,
    "properties": [
        {
            "name": "IMONumber",
            "value": {
                "value": "",
                "ok": true,
                "dis": false,
                "cat": null,
                "ts": "2022-11-02T17:49:54.3346261Z"
            },
            "details": {
                "symbol": null,
                "type": "String",
                "isStatic": false,
                "isLive": true,
                "hasHistorical": true,
                "visibility": "Public",
                "sortOrder": 0,
                "texts": [
                    {
                        "id": "Description",
                        "t": {
                            "def": "IMO nummer",
                            "ENG": "IMO Number"
                        }
                    }
                ],
                "categories": {}
            }
        },
        {
            "name": "Name",
            "value": {
                "value": "",
                "ok": true,
                "dis": false,
                "cat": null,
                "ts": "2022-11-02T17:49:54.3346261Z"
            },
            "details": {
                "symbol": null,
                "type": "String",
                "isStatic": false,
                "isLive": true,
                "hasHistorical": false,
                "visibility": "Public",
                "sortOrder": 1,
                "texts": [
                    {
                        "id": "Description",
                        "t": {
                            "def": "Navn",
                            "ENG": "Name"
                        }
                    }
                ],
                "categories": {}
            }
        },
        {
            "name": "IsRegistered",
            "value": {
                "value": 0,
                "ok": true,
                "dis": false,
                "cat": null,
                "ts": "2022-11-02T17:49:54.3346261Z"
            },
            "details": {
                "normalValue": false,
                "symbol": null,
                "type": "Digital",
                "isStatic": false,
                "isLive": true,
                "hasHistorical": true,
                "visibility": "Details",
                "sortOrder": 2,
                "texts": [
                    {
                        "id": "Description",
                        "t": {
                            "def": "Er Registrert",
                            "ENG": "Is Registered"
                        }
                    }
                ],
                "categories": {}
            }
        },
        {
            "name": "MeterStart",
            "value": {
                "value": 0,
                "ok": true,
                "dis": false,
                "cat": null,
                "ts": "2022-11-02T17:49:54.3346261Z"
            },
            "details": {
                "min": null,
                "max": null,
                "unitId": "kWh",
                "unit": {
                    "def": "kWh"
                },
                "displayFormat": ".2f",
                "systemSetpointAccessName": null,
                "setpointAccessName": null,
                "optimalMaxAccessName": null,
                "optimalMinAccessName": null,
                "tripHighAccessName": null,
                "tripLowAccessName": null,
                "alarmLimitLowAccessName": null,
                "alarmLimitLowLowAccessName": null,
                "alarmLimitHighAccessName": null,
                "alarmLimitHighHighAccessName": null,
                "alarmLowLowObject": null,
                "alarmLowObject": null,
                "alarmHighObject": null,
                "alarmHighHighObject": null,
                "symbol": null,
                "type": "Float",
                "isStatic": false,
                "isLive": true,
                "hasHistorical": true,
                "visibility": "Details",
                "sortOrder": 2,
                "texts": [
                    {
                        "id": "Description",
                        "t": {
                            "def": "Målt Energi ved Start",
                            "ENG": "Meter at start"
                        }
                    }
                ],
                "categories": {}
            }
        },
        {
            "name": "MeteredEnergy",
            "value": {
                "value": 0.0,
                "ok": true,
                "dis": false,
                "cat": null,
                "ts": "2022-11-03T01:59:37.5995262Z"
            },
            "details": {
                "min": null,
                "max": null,
                "unitId": "kWh",
                "unit": {
                    "def": "kWh"
                },
                "displayFormat": ".2f",
                "systemSetpointAccessName": null,
                "setpointAccessName": null,
                "optimalMaxAccessName": null,
                "optimalMinAccessName": null,
                "tripHighAccessName": null,
                "tripLowAccessName": null,
                "alarmLimitLowAccessName": null,
                "alarmLimitLowLowAccessName": null,
                "alarmLimitHighAccessName": null,
                "alarmLimitHighHighAccessName": null,
                "alarmLowLowObject": null,
                "alarmLowObject": null,
                "alarmHighObject": null,
                "alarmHighHighObject": null,
                "symbol": null,
                "type": "Float",
                "isStatic": false,
                "isLive": true,
                "hasHistorical": true,
                "visibility": "Public",
                "sortOrder": 1,
                "texts": [
                    {
                        "id": "Description",
                        "t": {
                            "def": "Målt Energi",
                            "ENG": "Metered energy"
                        }
                    }
                ],
                "categories": {}
            }
        },
        {
            "name": "HourMeterStart",
            "value": {
                "value": 0,
                "ok": true,
                "dis": false,
                "cat": null,
                "ts": "2022-11-02T17:49:54.3346261Z"
            },
            "details": {
                "min": null,
                "max": null,
                "unitId": "h",
                "unit": {
                    "def": "h"
                },
                "displayFormat": ".1f",
                "systemSetpointAccessName": null,
                "setpointAccessName": null,
                "optimalMaxAccessName": null,
                "optimalMinAccessName": null,
                "tripHighAccessName": null,
                "tripLowAccessName": null,
                "alarmLimitLowAccessName": null,
                "alarmLimitLowLowAccessName": null,
                "alarmLimitHighAccessName": null,
                "alarmLimitHighHighAccessName": null,
                "alarmLowLowObject": null,
                "alarmLowObject": null,
                "alarmHighObject": null,
                "alarmHighHighObject": null,
                "symbol": null,
                "type": "Float",
                "isStatic": false,
                "isLive": true,
                "hasHistorical": true,
                "visibility": "Details",
                "sortOrder": 2,
                "texts": [
                    {
                        "id": "Description",
                        "t": {
                            "def": "Timeteller ved Start",
                            "ENG": "Operating hours at start"
                        }
                    }
                ],
                "categories": {}
            }
        },
        {
            "name": "MeteredHours",
            "value": {
                "value": 0.0,
                "ok": true,
                "dis": false,
                "cat": null,
                "ts": "2022-11-03T01:59:37.5995262Z"
            },
            "details": {
                "min": null,
                "max": null,
                "unitId": "h",
                "unit": {
                    "def": "h"
                },
                "displayFormat": ".1f",
                "systemSetpointAccessName": null,
                "setpointAccessName": null,
                "optimalMaxAccessName": null,
                "optimalMinAccessName": null,
                "tripHighAccessName": null,
                "tripLowAccessName": null,
                "alarmLimitLowAccessName": null,
                "alarmLimitLowLowAccessName": null,
                "alarmLimitHighAccessName": null,
                "alarmLimitHighHighAccessName": null,
                "alarmLowLowObject": null,
                "alarmLowObject": null,
                "alarmHighObject": null,
                "alarmHighHighObject": null,
                "symbol": null,
                "type": "Float",
                "isStatic": false,
                "isLive": true,
                "hasHistorical": true,
                "visibility": "Public",
                "sortOrder": 1,
                "texts": [
                    {
                        "id": "Description",
                        "t": {
                            "def": "Timeteller",
                            "ENG": "Operating hours"
                        }
                    }
                ],
                "categories": {}
            }
        },
        {
            "name": "IsConnected",
            "value": {
                "value": 0,
                "ok": false,
                "dis": false,
                "cat": null,
                "ts": "2022-11-02T17:49:54.3346261Z"
            },
            "details": {
                "normalValue": false,
                "symbol": null,
                "type": "Digital",
                "isStatic": false,
                "isLive": true,
                "hasHistorical": true,
                "visibility": "Details",
                "sortOrder": 2,
                "texts": [
                    {
                        "id": "Description",
                        "t": {
                            "def": "Er Tilkoblet",
                            "ENG": "Is Connected"
                        }
                    }
                ],
                "categories": {}
            }
        },
        {
            "name": "SessionDuration",
            "value": {
                "value": 0.0,
                "ok": true,
                "dis": false,
                "cat": null,
                "ts": "2022-11-03T01:59:07.2514549Z"
            },
            "details": {
                "min": null,
                "max": null,
                "unitId": "h",
                "unit": {
                    "def": "h"
                },
                "displayFormat": ".1f",
                "systemSetpointAccessName": null,
                "setpointAccessName": null,
                "optimalMaxAccessName": null,
                "optimalMinAccessName": null,
                "tripHighAccessName": null,
                "tripLowAccessName": null,
                "alarmLimitLowAccessName": null,
                "alarmLimitLowLowAccessName": null,
                "alarmLimitHighAccessName": null,
                "alarmLimitHighHighAccessName": null,
                "alarmLowLowObject": null,
                "alarmLowObject": null,
                "alarmHighObject": null,
                "alarmHighHighObject": null,
                "symbol": null,
                "type": "Float",
                "isStatic": false,
                "isLive": true,
                "hasHistorical": true,
                "visibility": "Public",
                "sortOrder": 1,
                "texts": [
                    {
                        "id": "Description",
                        "t": {
                            "def": "Varighet for Opphold",
                            "ENG": "Session duration"
                        }
                    }
                ],
                "categories": {}
            }
        },
        {
            "name": "PointOfContact",
            "value": {
                "value": "",
                "ok": true,
                "dis": false,
                "cat": null,
                "ts": "2022-11-02T17:49:54.3346261Z"
            },
            "details": {
                "symbol": null,
                "type": "String",
                "isStatic": false,
                "isLive": true,
                "hasHistorical": true,
                "visibility": "Public",
                "sortOrder": 1,
                "texts": [
                    {
                        "id": "Description",
                        "t": {
                            "def": "Kontakt",
                            "ENG": "Point of Contact"
                        }
                    }
                ],
                "categories": {}
            }
        },
        {
            "name": "PointOfContactPhone",
            "value": {
                "value": "",
                "ok": true,
                "dis": false,
                "cat": null,
                "ts": "2022-11-02T17:49:54.3346261Z"
            },
            "details": {
                "symbol": null,
                "type": "String",
                "isStatic": false,
                "isLive": true,
                "hasHistorical": true,
                "visibility": "Public",
                "sortOrder": 1,
                "texts": [
                    {
                        "id": "Description",
                        "t": {
                            "def": "Telefonnummer",
                            "ENG": "PoC Phone number"
                        }
                    }
                ],
                "categories": {}
            }
        }
    ],
    "texts": [
        {
            "id": "Description",
            "t": {
                "def": "Tilkoblet skip",
                "ENG": "Connected ship"
            }
        }
    ],
    "contextCommands": [],
    "alarms": [],
    "homeNav": null,
    "visibility": "Public",
    "sortOrder": 0

}