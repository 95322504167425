 export const hasRoleName = function (roleNameList:any[], roleName:string):boolean{
    const filtered = roleNameList.filter( (r:any)=> r["roleName"] === roleName);
    return filtered.length>0
}

// Returns roles in GUI based on logical roles from AD/API 
// TODO: Remove this, and move the responsibility of filtering and presenting the view-model to the API
export const setRoles = function (userRolesFromAPI:any[])  {
    const roles = [];
    const hasFinanceRole = hasRoleName(userRolesFromAPI,"Finance")

    const hasOperationsRole = hasRoleName(userRolesFromAPI,"Operations")

    const hasSuperuserRole = hasRoleName(userRolesFromAPI,"Superuser")

    if(hasSuperuserRole){
       roles.push( 
        {name:"operations", id:"operations"},
        {name:"superuser", id:"superuser"},
        // {name:"utilization", id:"utilization"},
        // {name:"energy", id:"energy"},
        // {name:"emission", id:"emission"},
        {name:"finance", id:"finance"},
        {name:"shipRegistry", id:"shipRegistry"}
        )
    }

    if(hasFinanceRole&&!hasSuperuserRole){
        roles.push( 
         {name:"finance", id:"finance"}
         )
     }

     if(hasOperationsRole&&!hasSuperuserRole){
        roles.push( 
            {name:"operations", id:"operations"},
            // {name:"utilization", id:"utilization"},
            // {name:"energy", id:"energy"},
            // {name:"emission", id:"emission"},
            {name:"shipRegistry", id:"shipRegistry"}
         )
     }

    return roles
 }

  interface CustomerDTO {
  name: string
  id: string
 }

 interface ShipLines {
  lineId: string
  name: string
  signal: string
 }

 interface PlantDTO {
  id: string
  name: string
  shortName: string
  customer: CustomerDTO
  lat: string
  long: string
  shipLines: ShipLines
 }

 interface CustomerPlantDTO {
  id: string
 }
 interface CustomerPlantsDTO {
  customers: CustomerPlantDTO
 }

 export const groupPlantsByCustomer = (plants: PlantDTO[]) => {
    plants.map((plant: PlantDTO) => {

    })
  };