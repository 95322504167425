import React, { FC, useEffect, useState } from "react"
import { useDispatch } from "react-redux";

import axios from "axios";
import * as yup from 'yup';
import CheckIcon from '@mui/icons-material/Check';
import { FormHelperText } from "@material-ui/core";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from "react-hook-form";
import ReactSelect from "react-select";

import { Button, CircularProgress, Box, Icon, Checkbox, TextField, FormGroup, FormLabel, IconButton, Typography, DialogActions, FormControlLabel} from '@mui/material'

import { useApiUrl } from "../../../../settings/hook";
import { USER_MANAGEMENT_ENDPOINTS } from "../../endpoints";
import manageUserIcon from '../../../../../assets/icons/manageUser.svg';
import { ConfirmationDialogWithIcon } from "../../../Components/dialog";
import { translationWithCapitalization } from "../../../../core";


const selectStyles = {
  menuList: styles => {
    return {
      ...styles,
      maxHeight: 136
    };
  }
};

  const schema = yup.object({
    user: yup.string().required('You must select a user'),
    roles: yup.array().min(1).required('You must select at least one role')
  })

const AddUserToPlantForm = (props:any)=> {

  const {data, handleClose, handleModalClicked } = props;

  const apiURL = useApiUrl();

  const [selectedRoles, setSelectedRoles] = useState([])

    const [roleDefinitions, setRoleDefinitions] = useState([])
    const onError = (errors, e) => console.log(errors, e);
    const [userList, setUsersList] = useState([])
    const [userListFormatted, setUserListFormatted] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [responseMessage, setResponseMessage] = useState("")



    const SEARCH_FOR_USER = translationWithCapitalization("searchForUser");

    useEffect( ()=>{
      axios
          .get(`${apiURL}${USER_MANAGEMENT_ENDPOINTS.GET_ALL_USERS}`)
          .then( response => { 
            setUsersList(response.data)})

            axios
            .get(`${apiURL}${USER_MANAGEMENT_ENDPOINTS.GET_ALL_ROLE_DEFINITIONS}`)
            .then( response => { 
              setRoleDefinitions(response.data)})


     },[apiURL] )
  

    const onSubmit = (input, e) => {
      
      const URL = `${apiURL}/Access/${input.user}/upsertPlantAccess`;
      
      setIsLoading(true)
  
      axios.post(URL, {'plantId':data.id, 'roleIds':input.roles})
      .then( (response) => {
        setResponseMessage(`User has been added to ${data.name}`)
        setIsLoading(false)
        setTimeout(handleModalClicked()  , 6000 );
        handleClose();
      })
      .catch( (error) => {
        setResponseMessage(`Error adding user to plant`)
        setIsLoading(false)

      });

    }

    
    useEffect( ()=> {

      if(userList.length>0){
        var uList = []
        userList.map( i=>{
          uList.push({ value: i.id, label: ` ${i.firstName} - ${i.lastName} - ${i.email}`  })
        } )
        setUserListFormatted(uList)

      }

    },[userList])

    const { 
      control,
      register, 
      handleSubmit, 
      watch, 
      reset,
      resetField,
      setValue,
      getValues,
      formState: { errors}
    } = useForm({
      resolver:yupResolver(schema)
    });
  
  return <div>
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
    >
    <div>
      <div style = {{
        alignItems:"center",
        display:"flex",
        fontWeight: '700',
        gap:'15px'
      }}> 
        <Icon>
          <CheckIcon/>
        </Icon>
        <Typography sx = {{display: 'inline-block'}}>
          {data.name}
        </Typography>
      </div>
    </div>
    <div
      style = {{
        display:"flex",
        flexWrap: "wrap",
        gap:'15px'
      }}
        >
           <br/>
           <div style={{width: '350px'}}>
           <Controller
              {...register("user")}
              render={({ field: { onChange, value, name, ref } }) => {
              return (
                <ReactSelect
                  placeholder = {SEARCH_FOR_USER}
                  styles = {selectStyles}
                  onChange={(e) => 
                    {
                      setValue('user', e.value, { shouldValidate: true })
                    }}
                  value = {userListFormatted.find((c) => c.value === value)}
                  ref={ref}
                  isClearable
                  options={ userListFormatted || []}
                />
              );
            }}
            control={control}
            rules={{ required: true }}
          />
            <span>{errors.user && errors.user?.message && <FormHelperText> {errors.user.message} </FormHelperText>}</span>
          </div>
      </div>

      

       <div>
       <FormLabel >Roles</FormLabel>
        <FormGroup>
          <Controller
            name="roles"
            
            control={control}
            render={({ field }) => (
              <>
                {roleDefinitions.map((r) => (
                  <FormControlLabel
                  {...field}

                    key={r.id}
                    label={r.name}
                    control={
                      <Checkbox
                        
                        name={r.displayName}
                        value={r.id}
                        onChange={(e) => {
                          var l = [...selectedRoles]

                          // setValue('roles', l)
                          if(e.target.checked)
                          {
                            l.push(e.target.value)
                            setValue('roles', l)

                            setSelectedRoles(l);
                          }
                          else
                          {
                            const f = l.findIndex( x=> x === e.currentTarget.value)
                            l.splice(f,1)
                            setSelectedRoles(l)
                          }
                        }}
                      />
                    }
                  />
                ))}
              </>
            )}
          />
            <span>{errors.roles && errors.roles?.message && <FormHelperText> {errors.roles.message} </FormHelperText>}</span>
            <span> {responseMessage}</span>
            {isLoading&& <CircularProgress />}
        </FormGroup>
      </div>

      <DialogActions>
          <Button variant="text" onClick={ handleClose } >Cancel</Button>
              <Button 
                variant="contained" 
                type="submit"
              >
                Add
          </Button>
      </DialogActions>
      </form>
    </div>
  }

  interface IAddUserToPlantDialog{
    data:any
    open:boolean,
    handleClose:Function,
    handleAddUser:Function,
    handleSubmit:Function,
    edit:boolean
  }


export const AddUserToPlantDialog: FC<IAddUserToPlantDialog> = (props:IAddUserToPlantDialog)=>{
    const { data, open, handleClose, edit, handleAddUser} = props;

    const icon=  manageUserIcon
    const titleText = "Add new user to plant"
    
    return <ConfirmationDialogWithIcon 
        sourceTitle={""}
        icon={icon}
        dialogTitle={titleText}
        open={open}
        handleClose={handleClose}
        warningText={""}
        explanatoryText={""}>
            <AddUserToPlantForm 
              handleClose = {handleClose}
              data = {data}
              handleModalClicked = {handleAddUser}
            />
        </ConfirmationDialogWithIcon>
}