import { useSelector } from "react-redux";
import { AppState } from "../../../core";

export const useEvents = () =>
  useSelector((state: AppState) => state.event.events);

export const useEventsAreLoading = () =>
  useSelector((state: AppState) => state.event.loading);

export const useEventsTotalCount = () =>
  useSelector((state: AppState) => state.event.totalCount);