import React, { FC, useState } from 'react';


import {Button, CircularProgress, Box, Icon, Checkbox, TextField, Dialog, FormGroup, FormLabel, IconButton, Typography, DialogActions, FormControlLabel} from '@mui/material'

import { isNil } from 'lodash';
import { IUserDTO } from '../../interface';
import { IConfirmationDialog } from '../../../Components/dialog/';
import { translationWithCapitalization } from '../../../../core';
import { t } from 'i18next';


export const DeleteUserDialog: FC<IConfirmationDialog> = (props:any, user:IUserDTO)=>{
    const {open, handleClose, handleSubmit, selectedUser, selectedPlant} = props;

    const [isLoading, setIsLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState("")

    const ARE_YOU_SURE_YOU_WANT_TO = translationWithCapitalization("areYouSureYouWantTo")
    const FROM = t("from");
    const REMOVE = translationWithCapitalization("remove");
    const CANCEL = translationWithCapitalization("cancel");

    const plantName= !isNil(selectedUser?.plantRoles?.plant?.name)?selectedUser?.plantRoles?.plant?.name: "-"

return <> <Dialog
              PaperProps=
                {{sx: {
                    borderRadius: "10px",
                    padding:"40px 100px 40px 100px",
                    display:'flex',
                    border: '1px solid #D8DCDD',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    justifyContent:'center'
                  }
                }}

              open={open}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              >

  <Typography>
    {ARE_YOU_SURE_YOU_WANT_TO}
  </Typography>
  <br/>
  <Typography sx={{ fontWeight: 'bold' }}>
      {REMOVE} {selectedUser?.firstName} {selectedUser?.lastName} {FROM} {plantName}?
  </Typography>
  <Typography sx={{ fontWeight: 'bold' }}>
      {statusMessage}
  </Typography>
          
<DialogActions>
  <Box>


  {isLoading ?  <CircularProgress /> : 
  <div 
  style = {{marginTop:"24px",
    display:"flex",
    justifyContent:"space-between",
    gap:"36px"}}>
      <Button 
          onClick={()=>
            {
              handleSubmit(selectedUser);
            }} variant="contained" color = "error" autoFocus 
          sx = {{
              backgroundColor:"#DA0000"
          }}
      >
          {REMOVE}
      </Button>
      <Button onClick={()=>{handleClose()}} variant="outlined" >
          {CANCEL}
      </Button>
      </div>}
  </Box>
  
</DialogActions>


</Dialog> </>;

   
}