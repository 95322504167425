

import React, { useState } from "react";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid, Icon, List, ListItemButton, ListItemText } from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import emissionIconBlue from "../../../../assets/icons/blue_Emission.svg";
import energyIconBlue from '../../../../assets/icons/blue_Energy.svg';
import financeIconBlue from "../../../../assets/icons/blue_Finance.svg";
import powerIconWhite from '../../../../assets/icons/blue_Power connect.svg';
import shipRegistryIconBlue from "../../../../assets/icons/blue_ship.svg";
import emissionIcon from "../../../../assets/icons/hvit_Emission.svg";
import energyIconWhite from '../../../../assets/icons/hvit_Energy.svg';
import financeIcon from "../../../../assets/icons/hvit_Finance.svg";
import powerIconGreen from '../../../../assets/icons/hvit_Power connect.svg';
import shipRegistryIconWhite from "../../../../assets/icons/hvit_ship.svg";
import utilizationIcon from "../../../../assets/icons/hvit_Utilization.svg";
import navigationIcon from "../../../../assets/icons/nav_hvit.svg";
import settingsIconWhite from '../../../../assets/icons/settings-white.svg';
import settingsIcon from '../../../../assets/icons/Settings.svg';
import "../../../../public/translations/i18n";
import { SettingsSetCurrentPlant } from "../../../settings";
import { useCurrentPlant } from "../../../settings/hook";
import { AppState } from "../../reducer";
import { translationWithUppercase } from "../../utilities";



const drawerWidth = 350;

const openedMixin = (theme: any) => ({
  width: drawerWidth,
  border: 'none',
  zIndex: theme.drawer.zIndex,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: 'hidden',
});

const closedMixin = (theme: any) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  border: 'none',
  zIndex: theme.drawer.zIndex,
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)})`,
  },
});


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexShrink: 0,
    zIndex: theme.drawer.zIndex,
    overflow: "hidden",
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export const SideBar = (props: any) => {
  const { rolesTransformed } = props;

  const dispatch = useDispatch();
  const plants = useSelector((state: AppState) => state.user.plants);
  const currentPlant = useCurrentPlant();
  const plantNameNotEmpty = !!currentPlant.plantId;
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const ALL = translationWithUppercase("all plants")
  const open = Boolean(anchorEl)
  const plantName = !plantNameNotEmpty ? ALL : currentPlant.plantName?.toUpperCase()
  const customerNameAndPlantName = plantName
  const hasSuperuserRole = rolesTransformed.some(i => i.id.toString() === "superuser");

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const onPlantClick = (plant: any) => {
    setAnchorEl(null);
    dispatch(new SettingsSetCurrentPlant({
      plantId: plant?.id,
      plantName: plant?.shortName
    }))
  }

    const onAllClick = () => {
    setAnchorEl(null);
    dispatch(new SettingsSetCurrentPlant({
      plantId: 'All',
      plantName: 'All'
    }))
  }

  return (<>
    <Drawer
      onMouseEnter={() => setDrawerOpen(true)}
      onMouseLeave={() => setDrawerOpen(false)}
      open={drawerOpen}
      variant='permanent'
    >
      <Box sx={theme => theme.mixins.toolbar} />

      <List
        sx={{
          paddingTop: '0px',
          paddingBottom: "0px",
        }}
      >
        <ListItemButton
          id="demo-positioned-menu"
          sx={{
            width: drawerWidth,
            padding: 0,
            letterSpacing: '0.1rem'
          }}
          component="button"
          onClick={(e: any) => {
            setAnchorEl(e.currentTarget)
          }}
        >
          {createRoleIconContent(customerNameAndPlantName, drawerOpen, open)}
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Menu
          id="demo-positioned-menu"
          anchorEl={anchorEl}
          aria-labelledby="demo-positioned-button"
          open={open}
          keepMounted
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <MenuItem
              key={'All'}
              sx={{
                textAlign: "center",
                paddingTop: "20px",
                paddingBottom: "20px",
                fontFamily: ["Open Sans"],
                letterSpacing: '0.1rem',
              }}
              onClick={() => onAllClick()}
            >
              <ListItemText>{'All Plants'}</ListItemText>
            </MenuItem>
          {plants.map((plant: any, key: number) => {
            return <MenuItem
              key={plant.id}
              sx={{
                textAlign: "center",
                paddingTop: "20px",
                paddingBottom: "20px",
                fontFamily: ["Open Sans"],
                letterSpacing: '0.1rem',
              }}
              onClick={() => onPlantClick(plant)}
            >
              <ListItemText>{plant?.name}</ListItemText>
            </MenuItem>
          })}
        </Menu>
        {rolesTransformed.length > 0 ?
          rolesTransformed.filter(r => r.id !== "superuser").map((role: any, key) => { return <span key={key}>{createRoleIconContent(role.name, drawerOpen, open)} </span> }) : ""
        }
        <Grid item sx={{
          width: "64px",
          backgroundColor: "primary.main", height: '55vh', zIndex: ""
        }}>

        </Grid>
        {hasSuperuserRole && createRoleIconContent(ROLE_NAMES.USER_MANAGEMENT, drawerOpen, open)}
        <Grid item sx={{
          width: "64px",
          "&:hover": {
            border: "1px solid #00FF00",
            color: 'gray',
            backgroundColor: 'lightblue'
          },
          backgroundColor: "primary.main", height: '50vh', zIndex: ""
        }}></Grid>

      </List>

      {/* Part of menu that are not icons (I.e long blue column) */}

      {/* <List>
        </List> */}
    </Drawer>
  </>)
}

// TODO: Replace with roles from server
enum ROLE_NAMES {
  FINANCE = "finance",
  UTILIZATION = "utilization",
  ENERGY = "energy",
  EMISSION = "emission",
  OPERATIONS = "operations",
  USER_MANAGEMENT = "usermanagement",
  SHIP_REGISTRY = "shipRegistry"
};

//TODO: Refactor so that we don't have several role-routes enums and objects
enum ROLE_ROUTES {
  finance = "/finance",
  utilization = "/utilization",
  energy = "/energy",
  emission = "/emission",
  operations = "/operations",
  usermanagement = "/usermanagement",
  shipRegistry = "/shipRegistry"
}

/**
 *
 * * Function with many if-s to set properties (color, font-size etc) conditionally
 * @param roleName - Name of the role
 * @param menuOpen -Whether the menu has been hovered over
 * @param open 
 * @returns 
 */
const createRoleIconContent = (roleName: string, menuOpen: boolean, open: any): any => {
  var listIcon: any = navigationIcon;
  var backgroundColor = "primary.main";
  var activeColor = "primary.light"
  var currentLocation = location.pathname.replace("/", "");
  var isOnRoleItem = currentLocation === roleName;

  if (roleName === ROLE_NAMES.OPERATIONS) {
    if (isOnRoleItem) {
      backgroundColor = '#e5f1f5'
      listIcon = powerIconWhite
    }
    else {
      listIcon = powerIconGreen
    }
  }

  if (roleName === ROLE_NAMES.USER_MANAGEMENT) {
    if (isOnRoleItem) {
      backgroundColor = '#e5f1f5'
      listIcon = settingsIcon
    }

    else {
      listIcon = settingsIconWhite
    }
  }


  else if (roleName === ROLE_NAMES.EMISSION) {
    if (isOnRoleItem) {
      backgroundColor = '#e5f1f5'
      listIcon = emissionIconBlue
    }
    else {
      listIcon = emissionIcon
    }
  }

  else if (roleName === ROLE_NAMES.FINANCE) {
    if (isOnRoleItem) {
      backgroundColor = '#e5f1f5'
      listIcon = financeIconBlue
    }
    else {
      listIcon = financeIcon
    }
  }
  else if (roleName === ROLE_NAMES.ENERGY) {

    if (isOnRoleItem) {
      backgroundColor = '#e5f1f5'
      listIcon = energyIconBlue
    }
    else {
      listIcon = energyIconWhite
    }
  }
  else if (roleName === ROLE_NAMES.UTILIZATION) {
    if (isOnRoleItem) {
      backgroundColor = '#e5f1f5'
      listIcon = shipIconBlue
    }
    else {
      listIcon = utilizationIcon
    }
  }

  else if (roleName === ROLE_NAMES.SHIP_REGISTRY) {
    if (isOnRoleItem) {
      backgroundColor = '#e5f1f5'
      listIcon = shipRegistryIconBlue
    }
    else {
      listIcon = shipRegistryIconWhite
    }
  }

  return <ListItemWithIcon
    roleName={roleName}
    icon={listIcon}
    active={isOnRoleItem}
    activeColor={activeColor}
    backgroundColor={backgroundColor}
    menuOpen={menuOpen}
    path={ROLE_ROUTES[roleName]}
    open={open}
  />
}


/**
 * @param roleName - Name of the role
 * @param icon - The icon (path to the icon)
 * @param active - if you are on the same path as the page it belongs to
 * @param menuOpen - if the menu is open/hovered over
 * @param path - the url path to the page
 * @param activeColor
 * @param backgroundColor - CSS property, background behind icon (Blue or light blue, typically)
 * @return functional component with icon to be used in menu
 */

interface IListItemWithIcon {
  roleName: string;
  icon: string;
  active: boolean;
  activeColor: string;
  menuOpen: boolean;
  backgroundColor: string;
  path: string;
  open: boolean;
}

/**
 * *The List-item itself (text, icon, in/out)
 * @param props 
 * @returns 
 */

const ListItemWithIcon = (props: IListItemWithIcon) => {
  const { roleName, open, icon, path, menuOpen, backgroundColor } = props;
  const history = useHistory();

  return <ListItemButton
    onClick={() => {
      history.push(path);
    }}
    sx={{
      padding: 0,
      letterSpacing: '0.1rem',
    }}
  >
    <Grid sx={{
      display: 'flex',
      alignContent: 'center',
      width: "64px",
      padding: "24px 16px",
      backgroundColor: backgroundColor,
    }} item>
      <Icon sx={{ width: '30px' }}>
        <img src={icon} height={'100%'} width={'100%'}
        />
      </Icon>
    </Grid>
    {menuOpen &&
      <ListItemText
        sx={{
          marginTop: "0",
          marginLeft: "20px",
          letterSpacing: '0.1rem',
        }}
        inset={false}
        primary={
          translationWithUppercase(roleName)
        }
      />
    }
  </ListItemButton>
}

