/* eslint-disable */

import React, { useContext, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import ComputerSharpIcon from '@mui/icons-material/ComputerSharp';
import { CircularProgress, Link as ExternalLink, Grid, Icon } from '@mui/material';
import { AuthenticationType, MapMouseEvent, PopupOptions, data } from "azure-maps-control";
import { find, isEmpty, isNil } from "lodash";
import {
  AzureMap, AzureMapDataSourceProvider,
  AzureMapFeature,
  AzureMapLayerProvider, AzureMapPopup, AzureMapsContext, AzureMapsProvider, ControlOptions, IAzureMapControls, IAzureMapImageSprite, IAzureMapOptions, IAzureMapsContextProps
} from "react-azure-maps";
import { useTranslation } from "react-i18next";

import ChargingStation from "../../../assets/icons/green_power.svg";
import ChargingStationBlack from "../../../assets/icons/grey_power.svg";
import errorIconRed from "../../../assets/icons/red_Error.svg";
import ChargingStationRed from "../../../assets/icons/red_power.svg";
import { IPlantNew, useHMIMonitoringURL } from '../../../src/plant';
import { getTokenFromSessionStorage } from "../../authconfig";
import { AppState, translationWithCapitalization } from "../../core";
import { useCurrentPlant, useRedirectUrl } from "../../settings/hook";
import { useSignalRUrl } from "../../signalr/hook";
import { useAzureMapsKey, useUserHasLoaded } from "../../user/hooks";
import { OperationsSummary } from "../startpage/components/operationsSummary";
import { ActiveAlarmsRequestAllForPlantsByPlant } from "./actions";
import { DEAD_LINE } from "./constants";
import { useActiveAlarmsWithCount, useOperationsActiveAlarmsAreLoading } from "./hooks";
import { IPlantWithAlarmCountNew } from "./interfaces";
import { PlantListView } from "./listView/plantListView";
import { calculateMidpointForMap, summarizeAlarmsForMergedPlants } from "./utils";

function clusterClicked(e: any) {

  if (e && e.shapes && e.shapes.length > 0 && e.shapes[0].properties.cluster) {
    e.map.setCamera({
      center: [...e.position],
      zoom: e.map.map.transform.tileZoom + 4,
      type: 'ease',
      duration: 500,
    });

  }
}

const renderPoint = (data: any, alarmCount: any) => {
  const lines = data.shipLines.map(shipLine => shipLine.signal)
  return (
    <AzureMapFeature
      type="Point"
      id={data.id?.toString()}
      key={data.id}
      coordinate={[data.long, data.lat]}
      properties={{
        id: data.id,
        lines: lines,
        plantShortName: data.shortName,
        plantName: data?.name,
        long: data.long,
        url: data.url,
        provider: data.provider,
        lat: data.lat,
        activeAlarms: data.activeAlarms,
        thereAreActiveAlarms: parseInt(data.activeAlarms) > 0 ? "show" : "notShow"
      }}
    />
  );
};

const controls: IAzureMapControls[] = [

  {
    controlName: "ZoomControl",
    options: { position: "top-right" } as ControlOptions
  },
  {
    controlName: "CompassControl",
    controlOptions: { rotationDegreesDelta: 10, style: "dark" },
    options: { position: "bottom-right" } as ControlOptions
  },
  {
    controlName: "PitchControl",
    controlOptions: { pitchDegreesDelta: 5, style: "dark" },
    options: { position: "bottom-right" } as ControlOptions
  }
];

const OperationsPage = () => {
  const plants: IPlantNew[] = useSelector((state: AppState) => state.user.plantsForOperationsRole);
  const userIsLoading = useUserHasLoaded();
  const dispatch = useDispatch();
  useEffect(() => {
    if (plants.length <= 0) {
      return;
    }
    const plantsToString: string[] = plants.map(i => i["shortName"])
    dispatch(new ActiveAlarmsRequestAllForPlantsByPlant(plantsToString))
  }, [plants])

  return (
    <Grid
      container
      item
    // sx = {{padding:"35px" }}
    >
      <AzureMapsProvider>
        <>
          <Grid item xs={12} lg={8} px={3}>
            <div style={{ height: "90vh", width: "100%" }}>
              {
                plants.length > 0 && !isNil(plants) && !userIsLoading && <OperationsMap plants={plants} />
              }
              <Grid container>
                <Grid item>
                  <img src={ChargingStationBlack} height="30px" />
                  <span style={{ fontSize: "13px" }}>{translationWithCapitalization("plantNotOnline")} </span>
                </Grid >
                <Grid item>
                  <img src={ChargingStation} height="30px" />
                  <span style={{ fontSize: "13px" }}>{translationWithCapitalization("noActiveAlarms")} </span>
                </Grid>
                <Grid item>
                  <img src={ChargingStationRed} height="30px" />
                  <span style={{ fontSize: "13px" }}>{translationWithCapitalization("atLeastOneActiveAlarm")}</span>
                </Grid>
              </Grid>

            </div>
          </Grid>
          <Grid item xs={12} md={3} container spacing={1}>
            <Grid item xs={12} md={12}>
              <OperationsSummary />
            </Grid>
            <Grid item xs={12} md={12}>
              <PlantListView />
            </Grid>
          </Grid>
        </>
      </AzureMapsProvider>
    </Grid>

  );
};

export default OperationsPage;

//TODO: Use this with separate SignalR-connection instead of grinding everything on client side
// todo: Composition
interface OperationsMapProps {
  plants: IPlantNew[]
}

const OperationsMap = (props: OperationsMapProps) => {
  const plants = props.plants;
  const [updatePoints, setUpdatePoints] = useState(true)

  const defaultZoom = 2.5;

  const redirectUrl = useRedirectUrl();
  const hmiUrl = useHMIMonitoringURL();

  const initialAlarmsFromCosmosDB = useActiveAlarmsWithCount();
  const initialAlarmsFromCosmosDBAreLoading = useOperationsActiveAlarmsAreLoading();
  const currentPlant = useCurrentPlant();

  const [popupOptions, setPopupOptions] = useState<PopupOptions>({
  });
  const { mapRef, isMapReady } = useContext<IAzureMapsContextProps>(AzureMapsContext);
  const [popupProperties, setPopupProperties] = useState({});
  const [plantsInitialData, setPlantsInitialData] = useState([]) //Initial data from invoke
  const [plantsUpdatedData, setPlantsUpdatedData] = useState([]) //updated data from Web Socket, 

  const [staticPlantsWithAlarms, setStaticPlantsWithAlarms] = useState<IPlantWithAlarmCountNew[]>([]) //plantOverview + alarms from invoke (signalR)
  const [annunciatedAlarmsUpdated, setAnnunciatedAlarmsUpdated] = useState([])  //alarms from .on (signalR)
  const [staticPlantsWithAlarmsMerged, setStaticPlantsWithAlarmsMerged] = useState([]);

  const [isVisible, setIsVisible] = useState(false);

  const [mergedArray, setMergedArray] = useState([]); //Hacky work around to combine initial with updated data
  const signalRUrl = useSignalRUrl()
  const azureMapKey = useAzureMapsKey();
  const userIsLoading = useUserHasLoaded();
  const [alarmsSummedUpAfterUpdate, setAlarmsSummedUpAfterUpdate] = useState(0);
  var builder = new HubConnectionBuilder()
    .withAutomaticReconnect()
    .withUrl(signalRUrl, { accessTokenFactory: async () => await getTokenFromSessionStorage() })
    .configureLogging(LogLevel.Information);

  useEffect(() => {
    console.log("popup properties", popupProperties)
  }, [popupProperties])

  const connection = builder.build();

  connection.on("ShipPropertiesChanged", async (...m: any) => {

    if (!isNil(m[0])) {
      setPlantsUpdatedData(i => [...i, m[0]])
    }
  }
  )
  connection.on("AlarmChanged", async (...m: any) => {

    const alarm = m[0]
    const plantNameForAlarm = m[0].plant;
    const copiedAlarmUpdatedArray = [...annunciatedAlarmsUpdated];

    if (!isNil(m[0])) {
      setAnnunciatedAlarmsUpdated([...copiedAlarmUpdatedArray, m[0]])
    }
  }
  );

  /**
   * Ad hoc method for merging telemetry with inital data
   */
  useEffect(() => {
    // TODO: Create unit test for this
    var plantMerged123 = [...plantsInitialData]
    if (!isNil(plantsUpdatedData) && plantsUpdatedData.length > 0) {
      plantsUpdatedData.forEach(r => {
        if (!isNil(r)) {
          r?.properties.forEach(p => {
            if (!isNil(plantsInitialData) && plantsInitialData.length > 0) {
              var plantIndex = plantsInitialData.findIndex(plantInitialToFind => plantInitialToFind.plantName.toString() === r.plantName.toString());

              // console.log(find(plantsInitialData, (i)=>{return i.plantName === plantInitialToFind.plantName.toString()})
              var plantInitialDataCloned = plantsInitialData[plantIndex]

              const plantFilteredByPlantName = plantsInitialData.filter(i => i.plantName.toString() === r.plantName.toString())
              plantFilteredByPlantName.forEach(pi => {
                var lines = pi?.line
                if (!isNil(pi?.line) && pi?.line.length > 0 && pi?.plantName.toString() === r.plantName.toString()) {

                  var lineIndex = lines.findIndex((l: any) => l.name.toString() === p.objectName.toString());

                  const foundLine = lines[lineIndex]

                  if (!isNil(foundLine) && !isNil(foundLine?.properties) && foundLine?.properties.length > 0) {
                    var properties = foundLine.properties;
                    var propertyChanged = properties.find(pChanged => pChanged.name.toString() === p.name.toString())
                    var propertyChangedIndex = properties.findIndex(pChangedIndex => pChangedIndex.name.toString() === p.name.toString())
                    properties.splice(propertyChangedIndex, 1)
                    propertyChanged.value = p.value

                    properties.push(propertyChanged)
                    foundLine.properties = properties;
                  }
                  lines[lineIndex]
                }
                plantInitialDataCloned.line = lines;
              }
              )
              plantMerged123[plantIndex] = plantInitialDataCloned
            }
          }
          )
        }

      })
    }

    setMergedArray(plantMerged123);

  }, [plantsUpdatedData, plantsInitialData])


  useEffect(() => {

    /**
     * Reset zoom for "all" to default
     * Bounding box to include all relevant points   
     *  
     **/
    if (isMapReady && mapRef) {
      let midpoint;
      let zoomLevel;
      if (currentPlant.plantName !== 'All') {
        const thisPlant = staticPlantsWithAlarms
          .find(p => p.shortName?.includes(currentPlant?.plantName));

        midpoint = [thisPlant?.long, thisPlant?.lat];
        zoomLevel = 18
      } else {
        midpoint = calculateMidpointForMap(staticPlantsWithAlarms)
        zoomLevel = defaultZoom
      }
      if (!midpoint) return

      mapRef?.setCamera(
        {
          center: midpoint,
          zoom: zoomLevel
        }
      )
    }
  }, [currentPlant, isMapReady]);

  async function start() {
    try {
      // TODO: Refactor using connection/SignalR-class
      // TODO: Move this entire thing into a service instead of handling this on client-side
      await connection.start();
      plants.forEach(plant => {
        connection.invoke("Subscribe", plant.shortName)
      })
      // For each plant create an object and store it along with the plant line
      //TODO: move to Connection/SignalR-class

      plants.forEach(plant => {
        // Action, Epic
        const signals = plant.shipLines.map(shipLine => shipLine.signal)
        connection.invoke(
          "GetObjects", plant.shortName, signals).then(result => {
            // Null check? if line.includes(null)
            const resultsFormatted = []

            result.map(
              (x: any) => {
                isNil(x) ? resultsFormatted.push(DEAD_LINE) : resultsFormatted.push(x)
              }
            )


            var plantLine = {
              "plantName": plant.shortName,
              "line": resultsFormatted,
            }
            setPlantsInitialData(plantsInitialData => [...plantsInitialData, plantLine])
          }
          )
      })

    } catch (err) {
      console.log(err)
      setTimeout(start, 6000);
    }
  }

  useEffect(() => {
    if (!userIsLoading) {
      start()
    }
  }, [userIsLoading, plants])


  useEffect(() => {
    // TODO: Create unit test for this
    if (!isNil(initialAlarmsFromCosmosDB) && initialAlarmsFromCosmosDB.length > 0 && !isNil(plants) && plants.length > 0) {
      const cloned: IPlantWithAlarmCountNew[] = [...staticPlantsWithAlarms];
      initialAlarmsFromCosmosDB.forEach(activeAlarm => {
        var p = plants.find(p => {
          return p.shortName.toString() === activeAlarm.plant.toString()
        })

        if (!p) return
        var combined: IPlantWithAlarmCountNew = { ...p, "activeAlarms": activeAlarm.activeAlarmCount }
        var indexForAlreadyExistingPlant = cloned.findIndex(c => {
          return c.shortName?.toString() === activeAlarm.plant.toString()
        })

        indexForAlreadyExistingPlant >= 0 ? cloned[indexForAlreadyExistingPlant] = combined : cloned.push(combined)
      })
      setStaticPlantsWithAlarms(cloned)
    }

  }, [plants, initialAlarmsFromCosmosDB])

  useEffect(() => {
    // TODO: Create unit test for this
    if (staticPlantsWithAlarms.length > 0 && !isNil(initialAlarmsFromCosmosDB)) {
      const staticPlantsWithAlarmsCopy = [...staticPlantsWithAlarms]

      if (annunciatedAlarmsUpdated.length > 0 && staticPlantsWithAlarms.length > 0) {
        staticPlantsWithAlarmsCopy.forEach((p, key) => {
          const alarmActiveForNewAlarms = annunciatedAlarmsUpdated
            .filter(a => a.plant.toString() === p.shortName.toString()).filter(b => b.alarmInfo.active === true).length;

          const alarmsInactiveForNewAlarms = annunciatedAlarmsUpdated
            .filter(a => a.plant.toString() === p.shortName.toString()).filter(b => b.alarmInfo.active === false).length;

          const activeAlarms = p.activeAlarms;
          const activeAlarmsForNew = parseInt(alarmActiveForNewAlarms) + parseInt(activeAlarms) - parseInt(alarmsInactiveForNewAlarms);

          p.activeAlarms = activeAlarmsForNew;

          staticPlantsWithAlarmsCopy[key] = p;

        })
      }
      setStaticPlantsWithAlarmsMerged(staticPlantsWithAlarmsCopy);

    }

  }, [staticPlantsWithAlarms, annunciatedAlarmsUpdated, initialAlarmsFromCosmosDB])


  useEffect(() => {
    const num = summarizeAlarmsForMergedPlants(staticPlantsWithAlarmsMerged)
    setAlarmsSummedUpAfterUpdate(num)
  }, [staticPlantsWithAlarmsMerged, annunciatedAlarmsUpdated, initialAlarmsFromCosmosDB])

  connection.onclose(async () => {
    console.log("connection closes")
    await start();
  });

  const azureMapOptions: IAzureMapOptions = {
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: azureMapKey
    },
    center: mapRef ? mapRef.getCamera().center : calculateMidpointForMap(plants),
    showLogo: false,
    zoom: 2.5,
    view: "auto",
    style: "road"
  };



  const bubbleLayerOptions = {
    //Scale the size of the clustered bubble based on the number of points in the cluster.
    radius: [
      'step',
      ['get', 'point_count'],
      35, //Default of 20 pixel radius.
      100,
      50, //If point_count >= 100, radius is 30 pixels.
      750,
      70, //If point_count >= 750, radius is 40 pixels.
    ],
    //Change the color of the cluster based on the value on the point_cluster property of the cluster.
    color: [
      'step',
      ['get', 'count_active_alarms_for_this_cluster'],
      'rgba(0,255,0,0.8)', //Default to green.
      1,
      'rgba(255,0,0,1)', //If any alarms, the cluster is rendered as red
    ],
    strokeWidth: 0,
    filter: ['has', 'point_count'], //Only rendered data points which have a point_count property, which clusters do.
  };

  const memoizedMarkerRender = useMemo(
    () => {
      if (!isNil(!initialAlarmsFromCosmosDB) && staticPlantsWithAlarms.length > 0 && staticPlantsWithAlarmsMerged.length > 0) {
        return staticPlantsWithAlarmsMerged
          .map(el => renderPoint(el, initialAlarmsFromCosmosDB))
      }
    },
    [staticPlantsWithAlarms, currentPlant, initialAlarmsFromCosmosDB, staticPlantsWithAlarmsMerged]
  );
  useEffect(() => {
    setUpdatePoints(!updatePoints)
  }, [annunciatedAlarmsUpdated])
  return userIsLoading && (staticPlantsWithAlarms.length === 0) && (initialAlarmsFromCosmosDB.length === 0) && (staticPlantsWithAlarmsMerged.length === 0) ? <Grid sx={{ display: 'flex', justifyContent: 'center' }} >
    <CircularProgress />
  </Grid> : <>
    <div>
      <AzureMap
        options={azureMapOptions}
        imageSprites={[...chargingStationImageSprites]}
        controls={controls}
        cameraOptions={{
          type: "ease",
          duration: 200
        }}
        events={{ click: () => setIsVisible(false) }}
      >
        <AzureMapDataSourceProvider
          id={"MultiplePoint AzureMapDataSourceProvider"}
          options={{
            //Tell the data source to cluster point data.
            cluster: true,

            //The radius in pixels to cluster points together.
            clusterRadius: 45,

            //If you zoom in more than this, all points are rendered as symbols.
            clusterMaxZoom: 15,

            clusterProperties: {
              'count_active_alarms_for_this_cluster': ['+', ['case', count_active_alarms_for_this_cluster, 1, 0]],

            },
            noActiveAlarmsForThisCluster,
            iconOptions: {
              filter: ['!', ['has', 'point_count']], //Filter out clustered points from this layer.
            }
          }}
        >
          <AzureMapLayerProvider
            id={"MultiplePoint AzureMapLayerProvider"}
            options={{
              iconOptions: {
                filter: ['!', ['has', 'point_count']], //Filter out clustered points from this layer.
                size: 0.7,
                image: [
                  'match',
                  ['get', 'thereAreActiveAlarms'],
                  //For each entity type, specify the icon name to use.
                  "show", 'chargingStationRed',
                  'notShow', 'chargingStation', //chargingStationBlack
                  //Default fallback icon.
                  'chargingStation'
                ],
                allowOverlap: true,
                ignorePlacement: true
              }
            }}
            events={{

              click: (e: MapMouseEvent) => {
                setIsVisible(true)
                if (e?.shapes && e.shapes[0]?.data?.properties) {
                  const prop: any = e.shapes[0].data?.properties;
                  setPopupOptions({
                    ...popupOptions,
                    closeButton: false,
                    position: new data.Position(
                      prop.long,
                      prop.lat
                    ),
                    pixelOffset: [0, -18]
                  });
                  if (prop)
                    setPopupProperties({
                      ...prop
                    });

                }
              }

            }}
            type="SymbolLayer"
          >

          </AzureMapLayerProvider>

          <AzureMapLayerProvider
            id={'BubbleLayer LayerProvider'}
            options={bubbleLayerOptions}
            type="BubbleLayer"
            events={{
              click: clusterClicked

            }}
          ></AzureMapLayerProvider>

          <AzureMapLayerProvider
            id={'BubbleLayer2 LayerProvider'}
            options={{
              iconOptions: {
                image: 'none', //Hide the icon image.
              },
              textOptions: {
                textField: ['get', 'point_count_abbreviated'],
                offset: [0, 0.4],
                size: 32,
                color: "white"
              },
            }}
            type="SymbolLayer"

          ></AzureMapLayerProvider>

          {/* Hacky way for forcing the points to re-render */}
          {updatePoints && memoizedMarkerRender}
          {!updatePoints && memoizedMarkerRender}


        </AzureMapDataSourceProvider>


        {!isEmpty(popupProperties) && isVisible &&


          <AzureMapPopup
            isVisible={isVisible}
            options={{ ...popupOptions }}
            popupContent={<PopupContent
              hmiUrl={hmiUrl}
              redirectUrl={redirectUrl}
              popupProperties={popupProperties}
              annunciatedAlarms={initialAlarmsFromCosmosDB}
              plantsInitialData={plantsInitialData.find(a => a.plantName === popupProperties.plantShortName)}
              staticPlantsWithAlarms={!isNil(staticPlantsWithAlarmsMerged.find(a => a.id === popupProperties.id)) ? staticPlantsWithAlarmsMerged.find(a => a.id.toString() === popupProperties.id.toString()) : staticPlantsWithAlarmsMerged}
              merged={mergedArray.find(a => a.plantName === popupProperties.plantShortName)}

            />
            }
          />

        }

      </AzureMap >
    </div >

  </>
}

/**
 * Util for formatting line data before it is throuwn into the popup-data
 * @param line
 * @param lineUpdated - Line returned on SignalR-update
 *  * NOTE! This is reliant on the plant having the same name for MeteredHours, MeteredEnergy etc
 * @returns 
 */
const lineData = (line: any[]) => {
  const { t } = useTranslation();

  const findPropertyFromObject = (l: any, property: string) => {
    var meteredValue = "";
    meteredValue = l.properties.find(j => j.name.toString() === property.toString()).value.value
    return meteredValue;
  }

  const SHIP_IS_NOT_CONNECTED = translationWithCapitalization("shipIsNotConnected");
  const LINE_HAS_MISSING_DATA = translationWithCapitalization("lineHasMissingData");
  const meteredEnergy = (!isNil(line)) ? findPropertyFromObject(line, "MeteredEnergy").toFixed(2) : "No value found" //Check if updated
  const meteredHours = (!isNil(line)) ? findPropertyFromObject(line, "MeteredHours").toFixed(2) : "No value found" //Check if updated
  const isConnected = (!isNil(line)) ? findPropertyFromObject(line, "IsConnected") : "No value found" //Check if updated

  const lineTag = line.branchTag.replace("=", "")

  const lineHasMissingData = lineTag === "Missing-Data"

  if (lineHasMissingData) {
    return <div>{LINE_HAS_MISSING_DATA}</div>
  }
  else {
    return (isConnected ? <div>
      {lineTag}
      - {translationWithCapitalization("meteredEnergy")} - {meteredEnergy} kWh    {translationWithCapitalization("meteredHours")} - {meteredHours} h

      <br />
    </div> : <div>{lineTag} - {SHIP_IS_NOT_CONNECTED}</div>)
  }
}



const PopupContent = (props) => {

  const { popupProperties, plantsInitialData, hmiUrl, merged, redirectUrl, staticPlantsWithAlarms } = props;
  const s = !isNil(popupProperties?.id?.toString) && (popupProperties?.length > 0) && (isNil(merged)) ? find(merged, (o) => { return o.plantName.toString() === popupProperties.id.toString(); }) : plantsInitialData
  const showMerged = !isNil(s);
  const arrayToShow = showMerged ? s : plantsInitialData;

  const annunciatedAlarmsCount = !isNil(staticPlantsWithAlarms.activeAlarms) ? staticPlantsWithAlarms.activeAlarms : 0;

  const showSignalRData = !isNil(plantsInitialData);
  const showAlarms = !isNil(staticPlantsWithAlarms.activeAlarms)

  return <>
    <div style={{ padding: "16px 20px" }}>
      <h3>{popupProperties.plantShortName} - {popupProperties.plantName}</h3>
      {showAlarms ? <div>
        <ExternalLink
          style={{ paddingLeft: 13 }}
          href={`${redirectUrl}/alarms`}>
          <Icon>
            {staticPlantsWithAlarms.activeAlarms > 0 ?
              <img src={errorIconRed} height={25} width={25} style={{
              }} /> :
              <img src={errorIconRed} height={25} width={25} style={{
                opacity: "20%"
              }} />
            }

          </Icon>
        </ExternalLink>-
        {translationWithCapitalization("alarmsActive")}: {annunciatedAlarmsCount}
        <br />
        <ExternalLink
          href={`${redirectUrl}/alarms`}
          style={{ paddingLeft: 13 }}
        >
          View alarm Details!
        </ExternalLink>
      </div> : <div>{translationWithCapitalization("noActiveAlarmsFound")}
      </div>}

      {showSignalRData && <h4>{translationWithCapitalization("lines")}</h4>}
      {showSignalRData ? arrayToShow.line.map((line: any, key: number) => <React.Fragment key={key}>
        {/* Pass in old line, and updated line. Check whether updated has properties by findLast: 
  // Todo: Revise the old "structure", create a proper one
  IMPORTANT!!!
  
If not: return properties of old line */}
        {lineData(line)}
      </React.Fragment>) : <span>{translationWithCapitalization("telemetryNotAvailable")}</span>}
      <br />
      <ExternalLink
        target="_blank"
        href={`${hmiUrl}/${popupProperties.plantShortName}/HMI`}

        style={{
          textDecoration: 'none',
          color: "grey"
        }}
      >
        <ComputerSharpIcon />
      </ExternalLink>
    </div>
  </>

}



// icons etc for rendering on map ("form og farge")

const chargingStationImageSprites: IAzureMapImageSprite[] = [
  {
    id: "chargingStation",
    icon: ChargingStation,
    scale: 1
  },
  {
    id: "chargingStationRed",
    icon: ChargingStationRed,
    scale: 1
  },
  {
    id: "chargingStationBlack",
    icon: ChargingStationBlack,
    scale: 1
  }
]


const count_active_alarms_for_this_cluster = ['>=', ['get', 'activeAlarms'], 1];
const noActiveAlarmsForThisCluster = ['=', ['get', 'activeAlarms'], 0];