/* eslint-disable */

import React, { useEffect, useState } from 'react';

import { HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SignalWifiConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiConnectedNoInternet4';
import SignalWifiStatusbar4BarIcon from '@mui/icons-material/SignalWifiStatusbar4Bar';
import { Tooltip, Typography } from '@mui/material';
import { isNil, uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { green } from '@mui/material/colors';
import { getTokenFromSessionStorage } from '../../../authconfig';
import { AppState, translationWithCapitalization, translationWithUppercase } from '../../../core';
import { PlantRequestAll } from '../../../plant';
import { useCurrentPlant } from '../../../settings/hook';
import { useSignalRUrl } from '../../../signalr/hook';
import { useUserHasLoaded } from '../../../user/hooks';
import { ActiveAlarmsRequestAllForPlantsByPlant } from '../../operations/actions';
import { DEAD_LINE } from '../../operations/constants';
import { useActiveAlarmsWithCount } from '../../operations/hooks';
import { sumNumberOfTotalLines } from './../util';
import { SummaryCard } from './SummaryCard';

export const OperationsSummary = () => {
  const linesInUse = translationWithCapitalization("linesInUse");
  const dispatch = useDispatch()
  const activeAlarms = translationWithCapitalization("activeAlarms");
  const LOADING_MESSAGE = translationWithUppercase("loadingPleaseWait")
  const operations = translationWithCapitalization("operations");
  const [numberOfConnected, setNumberOfConnected] = useState(0);
  const currentPlant = useCurrentPlant();
  
  const [totalNumberOfLines, setTotalNumberOfLines] = useState(0)

  const initialAlarmsFromCosmosDB = useActiveAlarmsWithCount(); // Initial alarm-state

  const [alarmCounter, setAlarmCounter] = useState(0); //Aggregated for both initial and onChange in alarms

  const [statusText, setStatusText] = useState("");

  const [statusIcon, setStatusIcon] = useState(
    <Tooltip title="offline">
      <SignalWifiConnectedNoInternet4Icon sx={{ color: green[500] }} />
    </Tooltip>
  )

  const [plantsInitialData, setPlantsInitialData] = useState([]) //Initial data from invoke
  const [plantsUpdatedData, setPlantsUpdatedData] = useState([]) //updated data from Web Socket, 

  const [annunciatedAlarmsUpdated, setAnnunciatedAlarmsUpdated] = useState([])  //alarms from .on (signalR)


  const [alarmsWithAppendedValuesFromSignalR, setAlarmsWithAppendedValuesFromSignalR] = useState([])

  const [mergedArray, setMergedArray] = useState([]); //Hacky work around to combine initial with updated data
  const signalRUrl = useSignalRUrl()
  const userIsLoading = useUserHasLoaded();


  const { t } = useTranslation();
  const OPERATIONS_OFFLINE = t("operationsOffLine");

  const plants = useSelector((state: AppState) => state.user.plantsForOperationsRole);

  const [connectionState, setConnectionState] = useState("");

  useEffect(() => {
    if(plants.length <= 0) {
      return;
    }
    const currentPlants = currentPlant.plantName !== 'All' ? plants.filter(p => p.shortName === currentPlant.plantName) : plants
    const numLines = sumNumberOfTotalLines(currentPlants);
    setTotalNumberOfLines(numLines)
    const plantsToString = currentPlants.map(i => i["shortName"])
    dispatch(new ActiveAlarmsRequestAllForPlantsByPlant(plantsToString))
    setAlarmsWithAppendedValuesFromSignalR([])
  }, [plants, currentPlant])


  useEffect(() => {
    if(!userIsLoading) {
      dispatch(new PlantRequestAll());
    }
  }, [userIsLoading]);

  var builder = new HubConnectionBuilder()
    .withAutomaticReconnect()
    .withUrl(signalRUrl, { accessTokenFactory: async () => await getTokenFromSessionStorage() })
    .configureLogging(LogLevel.Information);

  const connection = builder.build();

  connection.on("ShipPropertiesChanged", async (...m: any) => {

    if (!isNil(m[0])) {
      setPlantsUpdatedData(i => [...i, m[0]])
    }
  }
  )

  connection.on("AlarmChanged", (...m: any) => {
    const alarm = m[0]
    const plantNameForAlarm = m[0].plant;

    if (!isNil(m[0])) {
      // Hacky way to get rid of duplicate values
      // Set is a data structure in javascript that does not have multiples of the same object
      // Array-> set --> array
      const arrayToSet = new Set(...annunciatedAlarmsUpdated)
      setAnnunciatedAlarmsUpdated([...arrayToSet, m[0]])
    }
  }
  );

  useEffect(() => {
    var plantMerged123 = [...plantsInitialData]
    if (!isNil(plantsUpdatedData) && plantsUpdatedData.length > 0) {
      plantsUpdatedData.forEach(r => {
        var plantNameForLookUp = r.plantName;
        r?.properties.forEach(p => {
          var objectName = p.objectName;
          var propName = p.name;
          if (!isNil(plantsInitialData) && plantsInitialData.length > 0) {
            var plantIndex = plantsInitialData.findIndex(plantInitialToFind => plantInitialToFind.plantName.toString() === plantNameForLookUp.toString());
            var plantInitialDataCloned = plantsInitialData[plantIndex]

            const plantFilteredByPlantName = plantsInitialData.filter(i => i.plantName.toString() === r.plantName.toString())


            plantFilteredByPlantName.forEach(pi => {
              var lines = pi?.line
              if (!isNil(pi?.line) && pi?.line.length > 0) {
                var lineIndex = lines.findIndex(b => p.objectName.toString() === b.name.toString());
                const foundLine = lines[lineIndex]
                var properties = foundLine?.properties;
                if (!isNil(properties) && properties.length > 0) {

                  var propertyChanged = properties.find(pChanged => pChanged.name.toString() === p.name.toString())
                  var propertyChangedIndex = properties.findIndex(pChangedIndex => pChangedIndex.name.toString() === p.name.toString())
                  properties.splice(propertyChangedIndex, 1)
                  propertyChanged.value = p.value

                  properties.push(propertyChanged)
                }
                foundLine?.properties = properties;

                lines[lineIndex]

              }
              plantInitialDataCloned.line = lines;
            })
            plantMerged123[plantIndex] = plantInitialDataCloned
          }

        }

        )
      })
    }

    setMergedArray(plantMerged123);

  }, [plantsUpdatedData, plantsInitialData])


  useEffect(() => {
    var numConnected = 0;

    if (mergedArray.length > 0) {
      const arrayToSetToArray = uniqBy(mergedArray, "plantName")

      arrayToSetToArray.forEach((plant: any) => {
        plant.line.forEach(l => {
          var isConnected = l.properties.find(i => i.name === "IsConnected").value.value;
          if (isConnected === true || isConnected === 1) {
            numConnected++
          }
        })
      })
    }
    setNumberOfConnected(numConnected)
  }, [plantsUpdatedData, plantsInitialData, mergedArray])


  useEffect(() => {
    if (annunciatedAlarmsUpdated.length > 0) {
      // if(!alarmsWithAppendedValuesFromSignalR.includes(...annunciatedAlarmsUpdated)){
      setAlarmsWithAppendedValuesFromSignalR([...alarmsWithAppendedValuesFromSignalR, ...annunciatedAlarmsUpdated])
      // }
    }
  }, [annunciatedAlarmsUpdated])

  useEffect(() => {
    if (isNil(initialAlarmsFromCosmosDB) ) {
      return;
    }
    var aCounter = 0;
    var initialAlarmsFiltered = initialAlarmsFromCosmosDB.filter(p => p.plant === currentPlant.plantName || currentPlant.plantName === 'All')
    initialAlarmsFiltered.forEach((i) => {
      aCounter += i.activeAlarmCount;
    })
    if(annunciatedAlarmsUpdated.length > 0) {
      const alarmActiveForNewAlarms = alarmsWithAppendedValuesFromSignalR
        .filter(b => (b.alarmInfo.active && (b.plant === currentPlant.plantName || currentPlant.plantName === 'All'))).length;

      const alarmsInactiveForNewAlarms = alarmsWithAppendedValuesFromSignalR
        .filter(b => (!b.alarmInfo.active && (b.plant === currentPlant.plantName || currentPlant.plantName === 'All'))).length;
      aCounter = aCounter + alarmActiveForNewAlarms - alarmsInactiveForNewAlarms
    }



    setAlarmCounter(aCounter)
  }, [initialAlarmsFromCosmosDB, alarmsWithAppendedValuesFromSignalR])

  async function start() {
    try {
      // TODO: Refactor using connection/SignalR-class
      // TODO: Move this entire thing into a service instead of handling this on client-side
      await connection.start();
      setConnectionState(connection.state)
      plants.forEach(plant => {
        connection.invoke("Subscribe", plant.shortName)
      })

      // For each plant create an object and store it along with the plant line
      //TODO: move to Connection/SignalR-class

      plants.forEach(plant => {
        // Action, Epic
        const lines = plant.shipLines.map(l => l.signal)
        connection.invoke(
          "GetObjects", plant.id, [...lines]).then(result => {
            // Null check? if line.includes(null)

            const resultsFormatted = []

            result.map(
              (x: any) => {
                isNil(x) ? resultsFormatted.push(DEAD_LINE) : resultsFormatted.push(x)
              }
            )

            var plantLine = {
              "plantName": plant.shortName,
              "line": resultsFormatted,
            }

            setPlantsInitialData(plantsInitialData => [...plantsInitialData, plantLine])
          }
          )
      })

    } catch (err) {
      setConnectionState(connection.state)
      setTimeout(start, 6000);
    }
  }

  useEffect(() => {
    if(!userIsLoading) {
      start()
    }
  }, [userIsLoading, plants])

  connection.onclose(async () => {
    console.log("connection closes")
    await start();
  });

  useEffect(() => {

    switch (connectionState) {
      case HubConnectionState.Disconnected:
      case HubConnectionState.Disconnecting:
        setStatusText(`${OPERATIONS_OFFLINE}`)
        setStatusIcon(
          <Tooltip title="offline">
            <SignalWifiConnectedNoInternet4Icon sx={{ color: green[500] }} />
          </Tooltip>
        )
        break;

      case HubConnectionState.Connected:
        setStatusText("Operations online ")
        setStatusIcon(
          <Tooltip title="Connected">
            <SignalWifiStatusbar4BarIcon sx={{ color: green[500] }} />
          </Tooltip>

        )
        break;

      case HubConnectionState.Connecting:
      case HubConnectionState.Reconnecting:
        setStatusText("Trying to connect...")
        setStatusIcon(
          <Tooltip title="Restarting">
            <RestartAltIcon sx={{ color: green[500] }} />
          </Tooltip>
        )
        break;

      default:
        setStatusIcon(<span>{LOADING_MESSAGE}</span>)
        break;

    }

  }, [connectionState])

  return <SummaryCard>
    <Typography gutterBottom variant="h5" component="div">
      {operations}
    </Typography>
    <Typography variant="body2" color="text.primary">
      {statusIcon}
      <br />
      {statusText}
      <br />
      {connectionState === HubConnectionState.Connected && <>

        {linesInUse}: {numberOfConnected}/
        {<span>{totalNumberOfLines}</span>}

      </>
      }
    </Typography>

    {connectionState === HubConnectionState.Connected && <Typography variant="body2" color="text.primary">
      {activeAlarms}:
      <span>{alarmCounter} </span>
    </Typography>}

  </SummaryCard>
}
