import React, { useMemo, useState } from "react";

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Icon, List, ListItemButton, ListItemText, Popover } from "@mui/material";


import { ExpandableTable } from "../../Components/tables";
import verticaldot from '../../../../assets/icons/verticaldot.svg'
import { translationWithCapitalization, translationWithUppercase } from "../../../core";
import { EditAllUserAccessesForUserDialog } from "./dialog/editAllUserAccessesForUser";
import { capitalize } from "lodash";

export const UserView = (props:any)=>{

  const FIRST_NAME = translationWithCapitalization("firstName");
  const LAST_NAME = translationWithCapitalization("lastName");
  const EMAIL = translationWithCapitalization("email");
  const CUSTOMER = translationWithCapitalization("customer");
  const NO_DATA = translationWithUppercase("noData");

  const {users} = props;

  const userColumns = useMemo(
    ()=> [
      {
        id:"manageRoles",
        Cell: ({ value, row }) => (
        <Box 
        sx={{
          display: 'flex', 
          flexDirection: 'row-reverse' 
        }}>
            <ManageUserMenu
              selectedUser={row.original}
           /> 
        </Box> 
        )
      },
      {
        Header:  (c) => <span
          style= {{fontWeight:"400", fontSize:"17px"}}
        > 
          {FIRST_NAME} <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => c.column.toggleSortBy(!c.column.isSortedDesc)}
            >
                { c.column.isSorted? <KeyboardArrowDownIcon 
                  fontSize="large"
                /> : <KeyboardArrowUpIcon 
                fontSize="large"
              /> }
            </IconButton> </span>,
        Cell: ({value,row})=> <Typography sx = {{fontWeight:700, fontSize:"16px"}}>{  capitalize(value)}</Typography>,
        accessor: 'firstName',
        id:"firstName"
      },
      {
        Header:  (c) => <span
          style= {{fontWeight:"400", fontSize:"17px"}}
        > 
          {LAST_NAME} <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => c.column.toggleSortBy(!c.column.isSortedDesc)}
            >
                { c.column.isSorted? <KeyboardArrowDownIcon 
                  fontSize="large"
                /> : <KeyboardArrowUpIcon 
                fontSize="large"
              /> }
            </IconButton> </span>,
        Cell: ({value,row})=> <Typography sx = {{fontWeight:700, fontSize:"16px"}}>{  capitalize(value)}</Typography>,
        accessor: 'lastName',
        id:"lastName"
      },
      {
        Header: (c) => <span
          style= {{fontWeight:"400", fontSize:"17px"}}
        > 
        {EMAIL} <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => c.column.toggleSortBy(!c.column.isSortedDesc)}
          >
              { c.column.isSorted? <KeyboardArrowDownIcon 
                fontSize="large"
              /> : <KeyboardArrowUpIcon 
              fontSize="large"
            /> }
          </IconButton> </span>,
        accessor: 'email',
        id:"email",
        
        Cell: ({value,row})=> <Typography sx = {{fontWeight:400, fontSize:"16px"}}>{value}</Typography>
      },
      {
        Header: (c) => <span
          style= {{fontWeight:"400", fontSize:"17px"}}
        > 
        {CUSTOMER} <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => c.column.toggleSortBy(!c.column.isSortedDesc)}
          >
              { c.column.isSorted? <KeyboardArrowDownIcon 
                fontSize="large"
              /> : <KeyboardArrowUpIcon 
              fontSize="large"
            /> }
          </IconButton> </span>,
        accessor: 'customerName',
        id:"customerName",
        Cell: ({value, row})=> <Typography sx = {{fontWeight:400}}>{value}</Typography>
      }
     
    ],[]
  )

  return   (users?.length>=1?<ExpandableTable
      expandRows = {false}
      showSearchFilter
      columns = {userColumns}
      showHeader = {true}
      headerColor = {"#D2E5EB"}
      tableColor = {"white"}
      renderRowSubComponent = {undefined}
      data = {users}/>
        :<span>{NO_DATA}</span>)
}

const ManageUserMenu = (props:any ) =>{

  const {selectedUser } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [userAccessDialogOpen, setUserAccessDialogOpen] = useState(false);
  const handleUserAccessDialogClose = ()=> {
    setUserAccessDialogOpen(false);
    setAnchorEl(null);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const MANAGE_USER_ACCESSES = translationWithUppercase("manageUseraccess");

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  
  return <> 
    <IconButton onClick = {handleClick} >
        <Icon>
          <img src = {verticaldot}/> 
        </Icon>
    </IconButton> 
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List>
          <ListItemButton 
            onClick = {()=>setUserAccessDialogOpen(true)}

            >
            <ListItemText 
              primary={MANAGE_USER_ACCESSES} />
          </ListItemButton>

        </List>
      </Popover> 

      <EditAllUserAccessesForUserDialog
          selectedUser={selectedUser}
          open={userAccessDialogOpen} 
          handleClose={handleUserAccessDialogClose} 
        />  
</>

}