import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { IAlarmSearchRequest } from "../../browse/alarmpage";
import { IActiveAlarmsToBeFetched, StatusForApiCall } from "./interfaces";





// export const useApiCall:any = (apiCall: () => Promise<AxiosResponse<any>>, setData: (data: any) => void ) => {

//     const [apiCallStatus, setApiCallStatus] = useState<StatusForApiCall>(StatusForApiCall.NOT_STARTED);
  
//     const [error, setError] = useState<string | null>(null);
  
//     useEffect(() => {
//       const fetchData = async () => {
//         try {
//           const response = await apiCall();
//           setData(response.data)
//           setApiCallStatus(StatusForApiCall.SUCCESS);
//           setError(null);
//         } catch (error) {
//           setApiCallStatus(StatusForApiCall.FAILURE);
//           setData(null)
//           setError("Error: " + JSON.stringify(error));
//         }
//       };
//       fetchData();
//     }, [apiCall, setData]);
  
//     return [apiCallStatus, error];
//   };
  
  

