import {AuthenticationResult, InteractionRequiredAuthError, PublicClientApplication} from "@azure/msal-browser";
import { instance } from ".";

/**
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: process.env.B2C_SIGNUPSIGNIN,
    },
    authorities: {
        signUpSignIn: {
            authority: process.env.B2C_SIGNUPSIGNIN_AUTH,
        }
    },
    authorityDomain: process.env.B2C_AUTH_DOMAIN
}

/**
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

/**
 * 
 * @param clientId clientId that is used for validating the app towards the proper App-service (Dev, QA, etc)
 * @param redirectUri 
 * @returns configuration for Azure B2C client
 */
export const createConfiguration = (clientId:string, redirectUri:string)=>{
    return {
        auth: {
            clientId: clientId,
            authority: process.env.B2C_SIGNUPSIGNIN_AUTH,
            knownAuthorities: [process.env.B2C_AUTH_DOMAIN],
            redirectUri: redirectUri,
            postLogoutRedirectUri: "/",
            navigateToLoginRequestUrl: false,
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: true,
        }
    }
}

/**
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */

 export const loginRequest = {
    scopes: [process.env.SCOPES]
  };
  
export async function getTokenFromSessionStorage() {

    let request = {
        scopes: loginRequest.scopes,
        account: instance.getAllAccounts()[0]
    }
    let token = ""
    await instance.acquireTokenSilent(request).then(response => {
        token = response.accessToken
    }).catch(error => {
        if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenPopup(request).then(response => {
                token = response.accessToken;
            })
        }
    })

    return token;

}