
import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { CircularProgress, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import axios from "axios";
import dotp from "dot-prop-immutable-chain";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import immutableValidator from "redux-immutable-state-invariant";
import { createLogger } from "redux-logger";
import { combineEpics, createEpicMiddleware } from "redux-observable";

import { useTranslation } from "react-i18next";
import '../assets/main.scss';
import '../public/translations/i18n';
import { createConfiguration, getTokenFromSessionStorage, loginRequest } from "./authconfig";
import { PdfPage } from "./browse/Components/pdfcomponents";
import { alarmEpics } from "./browse/alarmpage";
import { AlarmsPage } from "./browse/alarmpage/components";
import { eventEpics } from "./browse/alarmpage/events";
import { EmissionPage } from "./browse/emission";
import { EnergyPage } from "./browse/energy";
import { FinancePage } from "./browse/finance";
import { operationsEpics } from "./browse/operations";
import OperationsPage from "./browse/operations/components";
import { ShipRegistryPage } from "./browse/shipRegistry/shipRegistryPage";
import { StartPage } from "./browse/startpage/components";
import { UserManagementPage } from "./browse/usermanagement/components";
import { UtilizationPage } from "./browse/utilization";
import { routers } from "./constants/constants";
import { Layout, classActionMiddleware, createRootReducer, initialState } from "./core";
import { NotFoundPage } from "./core/components/NotfoundPage/components";
import { AccountSettingsPage } from "./settings/pages/SettingsPage";
import theme from "./theme";
import { userEpics } from "./user";

// Environment variables

if (typeof document === 'undefined') {
  React.useLayoutEffect = React.useEffect;
}

const environment = process.env.NODE_ENV || "",
  API_URL = process.env.API_URL || "",
  SIGNAL_R_URL = process.env.SIGNALR_URL || "",
  REDIRECT_URL = process.env.REDIRECT_URL || "",
  APPLICATION_NAME = process.env.APPLICATION_NAME || "",
  HMI_MONITORING_BASE_URL = process.env.REMOTE_MONITORING_URL || "",
  CLIENT_ID = process.env.CLIENT_ID || ""

// Store
const logger = createLogger({
  collapsed: (_getState, action) => !action.failure,
  level: (action: any) => {
    if (action.success) {
      return "info";
    }
    if (action.failure) {
      return "error";
    }
    return "log";
  }
});

// B2C-validation

axios.interceptors.request.use(async (config) => {
  const token = await getTokenFromSessionStorage()
  config.headers.authorization = `Bearer ${token}`
  config.headers.contentType = 'application/json'
  return config;
})

const configuration = createConfiguration(CLIENT_ID, REDIRECT_URL);

export const instance = new PublicClientApplication(configuration);

// MUI

export const LoadingComponent = () => {
  const { t } = useTranslation()
  const LOADING_PLEASE_WAIT = t("loadingPleaseWait")

  return (
    <div style={{
      width: "100%",
      alignItems: "center",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center"
    }}>
      <CircularProgress />
      {LOADING_PLEASE_WAIT}...
    </div>
  )
}

const ErrorComponent = () => {
  const { t } = useTranslation()
  const AUTHENTICATION_FAILED = t("authenticationFailed")
  return (
    <div
      style={{
        width: "100%",
        alignItems: "center",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center"
      }}>
      {AUTHENTICATION_FAILED}
    </div>
  )
}

// Epics & middleware etc.

const epicMiddleware = createEpicMiddleware();
const rootEpic = combineEpics(userEpics, alarmEpics, operationsEpics, eventEpics);

const middleware =
  process.env.NODE_ENV === "development"
    ? [
      immutableValidator({
        ignore: []
      }),
      epicMiddleware,
      classActionMiddleware,
      logger
    ]
    : [epicMiddleware, classActionMiddleware];

// Connect everything together

export const BlueWebApp = ({ store }: { store: any }) => {

  return (
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <MsalProvider instance={instance}>
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest} errorComponent={ErrorComponent} loadingComponent={LoadingComponent}>
                <Suspense fallback={<LoadingComponent />}>
                  <Layout>
                    <Switch>
                      <Route exact path={routers.HOME} component={StartPage} />
                      <Route exact path={routers.OPERATIONS} component={OperationsPage} />
                      <Route exact path={routers.FINANCE} component={FinancePage} />
                      <Route exact path={routers.PDF} component={PdfPage} />
                      <Route exact path={routers.EMISSION} component={EmissionPage} />
                      <Route exact path={routers.UTILIZATION} component={UtilizationPage} />
                      <Route exact path={routers.ENERGY} component={EnergyPage} />
                      <Route exact path={routers.ACCOUNT_SETTINGS} component={AccountSettingsPage} />
                      <Route exact path={routers.USER_MANAGEMENT} component={UserManagementPage} />
                      <Route exact path={routers.ALARM_PAGE} component={AlarmsPage} />
                      <Route exact path={routers.EVENTS_PAGE} component={AlarmsPage} />
                      <Route exact path={routers.SHIP_REGISTRY} component={ShipRegistryPage} />
                      <Route component={NotFoundPage} />
                    </Switch>
                  </Layout>
                </Suspense>
              </MsalAuthenticationTemplate>
            </MsalProvider>
          </Router>
        </ThemeProvider>
      </Provider>
    </StyledEngineProvider>
  )
};

export const BlueWeb: any = {
  initialize: (
    apiUrl = API_URL,
    signalRUrl = SIGNAL_R_URL,
    redirectUrl = REDIRECT_URL,
    domElement = "root",
    applicationName = APPLICATION_NAME,
    hmiMonitoringBaseURL = HMI_MONITORING_BASE_URL) => {

    // Set URLs from environment-file in store

    const store = createStore(
      createRootReducer(),
      dotp(initialState)
        .set("settings.name", applicationName)
        .set("settings.server", apiUrl)
        .set("settings.redirectUrl", redirectUrl)
        .set("signalRState.url", signalRUrl)
        .set("plant.HMIMonitoringBaseURL", hmiMonitoringBaseURL)
        .value(),
      composeWithDevTools(applyMiddleware(...middleware))
    );

    // Trigger epics
    epicMiddleware.run(rootEpic);

    // Initialize app into domElement
    ReactDOM.render(
      <BlueWebApp store={store} />,
      document.getElementById(domElement)
    );
  }
};
(window as any).BlueWeb = BlueWeb;