import React, { useEffect, useState } from "react";

import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { Icon, IconButton, List,  Popover } from '@mui/material';

import { IShip } from "../../models/interfaces";
import verticaldot from '../../../../../assets/icons/verticaldot.svg'
import { AddShipToRegistryDialog } from "../dialog/AddShipToRegistryDialog";
import { DeleteShipFromRegistryDialog } from "../dialog/DeleteShipFromRegistryDialog";

interface IManageShipMenuProps{
  selectedShip:IShip;
  onRemoveShipClicked:Function;
  handleEditShipClicked:Function;
}

export const ManageShipMenu = (props:IManageShipMenuProps ) =>{

    const {selectedShip, onRemoveShipClicked, handleEditShipClicked} = props;

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [deleteShipDialogOpen, setDeleteShipDialogOpen] = useState(false);
    const handleDeleteShipDialogClose = ()=> setDeleteShipDialogOpen(false);

    const [editShipDialogOpen, setEditShipDialogOpen] = useState(false);
    const handleEditShipDialogClose = ()=> setEditShipDialogOpen(false);
  
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    
    return <> 
    <IconButton onClick = {handleClick} >
        <Icon>
          <img src = {verticaldot}/> 
        </Icon>
        </IconButton> 
          <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <List>
            <ListItemButton 
              onClick = {()=>{
                setEditShipDialogOpen(true)
                setAnchorEl(null); 
                }
              }
              >
              <ListItemText 
                primary={"Edit ship"} />
            </ListItemButton>
  
            <ListItemButton 
                onClick = { ()=> {
                  setDeleteShipDialogOpen(!deleteShipDialogOpen);
                  setAnchorEl(null);
                  }
                }
            >
              <ListItemText 
                primary={"Remove ship from registry"} />
            </ListItemButton>
          </List>
        </Popover> 

        <DeleteShipFromRegistryDialog 
            open={deleteShipDialogOpen} 
            selectedShip={selectedShip} 
            handleClose={ handleDeleteShipDialogClose} 
            handleSubmit={onRemoveShipClicked}
        />

        <AddShipToRegistryDialog
          open={editShipDialogOpen}
          selectedShip = {selectedShip}
          handleSubmit = {handleEditShipClicked}
          handleClose ={handleEditShipDialogClose}
          editMode 
          />
  
  </>
  
  }