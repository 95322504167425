import * as yup from 'yup';

import React, { FC, useEffect, useState } from 'react';

import { Button, Checkbox, CircularProgress, DialogActions, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, makeStyles, Typography } from '@mui/material';
import { isNil } from 'lodash';

import { useApiUrl } from '../../../../settings/hook';
import addUserIcon from '../../../../../assets/icons/addUserIcon.svg';
import { ConfirmationDialogWithIcon, IConfirmationDialog } from '../../../Components/dialog';
import { translationWithCapitalization } from '../../../../core';
import axios from 'axios';
import { USER_MANAGEMENT_ENDPOINTS } from '../../endpoints';

export const UserAccessDialog: FC<IConfirmationDialog>= (props:any)=>{
    const {open, handleClose, row, selectedUser, handleSubmit} = props;

    const MANAGE_USER_ACCESS = translationWithCapitalization("manageUseraccess")

    const plant = selectedUser.plantRoles.plant;

    return <ConfirmationDialogWithIcon 
      dialogTitle={plant.name} 
      sourceTitle={MANAGE_USER_ACCESS}
      icon={addUserIcon} 
      search={undefined} 
      open={open} 
      handleClose={handleClose} 
      warningText={''} 
      handleConfirm={undefined}    
      >
        <UserAccessForm
         handleClose={handleClose} 
         selectedUser={selectedUser}
         handleSubmit = {handleSubmit}
        />
    </ConfirmationDialogWithIcon>
}

export const UserAccessForm: any = (props:any) =>{
  
   const {handleClose, selectedUser, handleSubmit} = props;
   const [submitting, setSubmitting] = useState(false);
   const [roleDefinitions, setRoleDefinitions] = useState([])
   const apiURL = useApiUrl();
   const [selectedRoles, setSelectedRoles] = useState([]);
   const [isLoading, setIsLoading] = useState(false)
   const [responseMessage, setResponseMessage] = useState("")

   const ROLES = translationWithCapitalization("roles")
   const UPDATE = translationWithCapitalization("update")

   const CANCEL = translationWithCapitalization("cancel")


   useEffect( ()=>{

    axios
    .get(`${apiURL}${USER_MANAGEMENT_ENDPOINTS.GET_ALL_ROLE_DEFINITIONS}`)
    .then( response => setRoleDefinitions(response.data))
      
   },[] )

   useEffect( ()=>{
    if(!isNil(selectedUser.plantRoles.roles))
    {
      selectedUser.plantRoles.roles.map(y=>{
        selectedRoles.push(y.id)
      })
    }
    
   },[selectedUser] )

   const onEditUserSubmitClicked = () => {
      if(!isNil(selectedUser)&&selectedRoles.length>0){
         handleSubmit(selectedUser, selectedRoles)
      }
    };

    return (
   
        <form
        >
          <hr/>
          <p
              style = {{
                fontWeight: '700',

                }}>
             {selectedUser.firstName} {selectedUser.lastName} 
              </p>
              <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend">{ROLES}</FormLabel>

              <FormGroup>

            {roleDefinitions.map( (x, key)=>{ 

              var initiallyChecked = !isNil(selectedUser.plantRoles.roles)&&selectedUser.plantRoles.roles.filter( i=> i['id']=== x.id).length>0

               return <FormControlLabel 
                    key = {key} 
                    label = {x.name} 
                    control={
                    <Checkbox 
                    value = {x.id}
                    defaultChecked = {initiallyChecked}
                    onChange={(e, checked) => {
                      if (e.target.checked) {

                        const currentRoles = [...selectedRoles]
                        currentRoles.push(e.currentTarget.value)
                        setSelectedRoles(currentRoles)
                       
                      } else {
                        const cRoles = [...selectedRoles]
                        const f = cRoles.findIndex( x=> x === e.currentTarget.value)
                        cRoles.splice(f,1)
                        setSelectedRoles(cRoles)
                      }
                    
                    }}

                    />} >
               </FormControlLabel> })}
               {isLoading&& <CircularProgress />}

    </FormGroup>

    
          </FormControl>
          {submitting ?    <DialogActions><CircularProgress /></DialogActions> : 
              <DialogActions>
              <Button variant="text" onClick={props.handleClose} >{CANCEL}</Button>
              <Button variant="contained" autoFocus onClick = {onEditUserSubmitClicked}>
                {UPDATE}
              </Button>
            </DialogActions> }
            <Typography >
            {responseMessage}
        </Typography>
    </form>
    );

    }