import React, { useEffect, useMemo, useState } from 'react';

import { Box, CssBaseline, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import '../../public/translations/i18n';
import { useCurrentPlant } from '../settings/hook';
import { PlantRequestPlantsFromRoles, UserRequestAll } from '../user';
import { selectRolesFromUserRoles, useUser, useUserHasLoaded } from '../user/hooks';
import { setRoles } from '../user/util';
import { MainHeader } from './components/Header';
import { SideBar } from './components/Sidebar/components';
import { translationWithCapitalization, translationWithUppercase } from './utilities';

const Footer = () => {
  return <footer style={{ zIndex: 2 }}>Blueday Technology AS 2022. All rights reserved</footer>;
};

export const Layout = ({ children }: any) => {
  const userRoles = selectRolesFromUserRoles();
  const userIsLoading = useUserHasLoaded();
  const rolesTransformed = useMemo(() => {
    return setRoles(userRoles);
  }, [userRoles]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(new PlantRequestPlantsFromRoles());
    dispatch(new UserRequestAll());
  }, []);

  return (
    <Grid
      container
      xs={12}
      sx={{
        display: 'flex',
        width: '100%',
        backgroundColor: '#d8dddc',
      }}
    >
      <CssBaseline />
      <MainHeader />
      <Grid
        container
        item
        xs={12}
        md={12}
        lg={12}
        sx={{
          height: '100%',
          backgroundColor: 'primary.light',
        }}
        paddingLeft="100px"
        paddingRight="100px"
      >
        <Box sx={theme => theme.mixins.toolbar} />
        {!userIsLoading && rolesTransformed.length > 0 && <SideBar rolesTransformed={rolesTransformed} />}
        <Grid item xs={12} sx={{ height: '5%' }}>
          <BreadCrumbs />
        </Grid>
        <Grid item xs={12} sx={{ height: '95%' }}>
          {children}
        </Grid>
        {/* <Grid sx = {{ backgroundColor:"primary.light", height: "5%"}} item xs = {12} >
      <Footer />
    </Grid> */}
      </Grid>
    </Grid>
  );
};

/**
 * @returns
 */
const BreadCrumbs = () => {
  const location = useLocation();
  const [title, setTitle] = useState('');
  const { t, i18n } = useTranslation();

  const homeTranslated = translationWithCapitalization('home');
  const currentPlant = useCurrentPlant();
  const locationNameNotEmpty = !!location.pathname.replace('/', '');
  const plantNameNotEmpty = !!currentPlant.plantId;
  const yourUser = useUser().customer;

  const ALL_PLANTS = translationWithUppercase('allPlants');
  const plantName = !plantNameNotEmpty ? ALL_PLANTS : `${currentPlant.plantName.toUpperCase()}`;

  useEffect(() => {
    locationNameNotEmpty ? setTitle(t(location.pathname.replace('/', ''))) : setTitle(homeTranslated);
  }, [location, currentPlant, i18n.language]);

  return (
    <>
      <Typography
        sx={{
          color: 'text.primary',
          fontWeight: '700',
        }}
      >
        {title.toUpperCase()} / {plantName}
      </Typography>
    </>
  );
};
