
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { translationWithCapitalization } from '../../../core';
import { TableWithModal } from '../../../core/Table/components';

export const TableProp = (props:any)=> {

    const { data } = props;
    const {i18n} = useTranslation()
  
    const vessel = translationWithCapitalization("vessel")
    const sessionStart = translationWithCapitalization("sessionStart")
    const sessionEnd = translationWithCapitalization("sessionEnd")
    const plantId = translationWithCapitalization("plant");
    const line = translationWithCapitalization("line")
    const consumedPower = translationWithCapitalization("consumedPower")
    const price = translationWithCapitalization("price")
    const totalSessionPrice = translationWithCapitalization("totalSessionPrice")
    const FINANCE = translationWithCapitalization("finance");
  
    const columns = useMemo(
      () => [
            {
              Header: "IMO",
              accessor: 'imo',
            },
            {
              Header: vessel,
              accessor: 'shipName',
            },
            {
              Header: sessionStart,
              accessor: 'sessionStart',
            },
            {
              Header: sessionEnd,
              accessor: 'sessionEnd',
            },
            {
              Header: price,
              accessor: 'price',
            },
            {
              Header: plantId,
              accessor: 'plant',
            },
            {
            Header: line, 
            accessor: 'line',
            },
            {
              Header: consumedPower,
              accessor: 'consumedPower',
              Cell: props => <>{props.value} kwh</>
            },
            {
              Header: totalSessionPrice,
              accessor: 'totalSessionPrice',
            },
      ],
      [i18n.language]
    )
  
    return(
     <TableWithModal columns={columns} data = {data} title = {FINANCE} />
    )
  }
  