import React, { useMemo } from 'react';

import { Box } from '@mui/material';

import { ManageShipMenu } from './manageShipMenu';
import { IShipRegistryTable } from '../../models/interfaces';
import { translationWithCapitalization } from '../../../../core';
import { BasicTableRootComponent } from '../../../../core/Table/basicTable';

export const ShipRegistryTable = (props:IShipRegistryTable)=>{
    const {clickEditShip, clickDeleteShip, data } = props;

    const VESSEL_NAME = translationWithCapitalization("vesselName");
    const IMO_NUMBER = translationWithCapitalization("imoNumber");
    const CONTACT_NAME = translationWithCapitalization("contactName");
    const CONTACT_PHONE_NUMBER = translationWithCapitalization("contactPhoneNumber");
    const CONTACT_EMAIL = translationWithCapitalization("contactEmail");
    const PRICE = translationWithCapitalization("price");

        // TODO: Add pagination from server side on data

    const columns = useMemo(
        () => [
              {
                id:"manageRoles",
                Cell: ({ value, row }) => (
                <Box>
                  <ManageShipMenu
                      selectedShip={row.original}
                      handleEditShipClicked = {clickEditShip}
                      onRemoveShipClicked={clickDeleteShip}
                  />
                </Box> 
                )
              },
              {
                Header: IMO_NUMBER,
                accessor: 'imo',
              },
              {
                Header: VESSEL_NAME,
                accessor: 'vesselName',
              },
              {
                Header: PRICE,
                accessor: 'price',
              },
              {
                Header: CONTACT_NAME,
                accessor: 'contactName',
              },
              {
                Header: CONTACT_PHONE_NUMBER,
                accessor: 'contactPhone',
              },
              
              {
                Header: CONTACT_EMAIL,
                accessor: 'contactEmail',
              }
        ],
        []
      )

    return <BasicTableRootComponent
    columns = {columns} 
    data = {data} 
    />
}