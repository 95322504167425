import { getTokenFromSessionStorage } from "../authconfig";
import { AppState } from "../core";
import { useUserHasLoaded } from "../user/hooks";

/* eslint-disable */

import { useEffect, useState } from 'react';

import { HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { useSelector } from "react-redux";

export const useSignalRUrl = () =>
useSelector((state: AppState) => state.signalRState.url);

export const useSignalRConnectionStatus = () =>
useSelector((state: AppState) => state.signalRState.connectionStatus);

export const useSignalRRetries = () =>
useSelector((state: AppState) => state.signalRState.connectionRetries);

export const useSignalR = () =>
useSelector((state: AppState) => state.signalRState);



export const createSignalRConnection = (url:string)=>{
  const [connection] = useState(
    new HubConnectionBuilder()
  .withAutomaticReconnect()
  .withUrl(url, { 
    accessTokenFactory: async () => await getTokenFromSessionStorage() })
  .configureLogging(LogLevel.Information)
  .build()
  )

  return {connection}
}

// Todo: use this instead of copy-pasted function in operations and startpage
export const useOperationsHubConnection = async (url:string, plants:any) => {

    const userIsLoading = useUserHasLoaded();
    const [plantsInitialData, setPlantsInitialData] = useState([]) //Initial data from invoke
  
    const [connection] = useState(
      new HubConnectionBuilder()
    .withAutomaticReconnect()
    .withUrl(url, { 
      accessTokenFactory: async () => await getTokenFromSessionStorage() })
    .configureLogging(LogLevel.Information)
    .build()
    );
  
    const [connectionState, setConnectionState] = useState(HubConnectionState.Connecting,
      )
  
     const start = async()=> {
      try {
        // TODO: Move this entire thing into a service instead of handling this on client-side
          await connection.start();
          setConnectionState(connection.state)
  
          plants.forEach(plant=>{
            connection.invoke("Subscribe", plant.id)
          })
  
        // For each plant create an object and store it along with the plant line
  
        plants.forEach( plant=> {        
          // Action, Epic
          connection.invoke(
            "GetObjects", plant.id, [...plant.lines] ).then(result => { 
              var plantLine = {
                "plantName":plant.id,
                "line":result,
              }
              setPlantsInitialData(plantsInitialData => [...plantsInitialData, plantLine])
            }
             )})
      
      } catch (err) {
          setConnectionState(connection.state)
          setTimeout(start, 6000);
      }
    }


    useEffect(  () => { 
      if(plants?.length > 0) {
        start() 
      }
    },[plants, url])   
  
    connection.onclose(async () => {
        await start();
    });
  

    return [connection, connectionState, await plantsInitialData]

  }