import dotp from "dot-prop-immutable-chain";
import { PlantActions, PlantActionType, IPlant, IPlantOverview } from "./";

export interface PlantState {
  HMIMonitoringBaseURL: string;
}

export const initialState: PlantState = {
  HMIMonitoringBaseURL:"",
};

export const plantReducer = (
  state = initialState, 
  action: PlantActions) => {

  switch (action.type) {

    case PlantActionType.REQUEST_ALL_SUCCESS: {
      return dotp(state)
        .set("entities", action.payload)
        .value();
    }

    case PlantActionType.SET_HMI_MONITORING_BASE_URL: {
      return  dotp(state)
        .set('HMIMonitoringBaseURL',
        action.payload.HMIMonitoringBaseURL)
        .value();
      }
      
    default:
      return state;
  }
};
