import { makeStyles } from '@mui/styles';
import React, {useState, useEffect, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper } from '@mui/material';

import { AsideCard, AsideCardModal } from '../../core/components/Card/Components';
import { makeData } from '../../core/Table/utils';
import { TableWithModal } from '../../core/Table/components';
import { useTranslation } from 'react-i18next';
import { translationWithCapitalization } from '../../core';

const useStyles = makeStyles((theme) => ({
  card:{
    padding:"50px"
  }
})
,{ index: 1 }

);


export const EmissionPage = ()=>{

const { t } = useTranslation();
const [openModal, setOpenModal] = useState(false);
const classes = useStyles();

    return (
      <Grid container justifyContent={"space-between"} spacing={2} >
      <Grid  
          item
          container xs={12} 
          md = {10} 
          gap={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          >
        <Grid item xs = {12}  component = {Paper} className = {classes.card}>
          {t("ipsum")}
        </Grid>
        <Grid item xs = {12}  component = {Paper} className = {classes.card}>
          <TableTest/>
        </Grid>
      </Grid>
      <Grid 
        container item 
        xs={12} md = {2} 
        spacing={2}
        direction="row"
        height = {"10vh"}
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs= {12} >
        <AsideCardModal
          title = {"Total Emission"}
          data = {123}
          cardAction = {setOpenModal}
          actionState = {openModal}
          unit = {"Mol-CO2"}
          />
        </Grid>
        <Grid item xs= {12}>
          <AsideCard
           title = "test"/>
        </Grid>

      </Grid>
    </Grid>    
    )
}

  function TableTest() {
    const title = translationWithCapitalization("emission");
    const vessel = translationWithCapitalization("vessel")
    const sessionStart = translationWithCapitalization("sessionStart")
    const sessionEnd = translationWithCapitalization("sessionEnd")
    const duration = translationWithCapitalization("duration")
    const consumedPower = translationWithCapitalization("consumedPower")
    const total = translationWithCapitalization("total")

    const columns = useMemo(
      () => [
            {
              Header: vessel,
              accessor: 'vessel',
            },
            {
              Header: sessionStart,
              accessor: 'sessionStart',
            },
            {
              Header: sessionEnd,
              accessor: 'sessionEnd',
            },
            {
              Header: duration,
              accessor: 'duration',
            },
            {
              Header: consumedPower,
              accessor: 'consumedPower',
            },
            {
              Header: total,
              accessor: 'total',
            },
      ],
      []
    )

    const data = useMemo(() => makeData(100), [])

    return(
    <TableWithModal columns={columns} data = {data} title = {title}/>
    )
  }

  export default TableTest; 
