import { IAlarmSearchRequest } from "../../browse/alarmpage";

  
  
  export enum StatusForApiCall{
    NOT_STARTED = "NotStarted",
    LOADING = "Loading",
    SUCCESS = "Success",
    FAILURE = "Failure"
  }


  export interface IActiveAlarmsToBeFetched {
    url:string,
    searchRequest:IAlarmSearchRequest 
  }
  