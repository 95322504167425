import dotp from "dot-prop-immutable-chain";

import { IPlant, IPlantNew } from "../plant";
import { UserActions, UserActionType } from "./";

export interface UserState {
  customer: string;
  roles: any;
  plants: IPlant[];
  name: string;
  azureMapsKey:string;
  loading:boolean;
  customers:any[];
  userRoles:any[];
  plantsForOperationsRole:IPlantNew[],
  APIIsConnected:boolean
}

export const initialState: UserState = {
  customer:"",
  name: "",
  plants: [],
  azureMapsKey: "",
  loading: true,
  customers: [],
  userRoles: [],
  plantsForOperationsRole: [],
  roles: [],
  APIIsConnected:false,
};


export const userReducer = (state = initialState, action: UserActions) => {
  switch (action.type) {
    case UserActionType.REQUEST_ALL_SUCCESS: {
      return dotp(state)
        .set("name", action.payload.name)
        .set("plants", action.payload.plants)
        .set("customers", action.payload.customers)
        .set("azureMapsKey", action.payload.azureMapsKey)
        .set("customer", action.payload.customer)
        .set("loading", false)
        .set("userRoles", action.payload.userRoles)
        .set("APIIsConnected", true)
        .value();
    }

    case UserActionType.REQUEST_ALL_FAILURE: {
      return dotp(state)
        .set("APIIsConnected", false)
        .value();
    }

    case UserActionType.REQUEST_PLANTS_FROM_ROLES_SUCCESS:{
      return dotp(state)
      .set('plantsForOperationsRole',
      action.payload)
      .value();
    }

    default:
      return state;
  }
};
