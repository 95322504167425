import React from 'react';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { TableSortLabel  } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useTable, useSortBy, useRowSelect, useGlobalFilter } from 'react-table';

export const BasicTableRootComponent = ({ columns, data } )=>{

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      selectedFlatRows
    } = useTable({
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    useRowSelect
    )
  
      const selectedRowsFormated:any[] = []
  
      selectedFlatRows.forEach(s => selectedRowsFormated.push(s.original))
  
    return(
      <>
      
      <TableContainer
      >
      <Table {...getTableProps()}
      >
          <TableHead
          >
            {headerGroups.map( (headerGroup, j) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={j}>
                {headerGroup.headers.map( (column, i) => (
                  <TableCell
                  key = {i}
                  sx = {{
                    letterSpacing: "0.15em",
                    fontSize:"16px",
                    color:'#C4C4C4',
                    padding:'0px'
                  }}
                   {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}
                    <TableSortLabel
                        active={column.isSorted}
                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                      />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, k) => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps()} key ={k}>
                  {row.cells.map( (cell, j) => {
                    return <TableCell 
                    key = {j}
                    sx = {{
                      letterSpacing: "0.15em",
                      fontSize:"16px",
                      padding:'5px',
                    }}
                    {...cell.getCellProps()}>{cell.render('Cell')}
                    </TableCell>
                  })}
                </TableRow>
              )
            })}
            {rows.length===0&&<div>no data</div>}
          </TableBody>
        </Table>
      </TableContainer>
      </>
    )
  }