import {from, Observable, of} from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, withLatestFrom, mergeMap, map } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { AppState } from "../core";
import {
    mappers,
    PlantActionType,
    PlantRequestAll,
    PlantRequestAllSuccess,
    PlantRequestAllFailure,
} from "./";
import {getTokenFromSessionStorage} from "../authconfig";
import {PublicClientApplication} from "@azure/msal-browser";
import axios from "axios";
import qs from "qs";

const plantRequestAllEpic$ = (
    action$: Observable<any>,
    store$: Observable<any>,
    instance: PublicClientApplication
) => {
    return action$.pipe(
        ofType(PlantActionType.REQUEST_ALL),
        withLatestFrom(store$),
        mergeMap( ([, store]: [PlantRequestAll, AppState]) =>
            {
                return from(
                    axios.get(`${store.settings.server}/api/plant`)
                ).pipe(
                    map(
                        (response: unknown) =>
                            new PlantRequestAllSuccess(
                                (response as any[]).map(mappers.plant.from)
                            )
                    )
                ).pipe(catchError(err => of(new PlantRequestAllFailure(err))))
            }
        )
    );
};





export const plantEpics = combineEpics(
    plantRequestAllEpic$
    );
