import React from 'react';

import { Box, Pagination, TableContainer, TableHead } from '@mui/material';
import {  TableCell, TableRow, Typography, Table } from "@mui/material"

import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useExpanded
} from 'react-table';
import { GlobalFilter } from './components';

/**
 * @param data The data you wish to pass in
 * @param columns The columns related to the data in question
 * @param renderRowSubcomponent The subcomponent you wish to render (If any)
 * @param showSearchFilter Whether you wish to have search/filter bar on top or not
 * @param showHeader Whether you wish to have headers or not (title, name etc)
 * @param tableColor Color value in string
 * 
 * TODO: Create Enum for colors
 */
export interface ITableWithPagination {
  data:any[];
  columns:any[];
  expandRows:boolean;
  rowSubComponent:[];
  showSearchFilter:boolean;
  showHeader:boolean;
}

/**
 * * Generic table and rows related to userManagement
 */

export const TableWithPagination = ({data, columns, showHeader=true, showPagination=true, expandRows = false, showSearchFilter = false, renderRowSubComponent, tableColor = "#D2E5EB"}) =>{

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      rows,
      preGlobalFilteredRows,
      setGlobalFilter,
      
      state: { 
        globalFilter,
        expanded
         }
    } = useTable(
      {
        columns,
        data
      },
      useGlobalFilter,
      useSortBy,
      useExpanded,
      usePagination,
      useRowSelect,
    )

    return <TableContainer
     style={{
        maxHeight: 620
      }}
      {...getTableProps()}
    >
      {showSearchFilter&&     <Box  
        display="grid" gap={2}
        gridTemplateColumns="repeat(12, 1fr)"  
        >
        <Box gridColumn="span 10">
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Box>
        
      </Box>
      }
      <Table
        size = {"medium"}
        stickyHeader
        {...getTableBodyProps()}
        sx ={{

          padding:"0px"
        }}
    >
      {showHeader&&<TableHead
      
      >

      {headerGroups.map((headerGroup, i:number) => {
        return <TableRow
        
          key = {i+123}
          sx = {{
            backgroundColor: tableColor,
          }}>

          {headerGroup.headers.map((column, k) => {return <TableCell 
            key = {k}>
            
            <Typography sx = {{
                fontWeight: 400,
                fontSize: '16px',
                color:"#003366",
                fontStyle:"normal"
              }}>
              {column.render("Header")}
              </Typography>
            </TableCell>})
          }
        </TableRow>
      }
      )}
      </TableHead>}

      {rows.map((row:any, key:any)=>{
          prepareRow(row)
          return <React.Fragment key = {key}>
            <TableRow {...row.getRowProps()} sx = {{
              backgroundColor: tableColor,
              color:"#003366",
                fontWeight: 700
            }}>
                {row.cells.map( (c:any, k:number)=>{
                return <TableCell 
                  key = {k + 59}  sx = {{
                  color:"#003366",
                  fontSize: '21.3838px',
                  fontWeight: 700
                }}>
                  {c.render("Cell")}
              </TableCell>
              } )} 
          </TableRow>
          <TableRow
          >
            {/* Collapsible content  i.e plants for example*/}
              {expandRows && <TableCell colSpan={6}>
                
              { row.isExpanded? (
                // <span>{}</span>
                    renderRowSubComponent({ row })
                ) : null
                }
            </TableCell>}
          </TableRow>
        </React.Fragment>          
        })
      }
      </Table>
      {showPagination&&
      <div>
        <Pagination count={10} />
        Number of rows per page:

      </div>
    }
    </TableContainer>
}