import { Typography } from "@mui/material";
import React from "react";
import { translationWithCapitalization } from "../../../core";
import { SummaryCard } from "./SummaryCard";

export const NoRolesAssignedCard = ()=>{

    const NO_ROLES_ASSIGNED_TO_YOU = translationWithCapitalization("noRolesAssignedToYou");
    const PLEASE_CONTACT_ADMINSTRATOT = translationWithCapitalization("pleaseContactCustomerAdmin");
    const ERROR = translationWithCapitalization("error");
  
    return <SummaryCard>
          <Typography gutterBottom variant="h5" component="div">
            {ERROR}
          </Typography>
          <Typography variant="body2" color="text.primary">
            {NO_ROLES_ASSIGNED_TO_YOU}
          </Typography>
          <Typography variant="body2" color="text.primary">
            {PLEASE_CONTACT_ADMINSTRATOT}
          </Typography>
        </SummaryCard> 
  }