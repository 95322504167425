/* eslint-disable */

import { Typography } from '@mui/material';
import React from 'react';
import { translationWithCapitalization } from '../../../core';
import { SummaryCard } from './SummaryCard';

export const EnergySummary = () => {

  const energy = translationWithCapitalization("energy");
  const consumedEnergy = translationWithCapitalization("consumedEnergy");

  return <SummaryCard>
    <Typography gutterBottom variant="h5" component="div">
      {energy}
    </Typography>
    <Typography variant="body2" color="text.primary">
      {consumedEnergy}
    </Typography>
    <Typography variant="body2" color="text.primary">
    </Typography>
  </SummaryCard>
}