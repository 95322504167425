import dotp from "dot-prop-immutable-chain";
import { SettingsActions, SettingsActionType } from "./";
import { SettingsLocale, SettingsLocales } from "./interfaces";

interface CurrentPlant {
  plantId: string
  plantName: string
}
export interface SettingsState {
  name: string;
  server: string;
  locales: SettingsLocales;
  locale: SettingsLocale;
  redirectUrl:"",
  currentPlant: CurrentPlant;
  currentOperator: any;
  refreshUsers:boolean;
}

export const initialState: SettingsState = {
  name: "",
  server: "",
  locales:null,
  redirectUrl:"",
  locale: null,
  currentPlant: {
    plantName:"All",
    plantId:""
  },
  currentOperator:{
    operatorName:"All",
    operatorId:""
  }
};

export const settingsReducer = (
  state = initialState,
  action: SettingsActions
) => {
  switch (action.type) {
    case SettingsActionType.SET_LOCALES: {
      return dotp(state)
        .set("locales", action.payload)
        .value();
    }
    case SettingsActionType.SET_LOCALE: {
      return dotp(state)
        .set("locale", action.payload)
        .value();
    }
    case SettingsActionType.SET_CURRENT_PLANT: {
      return dotp(state)
        .set("currentPlant", action.payload)
        .value();
    }
    case SettingsActionType.SET_CURRENT_OPERATOR: {
      return dotp(state)
        .set("currentOperator", action.payload)
        .value();
    }

    default:
      return state;
  }
};
