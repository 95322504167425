import { makeStyles } from '@mui/styles';
import React, { useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import {  Grid, Paper } from '@mui/material';
import { translationWithCapitalization } from '../../core';
import { AsideCard, AsideCardModal } from '../../core/components/Card/Components';

export const EnergyPage = ()=>{
    const dispatch = useDispatch();
  
    const [openModal, setOpenModal] = useState(false);

      return (  <Grid container justifyContent={"space-between"} >
        <Grid  
          item
          container xs={12} 
          md = {9} 
          direction="row"
          justify="flex-start"
          alignItems="flex-start">
          
          <Grid item xs = {12}  component = {Paper} sx  ={{ padding:"50px", border: "1px solid #d3d3d3" }}  >
  
          </Grid>
        </Grid>
        <Grid 
          container item 
          xs={12} md = {2} 
          spacing={2}
          direction="row"
          height = {"10vh"}
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs= {12} >
          <AsideCardModal
            title = {"Energy"}
            data = {0}
            cardAction = {setOpenModal}
            actionState = {openModal}
            unit = {""}
            />
          </Grid>
          <Grid item xs= {12}>
            <AsideCard
             title = "test"/>
          </Grid>
        </Grid>
      </Grid>    
      )
}