import { IDuration } from "../browse/interfaces";

export const routers = {
  PLANT: "/plants",
  HOME: "/",
  OPERATIONS: "/operations",
  FINANCE: "/finance",
  ENERGY: "/energy",
  UTILIZATION: "/utilization",
  EMISSION: "/emission",
  PDF: "/pdf",
  USER: "/user",
  BROWSE: "/browse/:branchId",
  SEARCH: "/search",
  ACCOUNT_SETTINGS: "/accountsettings",
  SETTINGS: "/settings",
  USER_MANAGEMENT: "/usermanagement",
  ALARM_PAGE: "/alarms",
  EVENTS_PAGE: "/events",
  SHIP_REGISTRY: "/shipregistry"
};

export const DURATIONS: IDuration = { 7: "week", 30: "month", 365: "year" };

export const LOCALES = { ENG: "English", NOR: "Norwegian" };

export const ALTERNATIVE_LOCALES_NAME: { [key: string]: string } = {
  NOR: "Norwegian",
  ENG: "English"
};

export const CURRENCIES = {
  NORWEGIAN_KRONER: "NOK",
  EUROS: "EUR",
  ICELANDIC_KRONER: "ISK"
}
