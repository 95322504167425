import { createTheme } from '@mui/material/styles';
  
  // Common Theme settings
  
  const commonTheme = ({

      typography:{
        fontFamily:"Open Sans",
        allVariants:{
          fontFamily:"Open Sans",
          letterSpacing: '0.1rem',
        },
      },

    palette: {
      primary: {
        main: "#003366",
        light: '#e5f1f5'
      },
      secondary: {
        main: '##fffefe',
      },

      alarm:{
        primary:"#DA0000",
        seondary:"rgba(218, 0, 0, 0.3)"
      },

      text: {
        primary: "#003366",
        secondary: "#d8dddc"
      }
    },

    icons:{
      size:"",
      warning:{
        color:""
      },
      error:{
        color:""
      },
      ok:{
        color:""
      }
    },

    drawer: {
      zIndex:1,
    },
    paper: {
      position: 'absolute',
      width: 500,
      border: '2px solid #000',
    },
    
  });
  
  // Light Theme
  
  const lightThemeSettings = {
    
    ...commonTheme,
  };
  
  const theme = createTheme(lightThemeSettings);
  
  export default theme;
  