import * as yup from 'yup';

import React, { FC, useEffect, useState } from 'react';

import { isNil, startCase } from 'lodash';
import { Button, Checkbox, CircularProgress, DialogActions, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, makeStyles, Typography, Grid } from '@mui/material';
import axios from 'axios';

import { useApiUrl } from '../../../../settings/hook';
import addUserIcon from '../../../../../assets/icons/addUserIcon.svg';
import { ConfirmationDialogWithIcon, IConfirmationDialog } from '../../../Components/dialog';
import { translationWithCapitalization } from '../../../../core';
import { USER_MANAGEMENT_ENDPOINTS } from '../../endpoints';
import { IRoleDTO } from '../../interface';
import Divider from '@mui/material/Divider';

export const EditAllUserAccessesForUserDialog: FC<IConfirmationDialog>= (props:any)=>{
    const {open, handleClose, row, selectedUser, handleSubmit} = props;

    const MANAGE_USER_ACCESS = translationWithCapitalization("manageUseraccess")
    const MANAGE_USER_ACCESSES = translationWithCapitalization("manageUserAccesses")

    const name = startCase(selectedUser.firstName + " " + selectedUser.lastName);

    return <ConfirmationDialogWithIcon 
      dialogTitle={MANAGE_USER_ACCESS} 
      sourceTitle={MANAGE_USER_ACCESSES + " " + name}
      maxWidth = {'lg'}
      icon={addUserIcon} 
      open={open} 
      handleClose={handleClose} 
      warningText={''} 
      handleConfirm={undefined}    
      >
        <EditAllUserAccessesForUserForm
         handleClose={handleClose} 
         selectedUser={selectedUser}
         handleSubmit = {handleSubmit}
        />
    </ConfirmationDialogWithIcon>
}

export const EditAllUserAccessesForUserForm: any = (props:any) =>{
  
   const {handleClose, selectedUser, handleSubmit} = props;
   const [roleDefinitions, setRoleDefinitions] = useState([])
   const [isLoading, setIsLoading] = useState(true)
   const [responseMessage, setResponseMessage] = useState("")
   const apiURL = useApiUrl();

   const ROLES = translationWithCapitalization("roles")
   const UPDATE = translationWithCapitalization("update")
   const CANCEL = translationWithCapitalization("cancel")

   const [userData, setUserData] = useState();
   const [errorMessage, setErrorMessage] = useState("");
   const [updateMessage, setUpdateMessage] = useState("");

   const [plantsWithRoles, setPlantsWithRoles] = useState([]);

   const PLANT = translationWithCapitalization("plant")

   const [isError, setIsError] = useState(false);

   useEffect( ()=>{
     axios
     .get(`${apiURL}/Access/${selectedUser.id}/getUser`)
     .then( (i)=>{
       setUserData(i.data);
       
        // Objects are not allowed as values in checkboxes, we must therefore use a string with plantId:roleId

       var tempArray = [];

        {i.data.plantRoles.map( (plantRole:any)=>  {
            tempArray.push(`${plantRole.plant.id}:empty`)
            plantRole.roles.map( role=>{
                tempArray.push(`${plantRole.plant.id}:${role.id}`)
            } )

        })}
        setPlantsWithRoles(tempArray)

     } )
     .catch( x=>{
        setIsError(true)}
        )
     .finally( ()=>{
       setIsLoading(!isLoading)
     } )

     axios
     .get(`${apiURL}${USER_MANAGEMENT_ENDPOINTS.GET_ALL_ROLE_DEFINITIONS}`)
     .then( response => { 
       setRoleDefinitions(response.data)})

   },[] )

   const onEditUserSubmitClicked = () => {
    var plantsWithRolesFormatted = [];

    plantsWithRoles.map( (plantWithRole:any)=>{
        var splitted = plantWithRole.split(":")

        var roleIds = []
        if(splitted[1]!=="empty"){
            roleIds.push(splitted[1]);
        }

        var foundIndex = plantsWithRolesFormatted.findIndex( x=> x.plantId === splitted[0] )

        if(foundIndex===-1){
            var newObject = {
                'plantId': splitted[0],
                'roleIds': roleIds
            }
            plantsWithRolesFormatted.push(newObject)

        }

        else{

            var foundObject = plantsWithRolesFormatted[foundIndex]

            var rolesCopied = foundObject.roleIds;
            rolesCopied.push(splitted[1])
            foundObject.roleIds = rolesCopied;
            plantsWithRolesFormatted[foundIndex] = foundObject

        }

    } )

      if(!isNil(selectedUser)){
        setIsLoading(true)
        axios
        .post(`${apiURL}/Access/${selectedUser.id}/upsertPlantsAccesses`, plantsWithRolesFormatted)
        .then( (i)=>{
            setUserData(i.data)
            setUpdateMessage("Roles updated successfully")
            setTimeout(handleClose(), 1000)
        } )
        .catch( (e)=>{
            setErrorMessage(e)
            setIsError(true);
        } )
        .finally( ()=>{
            setIsLoading(false)
            }
        )
      }
    };
    return (
   
        <form
        >
          <hr/>
          
              {isLoading ?    
              <CircularProgress />:
              <Grid>
                <Grid 
                    item xs= {12} 
                    container>
                        <Grid item xs = {4}>
                            <FormLabel component="legend">{PLANT}</FormLabel>
                        </Grid>
                        <Grid item xs = {8}>
                            <FormLabel component="legend">{ROLES}</FormLabel>
                        </Grid>
                    </Grid> 
                    {!isError&&<Grid 
                        item xs= {12} 
                        container>
                        {Array.isArray(userData.plantRoles)&&userData.plantRoles.map( (x:any, key)=> {
                                    var roles = x?.roles
                                    return <Grid 
                                            key = {key}
                                            container>
                                                <Grid item xs = {4}>
                                                    <Typography sx = {{fontWeight:"700"}}>{x?.plant?.name}</Typography>
                                                </Grid> 
                                                <Grid item container xs = {8}>
                                                    { Array.isArray(roleDefinitions)&&roleDefinitions?.map((roleDefinition:IRoleDTO, key)=> 
                                                    <Grid item xs = {6} key = {roleDefinition.id} container>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    value={`${x.plant.id}:${roleDefinition.id}`}
                                                                    defaultChecked = {roles.filter(j=>j.id === roleDefinition.id).length>0} 
                                                                    onChange={
                                                                        (e)=>{
                                                                            if(e.currentTarget.checked){
                                                                                var plantsRoles = [...plantsWithRoles]
                                                                                plantsRoles.push(e.currentTarget.value)
                                                                                setPlantsWithRoles(plantsRoles)
                                                                            }
                                                                            else{
                                                                                var plantsRoles = [...plantsWithRoles]
                                                                                var foundIndex = plantsRoles.findIndex(i=>i===e.currentTarget.value)
                                                                                if(foundIndex>=0){
                                                                                    plantsRoles.splice(foundIndex, 1)
                                                                                    setPlantsWithRoles(plantsRoles)
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                />
                                                            }
                                                            label={roleDefinition.name}/>
                                                    </Grid>
                                                    )}
                                                </Grid>
                                                    <Divider sx={{width:'100%'}}/>
                                         </Grid>}
                                        
                                    
                                    )}
                                {
                                   isError&&<Grid>
                                    Could not find the user
                                   </Grid> 
                                }
                            <Grid>{updateMessage}</Grid> 
                            
                    </Grid> 
              }
                </Grid>
              }
              <DialogActions>
              <Button variant="text" onClick={props.handleClose} >{CANCEL}</Button>
              <Button variant="contained" autoFocus onClick = {onEditUserSubmitClicked}>
                {UPDATE}
              </Button>
            </DialogActions>
            <Typography >
            {responseMessage}
        </Typography>
    </form>
    );

    }