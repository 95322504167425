import React, { useState } from 'react';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from "react-hook-form";
import { Button, Checkbox, CircularProgress, DialogActions, FormControlLabel, FormGroup, FormHelperText, FormLabel, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';

import { translationWithCapitalization } from '../../../../core';
import addUserIcon from '../../../../../assets/icons/addUserIcon.svg';
import { ConfirmationDialogWithIcon } from '../../../Components/dialog';

  const schema = yup.object().shape({
    givenName: yup.string().required(),
    familyName:yup.string().required(),
    email: yup.string().email().required(),
  })
  
  export const UserForms: any = (props:any) =>{

    const {handleClick, handleClose} = props;

    const ADD =translationWithCapitalization("add")
    const CANCEL =translationWithCapitalization("cancel")
    const EMAIL =translationWithCapitalization("email")
    const LAST_NAME =translationWithCapitalization("lastName")
    const FIRST_NAME =translationWithCapitalization("firstName")

    const { 
      control,
      register, 
      handleSubmit, 
      watch, 
      reset,
      resetField,
      setValue,
      getValues,
      formState: { errors}
    } = useForm({
      resolver:yupResolver(schema)
    });

    const onSubmit = (data: any, e: any) => {
      handleClick(data)
      setTimeout( handleClose(), 3000)
      
    } 
    const onError = (errors: any, e: any) => console.error(errors, e);
  
  return<> 
    <form
      onSubmit={(handleSubmit(onSubmit, onError))}
      >
     
      <div
        style = {{
          display:"flex",
          flexWrap: "wrap",
          gap:'15px'
        }}
          >
            {/* TODO: Use the new controlled components that are used in ship-registry */}
        <Controller
          name="givenName"
          control={control}
          render={({ field }) => <TextField
            {...field}
            autoFocus
            error = {errors.givenName && errors.givenName?.message && <span> {errors.givenName.message} </span>}
            margin="dense"
            id="givenName"
            label={FIRST_NAME}
            variant="standard"
            helperText={errors.givenName?errors.givenName.message:""}
          />}
        />
        <Controller
            name="familyName"
            control={control}
            render={({ field }) => <TextField
              {...field}
              autoFocus
              error = {errors.familyName && errors.familyName.message && <span>{errors.familyName.message}</span>}
              margin="dense"
              id="familyName"
              label={LAST_NAME}
              variant="standard"
              helperText={errors.familyName?errors.familyName.message:""}
            />}
          />
        <br/>
        <Controller
          name="email"
          control={control}
          render={({ field }) => <TextField
            {...field}
            autoFocus
            margin="dense"
            error = {errors.email && errors.email.message && <span>{errors.email.message}</span>}
            id="email"
            type="email"
            label={EMAIL}
            fullWidth
            variant="standard"
            helperText={errors.email?errors.email.message:""}
          />}
        />
          </div>
          <div>
      </div>
      <DialogActions>
          <Button variant="text" onClick={handleClose} >
            {CANCEL}
          </Button>
          <Button variant="contained" autoFocus type = "submit">
            {ADD}
          </Button>
         </DialogActions> 
        </form>
      </>
  }

  export const AddUserDialog = (props:any)=>{
    const {open, handleClose, plantsList, customersList, handleClick} = props;

    const ADD_USER =translationWithCapitalization("addUser")


    return <ConfirmationDialogWithIcon 
      dialogTitle={ADD_USER} 
      icon={addUserIcon} 
      search={undefined} 
      open={open} 
      handleClose={handleClose} 
      warningText={''} 
      handleConfirm={undefined}      
      >
        <UserForms
          handleClose={handleClose}
          handleClick = {handleClick}
          customersList = {customersList}
          plantsList = {plantsList}
        />
    </ConfirmationDialogWithIcon>

  }