import { string } from "yup"

export interface IAlarm {
    plant: any
    searchAlarms: any
    totalCount:number,
    id:string, //plantName
    alarmName:string,
    lastEventType:string,
    lastEventTime:string ,
    annunciationTime:Date,
    lastActiveTime:Date,
    lastNormalTime:number,
    closedTime:number,
    acknowledgedBy:number,
    activationId:number,
    isActive:number
    isClosed:number,
    alarmPriority:number
    alarmType:number,
    homeNavigation:number,
    tags:number,
    texts:any[],
    HasHubRequiredData:boolean
}

export interface IAlarmText{
    name: string,
    locale: string,
    content: string
}

export interface IPagination{
    pageNumber:number,
    pageSize:number
}

// TODO: Make a more general interface for both alarms and events, make IAlarmSearchRequest implement this?
export interface IAlarmSearchRequest {
    plants:string[],
    searchText:string,
    locale:string,
    fetchTotalCount:boolean,
    isActive:AlarmOptions,
    pagination:IPagination
}


export enum AlarmOptions{
    All,
    ActiveAlarms,
    NotActiveAlarms
}

export const SearchForAlarmsOptions = [
    { value: AlarmOptions.All, label: "All alarms"},
    { value: AlarmOptions.ActiveAlarms, label: "Active alarms"},
    { value: AlarmOptions.NotActiveAlarms, label: "Inactive alarms"},
]