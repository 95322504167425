import { Action } from "redux";
import { IFailureAction, ISuccessAction } from "../../../core";
import { IEvent } from "./interfaces";

export enum EventActionType {
  REQUEST_ALL = "[Event] Request All",
  REQUEST_ALL_SUCCESS = "[Event][Success] Request All",
  REQUEST_ALL_FAILURE = "[Event][Failure] Request All",

  REQUEST_SEARCH_EVENTS_FOR_PLANTS = "[Alarm] Request Search Events For Plants",
  REQUEST_SEARCH_EVENTS_FOR_PLANTS_SUCCESS = "[Alarm] Request Search Events For Plants Success",
  REQUEST_SEARCH_EVENTS_FOR_PLANTS_FAILURE = "[Alarm] Request Search Events For Plants Failure"
}

export class EventRequestAll implements Action {
  readonly type = EventActionType.REQUEST_ALL;

  constructor(public payload: any) {}
}

export class EventRequestAllSuccess implements Action, ISuccessAction {
  readonly type = EventActionType.REQUEST_ALL_SUCCESS;
  readonly success = true;
  constructor(public payload: IEvent) {}
}

export class EventRequestAllFailure implements Action, IFailureAction {
  readonly type = EventActionType.REQUEST_ALL_FAILURE;
  readonly failure = true;
  constructor(public payload: any) {}
}


export class RequestSearchForEventsForPlants implements Action {
  readonly type = EventActionType.REQUEST_SEARCH_EVENTS_FOR_PLANTS;

  constructor(public payload: any) {}
}

export class RequestSearchForEventsForPlantsSuccess implements Action, ISuccessAction {
  readonly type = EventActionType.REQUEST_SEARCH_EVENTS_FOR_PLANTS_SUCCESS;
  readonly success = true;
  constructor(public payload: IEvent) {}
}

export class RequestSearchForEventsForPlantsFailure implements Action, IFailureAction {
  readonly type = EventActionType.REQUEST_SEARCH_EVENTS_FOR_PLANTS_FAILURE;
  readonly failure = true;
  constructor(public payload: any) {}
}


export type EventActions =
  | EventRequestAll
  | EventRequestAllSuccess
  | EventRequestAllFailure
  | RequestSearchForEventsForPlants
  | RequestSearchForEventsForPlantsSuccess
  | RequestSearchForEventsForPlantsFailure
