
const common = {
    high:"høg", //Alto
    medium:"middels", //média
    alarm:"alarm",
    alarms:"alarmar",
    allCustomers:"alle kundar",
    allPlants:"alle anlegg",
    all:"alle",
    total:"total",
    events:"hendingar",
    records:"registreringar",
    energy:"energi",
    lastMonth:"siste måned",
    now:"no",
    signOut:"logg ut",
    viewAllAlarms:"sjå alle alarmar",
    home:"heim",
    settings:"innstillingar",
    notifications:"varslar",
    accountsettings:"kontoinnstillingar",
    search:"søk",
    ok:"ok",
    meteredEnergy: "målt energi",
    filterBy:"filtrer etter",
    meteredHours: "målt timar",
    sessionStart:"start",
    sessionEnd:"slutt",
    duration:"tid",
    consumedPower:"forbrukt energi",
    harbor:"hamn",
    noText:"ingen tekst funnen"
}

const glossary = {
    cancel:"avbryt",
    selected: "valdt",
    noData:"ingen data",

    users:"brukara",
    customers:"kundar"

}

const operations ={
    operations:"operasjonar",
    alarmsActive:"aktive alarmar",
    noAlarms: "ingen alarmar",
    allPlants:"alle anlegg",
    operationsOffLine:"Operasjonar nede! Ver vennleg å kontakte system-adminstrator",
    plant:"anlegg",
    lines:"linjer",
    activeAlarms:"aktive alarmar",
    linesInUse:"linjer i bruk" ,
    consumedEnergy:"forbrukt energi",
    line:"linje",
    active:"aktive",
    inactive:"ikkje aktive",
    error:"feil",
    shipIsNotConnected: "ingen aktiv sesjon for denne linja",
    atLeastOneActiveAlarm: "Anlegg  har minst èin aktiv alarm",
    noActiveAlarms: "Anlegg har ingen aktive alarmar",
    plantNotOnline: "Anlegg er ikkje på nett",
    noActiveAlarmsFound: "Ingen alarmer er funnen! Problem med tilkopling",
    telemetryNotAvailable: "Telemetri er ikkje tilgjengeleg! Prøv igjen seinare!",
    lineHasMissingData: "linja manglar data, ver venleg å kontakte adminstrator for anlegg",
    viewAlarmDetails:"Sjå alarm detaljar",
}

const shipRegistry = {
    addShip: "legg til skip",
    editShip:"rediger Ship",
    shipRegistry: "skipsregister",
    shipData: "skipsdata",
    vesselName:"fartøynavn",
    shipRegistryId:"skipsregister Id",
    contactPhoneNumber:"kontakttelefonnummer",
    selectAShipRegister:"vel eit skipsregister...",
    noShipRegistrySelected:"ingen skipsregister valdt...",
    imoNumber:"IMO-nummer",
    contactName:"kontaktnavn",
    contactInformation:"kontaktinformasjon",
    contactEmail:"kontakt E-post",
    price:"pris",
    save:"lagre"
}

const finance = {
    finance:"finans",
    lastTransactions:"siste transaksjonar",
    price:"pris",
    totalSessionPrice:"pris for heile sesjonen",
    noPlantSelected: "ingen anlegg valdt, eller faktura funnen"
}

const settings = {
    languages:"språk",
    accountSettings:"kontoinnstillingar",
    accountsettings:"kontoinnstillingar",
    
}

const userManagement = {
    usermanagement:"brukarhandsaming",
    inviteUser:"inviter brukar",
    noRolesAssignedToYou: "du har ikkje tilgong til nokre anlegg",
    pleaseContactCustomerAdmin: "ver venleg å kontakte superbrukar",
    selectCustomer:"vel brukar",
    isARequiredField:"er eit nødvendig felt",
    displayNameForPlant:"visningsnamn for anlegg",
    shortNameForPlant:"kortnavn for anlegg",
    selectExistingPlant:"vel eit eksisterande anlegg",
    searchForUser: "søk etter brukar...",
    selectExistingUser:"vel ein eksisterande brukar",
    addUsersAndRolesFromAnExistingPlant:"legg til rollar og brukara ifrå eit eksisterande anlegg",
    copyUsersAndRolesFrom:"dette vil kopiere brukara og rollar ifrå",
    createPlantConfirmation:"Bekreft lag anlegg",
    manageAllRoles:"handter alle rollar",
    manageUserAccesses:"rediger rollar for brukar",
    to:"til",
    from:"frå",
    remove:"fjerne",

    areYouSureYouWantTo:"er du sikker på at du vil",

    add: "legg til",
    user: "brukar",
    addUser:"legg til brukar",

    addUserToPlant: "legg til brukar på anlegg",
    roles:"rollar",
    email:"epost",
    lastName:"etternamn",
    firstName:"fornamn",
    selectUser:"vel brukar",
    editRoles:"styr brukartilgang",
    removeFromPlant:"fjern brukar ifrå anlegg",
    manageUseraccess:"styr brukartilgong",
    customer:"kunde",

    update:"oppdater",


    userHasBeenAdddedTo: "brukar har blitt lagt til",
    errorAddingUserTo: "feil ved å legge til",
}


const pageNotFound = {
    fourOFour:"404 side ikkje funnen",
    couldNotFind:"Årsak! Me klarte ikkje å finne sida du leita etter",
    whatCouldhaveCaused404: "Kva kunne ha forårsaka denne meldinga?",
    removedPageInQuestion:"Me har fjerna sida ifrå vår teneste",
    linkClickedBroken:"Lenka er gammal eller dårleg",
    typedAddressWrongly:"Addressa er feilstava",
    whatCanYouDoFourOFour:"Kva kan du gjere?",
    notifyBrokenLink:"gi oss ei melding om at lenka ikkje fungerer",
    retypeUrlFourOFour:"skriv inn URL på ny",
    clickButtonBelowFourOFour:"trykk knappen nedanfor",
    takeMeHome:"Ta meg heim",
   }
   
const emission = {
    emission:"utslepp",
}

const alarms ={
    allAlarms:"alle alarmar",
    activeAlarms:"aktive alarmar",
    inActiveAlarms:"inaktive alarmare",
    selectAlarmStatus:"vel alarmstatus",
    activeStatus:"aktivstatus",
    inactiveAlarms:"inaktive alarmar",


    searchForAlarms:"søk for alarmar",
    viewAllAlarms:"sjå alle alarmar",
    
    priority:"prioritet",
    alarmText: "alarmtekst",
    status:"status",
    notAcknowledgedAlarm:"ikkje kvittert",
    acknowledgedBy: "kvittert av"
}

const events ={
    searchForEvents:"søk for hendingar",
    events:"hendingar",
    source: "kjelde",
    time:"tid",
    eventTexts: "tekst"

}


export const TRANSLATIONS_NNO = {
    ...settings,
    ...pageNotFound,
    ...shipRegistry,
    ...alarms,
    ...events,
    ...userManagement,
    ...common,
    ...glossary,
    ...operations,
    ...finance,
    ...emission,
    utilization:"utnyttingsgrad",
    energy:"energi",
    operators:"operatørar",
    customers:"kundar",
    alarm:"alarm",
    plants:"anlegg",
    vessel:"skip",
    total:"total",
    viewAll: "sjå alle",
    ipsum:"O Lorem Ipsum é um texto modelo da indústria tipográfica e de impressão. O Lorem Ipsum tem vindo a ser o texto padrão usado por estas indústrias desde o ano de 1500, quando uma misturou os caracteres de um texto para criar um espécime de livro. Este texto não só sobreviveu 5 séculos, mas também o salto para a tipografia electrónica, mantendo-se essencialmente inalterada. Foi popularizada nos anos 60 com a disponibilização das folhas de Letraset, que continham passagens com Lorem Ipsum, e mais recentemente com os programas de publicação como o Aldus PageMaker que incluem versões do Lorem Ipsum. "
   };