import AddIcon from '@mui/icons-material/Add';
import { Button } from "@mui/material";

import React, { useState } from "react";
import { translationWithUppercase } from '../../../../core';
import { AddShipToRegistryDialog } from '../dialog/AddShipToRegistryDialog';

const AddButtonStyle = {
    marginLeft:"auto", 
    backgroundColor:"#D2E5EB", 
    paddingLeft:"50px", 
    paddingRight:"50px", 
    paddingTop:"10px", 
    paddingBottom:"10px"}

interface IAddButtonProps{
  handleAddShip:Function;
  disabled:boolean;
}

export const AddButton = (props: IAddButtonProps)=>{
    const {handleAddShip, disabled} = props;
    const [addShipDialogOpen, setAddShipDialogOpen] = useState<boolean>(false);

    const ADD_SHIP = translationWithUppercase("addShip")

    const handleAddShipDialogClose = ()=> setAddShipDialogOpen(false);
  
    return <> <Button
                disabled={disabled}
                onClick = {()=>{setAddShipDialogOpen(!addShipDialogOpen)}} 
                sx = {AddButtonStyle}>
              <AddIcon/>
              {ADD_SHIP}
            </Button>
              <AddShipToRegistryDialog
                editMode={false}
                handleSubmit = {handleAddShip}
                open={addShipDialogOpen}
                handleClose ={handleAddShipDialogClose}
                />
            </>
  }