import { IPagination } from "../interfaces"

export interface IEvent {
    totalCountFetched: boolean
    totalCount: number
    searchEvents: any
    id:string,
    plant:string
    time:any,
    relatedObjectName:string
    type:string
    username:string
    texts:any,
    arguments:any,
    source:string
}

export interface IEventSearchRequest {
    plants:string[],
    searchText:string,
    locale:string,
    fetchTotalCount:boolean,
    pagination:IPagination
}

export enum LoadingStatus {
    NOT_STARTED,
    LOADING,
    SUCCESS,
    FAILURE,
}