import React from "react";

import { Document, Font, PDFViewer, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';

import dayjs from "dayjs";
import { CURRENCIES } from "../../../constants/constants";


Font.register({family: "Inter", src:"../../../../assets/typography/inter.css"})

const styles = StyleSheet.create({
    page: {
      color: "black",
      flexDirection: "row",
      fontSize:"10.5px"
    },
    shipname:{
        fontFamily:"Helvetica",
        fontWeight:400,
        letterSpacing:"1",
        fontStyle:"normal",
        marginLeft:40,
        fontSize:"13px"
    },
    shipnameData:{
        fontFamily:"Helvetica",
        fontWeight:400,
        letterSpacing:"1",
        fontSize:"13px",
        marginLeft:40,
        color:"#737373"
    },
    invoiceDateHeader:{
        fontWeight:700,
        fontFamily:"Helvetica",
        letterSpacing:"1",
        color:"#000000",
    },
    invoiceDateData:{
        fontWeight:300,
        letterSpacing:"1",
        color:"#737373"
    },
    square:{
        textAlign:"center",
        marginLeft:50,
        marginBottom:50,
        padding:20,
        width:'30%',
        backgroundColor:"#C4C4C4"
    },

    square2:{
        padding:20,
        marginLeft:0,
        backgroundColor:"#F8F8F8"
    },

 
    cellData:{
        fontWeight: 400,
        letterSpacing:"1",
        color:"#737373",
    },

    square3:{
        padding:10,
        marginTop:100,
        marginBottom:40,
        marginLeft:270,
        width:'50%',
        backgroundColor:"#F8F8F8"
    },
    invoiceDate:{
        marginLeft:450,
        marginTop:30,
        width:"60%"
    },

    cellRow:{
        display:"flex",
        flexDirection:"row",
        marginLeft:51
    },
    cellHeader:{
        letterSpacing:"1",
    },

    viewer: {
      width: window.innerWidth/1.1, //the pdf viewer will take up all of the width and height
      height: window.innerHeight/1.3,
    },  
    section: {
        // margin: 10,
        padding: 10,
        flexGrow: 1
      },


    article: {
      width: 550,
      flexWrap: "nowrap",
    },

    line:{
        borderTop:"1.5px solid black",
        padding: 10,
        flexGrow: 1
    },

    table:{
        flex:1,
    },

    tableCell:{
        display:'flex',
        flexDirection:'row',
        flexWrap:'nowrap'
    },


    tableData:{
        fontWeight: 400,
        color:"#737373",
        flexGrow:1,
        alignItems:"flex-end",
        justifySelf: 'flex-end',
    },

    headerContainer: {
        flexDirection: 'row',
        marginBottom: 10,
      },
      leftColumn: {
        flexDirection: 'column',
        flexGrow: 9,
      },
      rightColumn: {
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'flex-end',
        justifySelf: 'flex-end',
      },
      title: {
        fontSize: 11,
        color: 'black',
        textDecoration: 'none',
        fontFamily: 'Lato Bold',
      },

});
  
const getCurrency = (plantName: string) => {
    switch (plantName.toLowerCase()) { 
        case "bosk":
            return CURRENCIES.EUROS
        case "efla":
            return CURRENCIES.ICELANDIC_KRONER
        default:
            return CURRENCIES.NORWEGIAN_KRONER
    }
 }


export const PdfPage = ()=>{

    const { t } = useTranslation();
    var now = dayjs()

    const history = useHistory();
    const rows = history.location.state 

    const today = now.format('DD/MM/YYYY')
    
        return <PDFViewer style={styles.viewer}>
            {/* Start of the document*/}
            <Document
                title={`Invoice ${today}-${rows.length}`}

            >
              {rows.map((r, k)=> <Page size="A4" style={styles.page} key = {k}>
                <View style={styles.article}>
                <View style={styles.invoiceDate}>
                    <Text style={styles.invoiceDateHeader}>Invoice Date</Text>
                    <Text style={styles.invoiceDateData}>{r.sessionEnd.slice(0,-6).replaceAll(".","/")}</Text>
                </View>
                 {/* <View style={styles.square}>
                    <Text>Logo</Text>
                </View> */}
                
                 <View style={styles.square2}>
                    <Text style = {styles.shipname}>Ship</Text>
                    <Text style = {styles.shipnameData}>{r.imo}, {r.shipName}</Text>
                </View>
                <View style={styles.cellRow}>
                    <View style = {styles.section}>
                        <Text style = {styles.cellHeader}>Plant</Text>
                        <Text style = {styles.cellData}>{r.plant}</Text>
                    </View>
                </View>
                <View style={styles.cellRow}>
                    <View style = {styles.section}>
                        <Text style = {styles.cellHeader}>Line</Text>
                        <Text style = {styles.cellData}>{r.line}</Text>
                    </View>
                    
                </View>
                <View style={styles.cellRow}>
                    <View style = {styles.section}>
                        <Text style = {{fontWeight:"bold"}}>Session Start</Text>
                        <Text style = {styles.cellData}>{r.sessionStart}</Text>

                    </View>
                    <View style={styles.section}>
                        <Text style = {styles.cellHeader}>Session End</Text>
                        <Text style = {styles.cellData}>{r.sessionEnd}</Text>
                    </View>
                </View>
                {/* <View style={styles.cellRow}>
                    <View style = {styles.section}>
                        <Text style = {styles.cellHeader}>Operating hour start</Text>
                        <Text style = {styles.cellData}>12345</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style = {styles.cellHeader}>Operating hour end</Text>
                        <Text style = {styles.cellData}>12345</Text>
                    </View>
                </View> */}
                
                <View style={styles.cellRow}>
                    {/* <View style = {styles.section}>
                        <Text style = {styles.cellHeader}>Maximum load</Text>
                        <Text style = {styles.cellData}>12345</Text>
                    </View> */}
                    <View style={styles.section}>
                        <Text style = {styles.cellHeader}>Elapsed hours</Text>
                        <Text style = {styles.cellData}>{r.duration.toFixed(1)} h</Text>
                    </View>
                </View>
                <View style={styles.square3}>
                
                 {/* <View style={styles.line}> */}


                <View style={styles.headerContainer}>
                    <View style={styles.leftColumn}>
                    <Text style = {styles.cellHeader}>Consumed Power</Text>
                    </View>
                    <View style={styles.rightColumn}>
                    <Text style={styles.cellData}>{r.consumedPower} kWh </Text>
                    </View>
                </View>

                <View style={styles.headerContainer}>
                    <View style={styles.leftColumn}>
                    <Text style = {styles.cellHeader}>Price</Text>
                    </View>
                    <View style={styles.rightColumn}>
                    <Text style={styles.cellData}>{r.price} {getCurrency(r.plant)} / kWh</Text>
                    </View>
                </View>

                <View style={styles.line}>
                   
                </View>

                <View style={styles.headerContainer}>
                    <View style={styles.leftColumn}>
                    <Text style = {styles.cellHeader}>Total Price</Text>
                    </View>
                    <View style={styles.rightColumn}>
                    <Text style={styles.cellData}>{r.totalSessionPrice} {getCurrency(r.plant)}</Text>
                    </View>
                </View>
                
                </View>
                </View>

              </Page>)}
              
            </Document>
          </PDFViewer>
    }