import React, { FC, useEffect, useState } from 'react';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from "react-hook-form";
import { Button, DialogActions, Grid, Typography } from '@mui/material';

import { IShip } from '../../interfaces';
import { FormInput } from '../../../Components/forms/formInput';
import { translationWithCapitalization } from '../../../../core';
import { ConfirmationDialogWithIcon } from '../../../Components/dialog';
import shipRegistryIconBlue from "../../../../../assets/icons/blue_ship.svg"; 

  const schema = yup.object().shape({
    imo: yup.string().required("test").length(7,"ImoNumber must be 7 characters"),
    price:yup.number().required("test"),
    vesselName: yup.string().required("test"),
    contactName: yup.string().notRequired().nullable(),
    contactPhoneNumber: yup.string().notRequired().nullable(),
    contactEmail: yup.string().email().notRequired().nullable(),
  })

  interface IShipFormsProps{
    editMode:boolean,
    handleClose:Function,
    handleClick:Function,
    selectedShip:IShip
  }
  
  export const ShipForms: any = (props:IShipFormsProps) => {

    const { editMode, handleClose, selectedShip, handleClick } = props;

    const SHIP_DATA = translationWithCapitalization("shipData");
    const VESSEL_NAME = translationWithCapitalization("vesselName");
    const IMO_NUMBER = translationWithCapitalization("imoNumber");
    const CONTACT_INFORMATION = translationWithCapitalization("contactInformation");
    const CONTACT_NAME = translationWithCapitalization("contactName");
    const CONTACT_PHONE_NUMBER = translationWithCapitalization("contactPhoneNumber");
    const EMAIL = translationWithCapitalization("email");
    const PRICE = translationWithCapitalization("price");
    const CANCEL = translationWithCapitalization("cancel");
    const SAVE = translationWithCapitalization("save");

    const methods = useForm({
        resolver:yupResolver(schema)
      })

      const { 
        control,
        register, 
        handleSubmit, 
        setValue,
        reset,
        formState: { errors}
      } = methods

      useEffect( ()=>{

        if(editMode){
          
          const defaultvalues = {
            imo:selectedShip.imo,
            price:selectedShip.price,
            contactName: selectedShip.contactName,
            vesselName:selectedShip.vesselName,
            contactEmail:selectedShip.contactEmail,
            contactPhoneNumber:selectedShip.contactPhone
          }
          reset({ ...defaultvalues });
        }
        
      },[] )

    const onSubmit = (data: any, e: any) => {

    if (editMode){
      handleClick(data, selectedShip.id);
    }

    else{
      handleClick(data);
    }

    } 
    const onError = (errors: any, e: any) => console.error(errors, e);

  return<> 
    <FormProvider
    {...methods}>

    <form
      onSubmit={(handleSubmit(onSubmit, onError))}
      >
        <Grid
            container
            spacing={8}
            >
              <Grid 
                item 
                container
                spacing={1}
                xs = {6}>
                <Grid 
                  item 
                  xs={12}
                >
                  <Typography
                    sx  ={{
                      fontWeight: '700',
                      fontSize: '16px',
                      color:'#003366',
                      textTransform:"uppercase",
                      letterSpacing: '0.1em'
                    }}
                  >
                    {SHIP_DATA}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormInput name={"vesselName"} label={VESSEL_NAME} required/>
                  </Grid>
                  <Grid item xs={6}>
                    <FormInput name={"imo"} label={IMO_NUMBER} required isNumber isDisabled={editMode} />
                  </Grid>
                  <Grid item xs = {6}>
                    <FormInput name={"price"} label={PRICE} isNumber required/>
                </Grid>
                </Grid>

                <Grid 
                  item 
                  container
                  spacing={1}
                  xs = {6}>
                  <Grid item xs={12}>
                    <Typography
                      sx  ={{
                        fontWeight: '700',
                        fontSize: '16px',
                        color:'#003366',
                        textTransform:"uppercase",
                        letterSpacing: '0.1em'
                      }}
                    >
                      {CONTACT_INFORMATION}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput name={"contactName"} label={CONTACT_NAME}  required={false} />
                  </Grid>
                  <Grid item xs={6}>
                      <FormInput name={"contactPhoneNumber"} label={CONTACT_PHONE_NUMBER}  required={false}/>
                  </Grid>
                  
                  <Grid item xs={6}>
                      <FormInput name={"contactEmail"} label={EMAIL} required={false}/>
                  </Grid>

                </Grid>

                <Grid
                  item
                  justifyContent="flex-end"
                >
                  <DialogActions>
                    <Button 
                      variant="contained"
                      sx = {{
                        backgroundColor: 'rgba(218, 0, 0, 0.6)'
                      }} 
                      onClick={handleClose} >
                      {CANCEL}
                    </Button>
                    <Button variant="contained" autoFocus type = "submit">
                      {SAVE}
                    </Button>
                  </DialogActions>
                </Grid>     
              </Grid>
                
            </form>
        </FormProvider>
      </>
  }

  interface IAddShipToRegistryDialogProps{
    open:boolean, 
    handleClose:Function
    plantsList?:any[]
    customersList?:any[]
    selectedShip?:any, 
    editMode:boolean,
    handleSubmit:Function
  }

  export const AddShipToRegistryDialog = (props:IAddShipToRegistryDialogProps)=>{
    const {open, handleClose, plantsList, customersList, selectedShip, editMode, handleSubmit} = props;

    const ADD_SHIP = translationWithCapitalization("addShip")

    const EDIT_SHIP = translationWithCapitalization("editShip")

    const titleText = editMode?EDIT_SHIP:ADD_SHIP;

    return <ConfirmationDialogWithIcon
      dialogTitle={titleText}
      icon={shipRegistryIconBlue}
      open={open}
      maxWidth = {'lg'}
      handleClose={handleClose}
      warningText={''}
      sourceTitle={''} 
      explanatoryText={''}          
      >
        <ShipForms
          handleClick = {handleSubmit}
          selectedShip = {selectedShip}
          editMode = {editMode}
          handleClose={handleClose} 
          customersList = {customersList}
          plantsList = {plantsList}
        />
    </ConfirmationDialogWithIcon>

  }