import React, { FC, InputHTMLAttributes } from 'react';

import Icon from '@mui/material/Icon';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';


/**
 * * Generic Confirmation-dialog
 *  @param icon: Icon to the left of the modal text
 *  @param sourceTitle: Title for plant, but could be customer in the future (i.e Plant 1, plant 2 etc)
 *  @param dialogTitle Title for the dialog-box
 *  @param children - Child elements for the modal (i.e buttons, form elements etc)
 *  @param explanatoryText Text that is immidieatly underneath the modal-title 
 *  @param open: Whether the dialog/modal should stay open or not
 *  @param handleClose: Method for handling closing of the modal/dialogue (passed down)
 *  @param handleConfirm: Method for handling confirm/ok etc of the modal.
 *  @param maxWidth MaxWidth for dialogbox
 */

export type WindowMaxWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined

interface ConfirmationDialogProps extends InputHTMLAttributes<HTMLInputElement>{
  icon:any,
  sourceTitle:string,
  explanatoryText:string,
  dialogTitle:string,
  children:any,
  open:boolean,
  handleClose:Function,
  warningText?:string,
  handleSubmit?:Function,
  maxWidth: WindowMaxWidth,
}


export const ConfirmationDialogWithIcon: FC<ConfirmationDialogProps> = (props:ConfirmationDialogProps)=>{
    const {
      open,
      handleClose,
      dialogTitle,
      icon,
      children,
      sourceTitle,
      explanatoryText,
      maxWidth
      } = props;

    return  <div>
    <Dialog
      fullWidth
      maxWidth= {maxWidth}
      PaperProps=
      {{sx: {
          justifyContent:'center'
       } }}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >

      <DialogTitle 
        id="alert-dialog-title"
        sx = {{
          alignItems:"center",
          display:"flex",
          fontWeight: '700',
          gap:'15px'
        }}
      >
        <Icon>
           <img src = {icon} height={'90%'}/>
        </Icon>
        {dialogTitle}
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#003366',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
        <DialogTitle 
        style = {{
          fontWeight: '700',
          }}>
        {sourceTitle}
        </DialogTitle>
      <DialogContent>
        
        {children}
        <Typography>
        {explanatoryText}
      </Typography>
      </DialogContent>
    </Dialog>
  </div>
  }