const common = {
    high:"high",
    medium:"medium",
    alarm:"alarm",
    alarms:"alarms",
    events:"events",
    records:"records",
    total:"total",
    viewAllAlarms:"view all alarms",
    energy:"energy",
    signOut:"Sign out",
    loadingPleaseWait:"loading please wait...",
    lastMonth:"last month",
    time:"time",
    now:"now",
    settings:"settings",
    accountSettings:"account settings",
    accountsettings:"account settings",
    search:"search",
    notifications:"notifications",
    all:"all",
    confirm:"confirm",
    ok:"ok",
    cancel:"cancel",
    add:"add",
    meteredEnergy: "Metered Energy",
    meteredHours: "Metered Hours",
    sessionStart:"session start",
    sessionEnd:"session end",
    filterBy:"filter by",
    duration:"duration",
    consumedPower:"Consumed Power",
    harbor:"harbor",
    noText:"no text found"
}

const glossary = {
    selected: "selected",
    noData:"no data"
}

const shipRegistry = {
    addShip: "add ship",
    editShip:"edit Ship",
    shipData: "ship data",
    shipRegistry: "ship registry",
    vesselName:"vessel name",
    shipRegistryId:"shipregistry Id",
    contactPhoneNumber:"contact phone number",
    imoNumber:"IMO Number",
    siteName:"site name",
    contactName:"contact name",
    contactInformation:"contact information",
    selectAShipRegister:"select a ship register...",
    noShipRegistrySelected:"no ship-registry-selected...",
    contactEmail:"contact E-Mail",
    price:"price",
    save:"save"
}

const operations ={
    operations:"operations",
    allPlants:"all plants",
    allCustomers: "all customers",
    alarmsActive:"active alarms",
    noAlarms: "no alarms",
    operationsOffLine:"operations offline! Please contact system admin",
    lines:"lines",
    line:"line",
    shipIsNotConnected: "No active session for this line",
    plant:"plant",
    active:"active",
    activeAlarms:"active alarms",
    linesInUse:"lines in use" ,
    consumedEnergy:"consumed energy",
    atLeastOneActiveAlarm: "Plant has at least one active alarm",
    noActiveAlarms: "Plant has no active alarms",
    plantNotOnline: "Plant is not online",
    noActiveAlarmsFound: "No Active Alarms found! Problem with connection",
    telemetryNotAvailable: "Telemetry not available! Try again later!",
    viewAlarmDetails:"View alarm details",
    inactive:"inactive",
    inactiveAlarms:"inactive Alarms",
    lineHasMissingData: "line has missing data, please contact the plant adminstrator",
    error:"error"
}

const finance = {
    lastTransactions:"last transactions",
    finance:"finance",
    price:"price",
    totalSessionPrice:"total session price",
    noPlantSelected: "No plant selected, or no invoices found"
}

const settings = {
    languages:"languages",
}

const userManagement = {
    usermanagement:"user management",
    inviteUser:"invite user",
    noRolesAssignedToYou: "you do not currently have any access to any plants",
    pleaseContactCustomerAdmin: "please contact your responsible super user",
    selectCustomer:"select customer",
    isARequiredField:"is a required field",
    displayNameForPlant:"displayname for plant",
    shortNameForPlant:"shortname for plant",
    searchForUser: "search for user...",
    selectExistingPlant:"Select existing plant",
    selectExistingUser:"Select existing user",
    addUsersAndRolesFromAnExistingPlant:"Add users and roles from an existing plant",
    copyUsersAndRolesFrom:"this will copy users and roles from",
    createPlantConfirmation:"create plant confirmation",
    to:"to",

    addUserToPlant: "add user to plant",
    roles:"roles",
    email:"e-mail",
    lastName:"last name",
    manageAllRoles:"manage all roles",
    firstName:"first Name",
    selectUser:"select user",
    editRoles:"manage plant access",
    manageUserAccesses:"manage user accesses for",
    removeFromPlant:"remove user from plant",
    manageUseraccess:"manage User Access",
    customer:"customer",

    add: "add",
    user: "user",
    addUser:"add user",

    areYouSureYouWantTo:"are you sure you want to",

    remove:"remove",

    update:"update",

    userHasBeenAdddedTo: "User has been added to",
    errorAddingUserTo: "Error adding user to",


}

const pageNotFound = {
    fourOFour:"404 page not found",
    couldNotFind:"Sorry we could not find the page you were looking for",
    whatCouldhaveCaused404: "What could have caused this to happen?",
    removedPageInQuestion:"We have removed the page in question",
    linkClickedBroken:"The link you clicked may be old/broken",
    typedAddressWrongly:"You have typed the address wrong",
    whatCanYouDoFourOFour:"What you can do?",
    notifyBrokenLink:"Notify us about any broken links",
    retypeUrlFourOFour:"Retype the URL",
    clickButtonBelowFourOFour:"Click the button below which will take you home",
    takeMeHome:"Take me home",
   }
   

const emission = {
    emission:"emission",
}

const alarms ={
    allAlarms:"all alarms",
    activeAlarms:"active alarms",
    inActiveAlarms:"inactive alarms",
    selectAlarmStatus:"select alarm status",

    searchForAlarms:"search for alarms",
    viewAllAlarms:"view all alarms",
    priority:"priority ",
    activeStatus:"active status",
    alarmText: "alarm text",
    status:"status",
    notAcknowledgedAlarm:"not acknowledged",
    acknowledgedBy: "acknowledged by"

}

const events ={
    searchForEvents:"search for events",
    source: "source",
    eventTexts: "text"
}

export const TRANSLATIONS_EN = {
    ...common,
    ...pageNotFound,
    ...alarms,
    ...shipRegistry,
    ...events,
    ...glossary,
    ...userManagement,
    ...operations,
    ...emission,
    ...settings,
    ...finance,
    utilization:"utilization",
    emission:"emission",
    finance:"finance",
    operators:"operators",
    customers:"customers",
    plants:"plants",
    vessel:"vessel",
    viewAll: "View All",
    ipsum:"Let's make a nice big leafy tree. You're the greatest thing that has ever been or ever will be. You're special. You're so very special. You got your heavy coat out yet? It's getting colder. Let's just drop a little Evergreen right here. We'll paint one happy little tree right here. Let's put a touch more of the magic here. Don't be afraid to make these big decisions. Once you start, they sort of just make themselves. We'll put all the little clouds in and let them dance around and have fun. I started painting as a hobby when I was little. I didn't know I had any talent. I believe talent is just a pursued interest. Anybody can do what I do. Everything is happy if you choose to make it that way. No worries. No cares. Just float and wait for the wind to blow you around. It's amazing what you can do with a little love in your heart. We're trying to teach you a technique here and how to use it."
   };