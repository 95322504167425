export enum SignalRHealth {
    RECONNECTED = "RECONNECTED",
    RECONNECTING = "RECONNECTING",
    SLOW_CONNECTION = "SLOW_CONNECTION",
    DISCONNECTED = "DISCONNECTED",
    ERROR = "ERROR"
  }
  
  export enum SignalRConnectionStatus {
    INITIAL = "INITIAL",
    CREATED = "CREATED",
    OFFLINE = "OFFLINE",
    ONLINE = "ONLINE",
    RECONNECTING = "RECONNECTING"
  }