import { useSelector } from "react-redux";

import { AppState } from "../../core";

export const useAlarms = () =>
  useSelector((state: AppState) => state.alarm.alarms);

export const useTotalCountForAllActiveAlarms = () =>
  useSelector((state: AppState) => state.alarm.totalCountAllActiveAlarms);

export const useTotalCountForSearch = () =>
  useSelector((state: AppState) => state.alarm.useTotalCountForSearch);
  
  export const useAlarmsAreLoading = () =>
  useSelector((state: AppState) => state.alarm.loading);

