import { IPlantNew } from '../../plant';
import { IPopupWithDataMerged } from './interfaces';

export const calculateMidpointForMap = (plant: IPlantNew[])=> {
    if(plant?.length === 0) return
    
    let lat = 0;
    let long = 0;
    for (let i = 0; i<plant.length;i++){
      lat += parseFloat(plant[i]["lat"]);
      long += parseFloat(plant[i]["long"]);
    }
    var test= [ long/plant.length, lat/plant.length];
    return test
  }

  export const getPlantsForOperations = ()=>{}


  export const summarizeAlarmsForMergedPlants = (popupDataWithMergedData:IPopupWithDataMerged[]) =>{
      return popupDataWithMergedData.reduce( (initial:number, obj)=>{ return initial+obj["activeAlarms"]
    },0 )
  }