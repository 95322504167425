

import React, { FC, useState } from 'react';

import { t } from 'i18next';
import { makeStyles } from '@mui/styles';
import { Box, Button, CircularProgress, Dialog, DialogActions, Typography } from '@mui/material';

import { IShip } from '../../interfaces';
import { translationWithCapitalization } from '../../../../core';



  interface IDeleteShipFromRegistryDialogProps{
    open:boolean,
    handleClose:Function,
    handleSubmit:Function,
    selectedShip:IShip
  } 

export const DeleteShipFromRegistryDialog = (props:IDeleteShipFromRegistryDialogProps)=>{
    const {open, handleClose, handleSubmit, selectedShip} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState("")
    
    const ARE_YOU_SURE_YOU_WANT_TO = translationWithCapitalization("areYouSureYouWantTo")
    const FROM = t("from");
    const REMOVE = translationWithCapitalization("remove");
    const CANCEL = translationWithCapitalization("cancel");
    const SHIP_REGISTRY = translationWithCapitalization("shipRegistry");

return <> <Dialog
            PaperProps=
            {{sx: {
                borderRadius: "10px",
                padding:"40px 100px 40px 100px",
                display:'flex',
                border: '1px solid #D8DCDD',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                justifyContent:'center'
              }
            }}
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >

  <Typography>
    {ARE_YOU_SURE_YOU_WANT_TO}
  </Typography>
  <br/>
  <Typography sx={{ fontWeight: 'bold' }}>
      {REMOVE} {selectedShip.vesselName} {FROM} {SHIP_REGISTRY}?
  </Typography>
  <Typography sx={{ fontWeight: 'bold' }}>
      {statusMessage}
  </Typography>
          
<DialogActions>
  <Box>


  {isLoading ?  <CircularProgress /> : 
  <div style = {{
    display:"flex",
    justifyContent:"space-between",
    gap:"36px"
  }}>
      <Button 
          onClick={()=>
            {
              handleSubmit(selectedShip);
            }} variant="contained" color = "error" autoFocus 
          sx = {{
              backgroundColor:"#DA0000"
          }}
      >
          {REMOVE}
      </Button>
      <Button onClick={()=>{handleClose()}} variant="outlined" >
          {CANCEL}
      </Button>
      </div>}
  </Box>
  
</DialogActions>


</Dialog> </>;

   
}