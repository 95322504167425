import dotp from "dot-prop-immutable-chain";

import { IEvent, EventActions, EventActionType, LoadingStatus } from ".";

export interface EventState {
  loading: LoadingStatus;
  events:IEvent[],
  searchEvents:[],
  totalCount:number
}

export const initialState: EventState = {
    loading: true,
    events:[],
    searchEvents:[],
    totalCount:0
};

export const eventReducer = (
  state = initialState,
  action: EventActions
) => {
    switch (action.type) {
        case EventActionType.REQUEST_ALL_SUCCESS: {
          return dotp(state)
            .set("events", action.payload)
            .set("loading", LoadingStatus.SUCCESS)
            .value();
        }

        case EventActionType.REQUEST_ALL: {
          return dotp(state)
            .set("loading", LoadingStatus.LOADING)
            .value();
        }

        case EventActionType.REQUEST_ALL_FAILURE: {
          return dotp(state)
            .set("loading", LoadingStatus.FAILURE)
            .value();
        }

        case EventActionType.REQUEST_SEARCH_EVENTS_FOR_PLANTS: {
          return dotp(state)
            .set("loading", LoadingStatus.LOADING)
            .value();
        }

        case EventActionType.REQUEST_SEARCH_EVENTS_FOR_PLANTS_SUCCESS: {
          return dotp(state)
            .set("loading", LoadingStatus.SUCCESS)
            .set("events", action.payload.searchEvents)
            .set("totalCount", action.payload.totalCount)
            .value();
        }

        case EventActionType.REQUEST_SEARCH_EVENTS_FOR_PLANTS_FAILURE: {
          return dotp(state)
            .set("loading", LoadingStatus.FAILURE)
            .value();
        }

        default:
          return state;
      }
};