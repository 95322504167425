import { makeStyles } from '@mui/styles';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Card, CardContent, Icon, Typography } from '@mui/material';

export const AsideCard = (props:any)=>{
  
    const {title, content} = props; 
    return <>
      <Card >
        <CardContent>
          <Typography variant = "h5" color="text.primary">
            {title}
          </Typography>
          {content}
        </CardContent>
      </Card>
    </>
  }
  
  export const AsideCardModal = (props:any, isOpen, cardAction, iconOpen, iconClosed)=>{
  
    const {title, data, preferredInterval="Last month", unit} = props; 
    
    return <>
      <Card >
        <CardContent>
          <Typography variant = "h5" color="text.primary">
            {title}
          </Typography>
          <div >
            <Typography color="text.secondary">
              {preferredInterval}
              <Button
                onClick = {()=>cardAction(isOpen)}
              >
                <Icon>
                  {isOpen?
                   <ExpandMoreIcon />:<ExpandLessIcon />}
                </Icon>
              </Button>
            </Typography>
          </div>
          <Typography  component = "span">
            {data} 
          </Typography>
          <Typography component = "span" color="text.secondary">
            {unit}
          </Typography>
        </CardContent>
      </Card>
    </>
  }
  