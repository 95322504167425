
import { TextField } from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';


interface IFormInputProps {
    name:string,
    label:string,
    required:boolean,
    isNumber?:boolean,
    isDisabled?:boolean
}

export const FormInput = (props:IFormInputProps) => {
    const { control, formState: { errors} } = useFormContext();
    const { name, required, isNumber=false, isDisabled=false } = props;
    
    return (<><Controller
        name={name}
        control={control}
        render={({ field }) => <TextField
            disabled = {isDisabled}
            {...field}
            InputLabelProps={{
                required: required || false,
            }}
            defaultValue=""
            type={isNumber? "number": "text" }
            size="small"
            error = {!isNil(errors[name]?.message)}
            margin="dense"
            helperText={!isNil(errors[name]?.message)&&errors[name]?.message}
            id={name}
            fullWidth
            variant="outlined"
            {...props}
        />}
    />
    </>
    )
}