import React, { forwardRef, useRef, useState } from 'react';

import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Checkbox, Icon, IconButton, List, ListItem, Popover, TableSortLabel, Toolbar, Tooltip, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useGlobalFilter, useRowSelect, useSortBy, useTable } from 'react-table';

import { translationWithCapitalization } from '..';

const now = dayjs();

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest}, ref) => {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <Checkbox ref={resolvedRef} {...rest} />
      </>
    )
  }
)

IndeterminateCheckbox.displayName="";

export const TableWithModal = ({ columns, data, title }, props:any) => {
  const { timeFrame} = props;

  return (
    <TableContainer>
      <EnhancedTable columns ={columns} data={data} title = {title} />
    </TableContainer>
  )
}

export const EnhancedTable = ({ columns, data, timeFrame, title } )=>{

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    selectedFlatRows,
    setGlobalFilter,
    state: { 
    selectedRowIds,
    globalFilter }
  } = useTable({
    columns,
    data,
  },
  useGlobalFilter,
  useSortBy,
  useRowSelect,
  hooks => {

    hooks.allColumns.push(columns => [
      {
        id: 'checked',
        Cell: ({row})=>(
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        ),
        Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
        )
      },
      ...columns,
    ])
  },
  )

    const selectedRowsFormated:any[] = []

    selectedFlatRows.forEach(s => selectedRowsFormated.push(s.original))

  return(
    <>
    <TableToolbar 
      preGlobalFilteredRows = {preGlobalFilteredRows}
      numSelected={Object.keys(selectedRowIds).length}
      globalFilter = {globalFilter}
      setGlobalFilter = {setGlobalFilter}
      numberOfSelected = {Object.keys(selectedRowIds).length} 
      title = {title} timeFrame = {timeFrame} 
      columns = {columns}
      selectedRows = {selectedRowsFormated}
      />
    <TableContainer
     style={{
        maxHeight: 620,
      }}
    >
      
    <Table {...getTableProps()}
      stickyHeader={false}
    >
        <TableHead>
          {headerGroups.map( (headerGroup, j) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={j}>
              {headerGroup.headers.map( (column, i) => (
                <TableCell
                key = {i}
                sx = {{
                  letterSpacing: "0.15em",
                  fontSize:"16px",
                  color:'#C4C4C4',
                  padding:'0px'
                }}
                
                    {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}
                  <TableSortLabel
                      active={column.isSorted}
                      direction={column.isSortedDesc ? 'desc' : 'asc'}
                    />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, k) => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()} key ={k}>
                {row.cells.map( (cell, j) => {
                  return <TableCell 
                  key = {j}
                  sx = {{
                    letterSpacing: "0.15em",
                    fontSize:"16px",
                    padding:'5px',

                  }}
                  {...cell.getCellProps()}>{cell.render('Cell')}

                  </TableCell>
                })}
              </TableRow>
            )
          })}
          
        </TableBody>
      </Table>
    </TableContainer>
    </>
  )
}

const TableToolbar = (props:any)=>{
  const {numberOfSelected, title, preGlobalFilteredRows, setGlobalFilter, globalFilter, timeFrame, selectedRows, columns} = props;

  const selected = translationWithCapitalization("selected");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event:any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return(<Toolbar
 >
    {numberOfSelected > 0 ? ( <>
        <Typography
          sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}

          variant="h5" component="h2"
        >
         {numberOfSelected} {selected}
         
        <IconButton aria-describedby={id} variant="contained" onClick={handleClick}>
          <MoreVertIcon/>
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <List>
            <ListItem>
              <Tooltip title="Export as CSV">
                <ExportSelectedAsCsvButton selectedRows = {selectedRows}/>
              </Tooltip>
            </ListItem>
            <ListItem>
              <Tooltip title="Export as CSV">
                <ViewSelectedAsPDFButton title = {title} selectedRows = {selectedRows} columns = {columns}/>
              </Tooltip>
            </ListItem>
          </List>
        </Popover>
        </Typography>
        
        </>
      ) : (<>
        <Typography id="modal-modal-title" variant="h5" component="h2" 
          sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
        >
            {title}
         </Typography>
        
      <Typography  sx = {{fontWeight:'light'}} color="text.secondary" component="h2">
        {timeFrame} 
      </Typography>
      
      </>
      )}

  </Toolbar>)
}


const ExportSelectedAsCsvButton = (props:any)=>{
  const {selectedRows} = props;

  const csv = convertJsonToCSV(selectedRows);

  return(<>
    <IconButton
      download = {`${now}-marked-rows.csv`}
      href={`data:text/csv;charset=utf-8,${encodeURIComponent(
        csv
        )}`}
    >
      <Icon>
        <DownloadOutlinedIcon/>
      </Icon>
    </IconButton>
  </>)
}

const ViewSelectedAsPDFButton = (props:any) =>{
  const { title , selectedRows, columns } = props;

  const history = useHistory();

  if(!isNil(selectedRows)){
    return(<>
      <IconButton
        onClick={ ()=> history.push('/pdf',selectedRows) }
      >
        <Icon>
            <PictureAsPdfIcon/>
          </Icon>
      </IconButton>
    </>)
  }
}

const convertJsonToCSV = (jsonList:any)=>{

  const header = Object.keys(jsonList[0]);
  const replacer = (key:any, value) => value === 'null' ? '': value;
  
  const csv = [
    header.join(','), // header row first
    ...jsonList.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
  ].join('\r\n');

  return csv;

}