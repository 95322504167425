import React, { useEffect, useMemo, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
  Box, CircularProgress, Grid, TextField, Typography
} from "@mui/material";
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ReactSelect from 'react-select';

import { isNil } from 'lodash';
import { AlarmOptions, IAlarmSearchRequest, RequestSearchAlarmHistoryForPlants } from '.';
import { routers } from '../../constants/constants';
import { translationWithCapitalization, translationWithUppercase } from '../../core';
import { useCurrentPlant } from '../../settings/hook';
import { useUser, useUserHasLoaded } from '../../user/hooks';
import { LoadingStatus as EventLoadingStatus, IEventSearchRequest, RequestSearchForEventsForPlants, useEvents, useEventsAreLoading as useEventsLoadingStatus, useEventsTotalCount } from './events';
import { useAlarms, useAlarmsAreLoading, useTotalCountForSearch } from './hooks';
import { TableWithPagination } from './table/components';

const useStyles = makeStyles((theme) => ({
  loadingComponent:{
    width:"100%",
      alignItems: "center",
      display: "flex",
      flexWrap:"wrap",
      flexDirection:"column",
      height: "100%",
      justifyContent: "center"
  },
})
,
{ index: 1 }
);

export const LoadingComponent = () => {
  const {t} = useTranslation()
  const LOADING_PLEASE_WAIT = t("loadingPleaseWait")
  const classes = useStyles();
    return (
      <div className={classes.loadingComponent}>
          <CircularProgress />
          {LOADING_PLEASE_WAIT}...
      </div>
    )
}

export const AlarmsPage = ()=>{
  const location = useLocation();
  const [tabValue, setTabValue] = useState(location.pathname.includes(routers.ALARM_PAGE)?0:1);
  const { t } = useTranslation();
  const ALARMS = t('alarms');
  const EVENTS = t('events');
  const history = useHistory();

    useEffect( ()=>{
      setTabValue(location.pathname.includes(routers.ALARM_PAGE)?0:1)
    },[location] )
    
    const handleChange = (event, newValue)=>{
        setTabValue(newValue);
    }

      return (  
      <Grid container justifyContent={"space-between"} >
        <Grid  
          item
          container 
          xs={12} 
          // direction="row"
          // justify="flex-start"
          // alignItems="flex-start"
          >

          <Grid item xs = {12}  component = {Paper} >
            <Tabs
              value={tabValue}
              onChange={handleChange}
            >
              <Tab onClick = {()=>history.push(routers.ALARM_PAGE)} label={ALARMS} color = "primary"/>          
              <Tab onClick = {()=>history.push(routers.EVENTS_PAGE)} label={EVENTS} color = "primary"/>
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <AlarmsView/>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <EventsView/>
            </TabPanel>
          </Grid>
           
        </Grid>
    </Grid>    
      )
  }
  
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
    );
  }

  const AlarmsView = ()=>{
      const [pageNumber, setPageNumber] = useState(0);
      const [queryString, setQueryString] = useState("");
      const dispatch = useDispatch();
      const alarms = useAlarms();
      const [selectedOption, setSelectedOption] = useState(AlarmOptions.All);
      const totalCountForSearch = useTotalCountForSearch();
      const userIsLoading = useUserHasLoaded();
      const alarmsAreLoading = useAlarmsAreLoading();
      const currentPlant = useCurrentPlant();
      const plants = useUser().plantsForOperationsRole;
      const { t } = useTranslation();

      const ALL_ALARMS = translationWithUppercase("allAlarms"); 
      const ACTIVE_ALARMS = translationWithUppercase("activeAlarms"); 
      const INACTIVE_ALARMS = translationWithUppercase("inactiveAlarms"); 

      const TIME = translationWithCapitalization("time") 
      const PLANT = translationWithCapitalization("plant")
      const ACTIVE_STATUS = translationWithCapitalization("activeStatus")
      const ALARM_TEXT = translationWithCapitalization("alarmText")
      const PRIORITY = translationWithCapitalization("priority")
      const STATUS = translationWithCapitalization("status")
      const SELECT_ALARM_STATUS = translationWithUppercase("selectAlarmStatus")
      const SEARCH_FOR_ALARMS = translationWithCapitalization("searchForAlarms")
      const SEARCH = translationWithCapitalization("search")
      const FILTER_BY = translationWithCapitalization("filterBy")
      const NO_TEXT = translationWithCapitalization("noText");
      const ACKNOWLEDGED_BY = translationWithCapitalization("acknowledgedBy");
      const NOT_ACKNOWLEDGED_ALARM = translationWithCapitalization("notAcknowledgedAlarm");

      const handleSubmit = (filteredAlarmStatus:AlarmOptions)=>{
        let plantsToString
        if(currentPlant.plantName !== 'All') {
          plantsToString =  plants
            .filter(p=>p.shortName === currentPlant.plantName)
            .map( (item)=>{
              return item['shortName'];
            });
        } else {
          plantsToString =  plants
            .map( (item)=>{
              return item['shortName'];
            });
        }

        var payload:IAlarmSearchRequest = {
          "plants": [...plantsToString],
          "searchText": queryString.toString(),
          "locale": "ENG",
          "fetchTotalCount": true,
          "pagination": {
            "pageNumber": 0,
            "pageSize": 100
          },
          isActive: filteredAlarmStatus
        }

          if(plants.length>0){
            dispatch(new RequestSearchAlarmHistoryForPlants(payload));
          }
      }

      const handleSearch = ()=>{
        handleSubmit(selectedOption)
      }

      const handleFilter = (event:any)=>{
        setSelectedOption(event.value)
        handleSubmit(event.value)
      }


      useEffect(() => {
        let plantsToString
        if(currentPlant.plantName !== 'All') {
          plantsToString =  plants
            .filter(p=>p.shortName === currentPlant.plantName)
            .map( (item)=>{
              return item['shortName'];
            });
        } else {
          plantsToString =  plants
            .map( (item)=>{
              return item['shortName'];
            });
        }

            var payload:IAlarmSearchRequest = {
              "plants": [...plantsToString],
              "searchText": queryString.toString(),
              "locale": "ENG",
              "fetchTotalCount": true,
              "pagination": {
                "pageNumber": pageNumber,
                "pageSize": 100
              },
              isActive: selectedOption
            }


          if(plants.length>0){
            dispatch(new RequestSearchAlarmHistoryForPlants(payload));
          }

      }, [userIsLoading, currentPlant, pageNumber, plants]);

      const SearchForAlarmsOptions = [
        { value: AlarmOptions.All, label: ALL_ALARMS},
        { value: AlarmOptions.ActiveAlarms, label: ACTIVE_ALARMS},
        { value: AlarmOptions.NotActiveAlarms, label: INACTIVE_ALARMS},
    ]
    
      const alarmColumns = useMemo(
        ()=> [
          {
            Header: () => null,
            id: "acknowledgedIcon",
          },
          {
            Header: () => PLANT,
            accessor:"plant",
            id: "plantName",
            Cell: ({row, value})=> {
              return <span style = {{fontSize:"14px"}}>{value.toUpperCase()} </span>
            }
          },
          { 
            Header: () => STATUS,
            accessor: 'lastEventType',
            id:"lastEventType",
            Cell: ({row, value})=> <span style = {{fontSize:"14px"}}>{value}</span>
          },
          { 
            Header: () => TIME,
            accessor: 'lastEventTime',
            Cell: ({row, value})=> <span style = {{fontSize:"14px", fontWeight:'lighter'}}>{dayjs(value).format('DD/MM/YYYY') } - {dayjs(value).format('HH:mm')}</span>
          },
          { 
            Header: () => ACTIVE_STATUS,
            accessor: 'isActive',
            Cell: ({row, value})=> <span style = {{fontSize:"14px"}}>{value?"active":"Inactive"}</span>
          },
          { 
            Header: () => ACKNOWLEDGED_BY,
            accessor: 'acknowledgedBy',
            id:"acknowledgedBy",
            Cell: ({row, value})=> {
            var acknowledgedByText = !isNil(value)?value:NOT_ACKNOWLEDGED_ALARM
            return <span style = {{fontSize:"14px"}}>{acknowledgedByText}</span>}
          },
          { 
            Header: () => ALARM_TEXT,
            accessor: 'texts',
            Cell: ({row, value})=> {
              const alarmText =  value.length>0? value[1]["content"]:NO_TEXT
              return <span style = {{fontSize:"14px"}}>{alarmText}</span>}
          },
          { 
            Header: () => PRIORITY,
            accessor: 'alarmPriority',
            Cell: ({row, value})=> {
              var value = value||"-";
              return <span style = {{fontSize:"14px"}}>{value.toUpperCase()}</span>}
          },
          
        ],[]
      )
    
          return (alarmsAreLoading?
            <LoadingComponent/>:
            <>
              <Grid 
                container
                sx = {{
                  marginBottom:"120px"
                }}
                spacing={3}
                >
                <Grid xs = {11} item>
                  <TextField
                    id="input-with-icon-adornment"
                    value={queryString}
                    label = {`${SEARCH_FOR_ALARMS}...`}  
                    onChange={ (event)=>{setQueryString(event.target.value)} }
                    fullWidth
                    onKeyPress={(event) => {
                      event.defaultPrevented = true;
                      if (event.key === 'Enter'){
                        handleSearch();      
                   }}}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                    ),
                  }}
                  variant="standard"
                />
                </Grid>
                <Grid
                item
                xs = {1}
              >
                <Button 
                  onClick = {()=> handleSearch()} 
                  variant="outlined">{SEARCH}
                </Button>
              </Grid>
              <Grid
                item
                xs = {1}
              >
                {FILTER_BY}
              </Grid>
                <Grid
                  item
                  xs = {3}
              >
                <ReactSelect
                  defaultValue={selectedOption}
                  onChange={ (event)=> {
                    handleFilter(event)
                  }
                  }
                  placeholder = {SearchForAlarmsOptions[selectedOption]["label"]} //`${SELECT_ALARM_STATUS}...`}
                  options ={
                    ...SearchForAlarmsOptions
                  }
                />
              </Grid>
              
            </Grid>
              <TableWithPagination
                expandRows = {false}
                handleSwitchPage={setPageNumber}
                totalCount = {totalCountForSearch}
                pageNumber = {pageNumber}
                showHeader={true}
                columns = {alarmColumns}
                renderRowSubComponent = {undefined}
                data = {alarms}/>
            </>
           )
  }

  const EventsView = ()=>{
    const [pageNumber, setPageNumber] = useState(0);
    const dispatch = useDispatch();
    const [queryString, setQueryString] = useState("");
    const totalEventsCount = useEventsTotalCount();

    const currentPlant = useCurrentPlant();
    const plants = useUser().plantsForOperationsRole;
    const eventsAreLoadingStatus = useEventsLoadingStatus();
    const events = useEvents();

    const {t} = useTranslation();
    const TIME = translationWithCapitalization("time") 
    const PLANT = translationWithCapitalization("plant")
    const TYPE = translationWithCapitalization("type")
    const SOURCE = translationWithCapitalization("source")
    const TEXT = translationWithCapitalization("eventTexts");
    const NO_TEXT = translationWithCapitalization("noText");
    const SEARCH = t("search")
    const SEARCH_FOR_EVENTS = t("searchForEvents")
    
    useEffect( ()=>{
      let plantsToString
      if(currentPlant.plantName !== 'All') {
        plantsToString =  plants
          .filter(p=>p.shortName === currentPlant.plantName)
          .map( (item)=>{
            return item['shortName'];
          });
      } else {
        plantsToString =  plants
          .map( (item)=>{
            return item['shortName'];
          });
      }

      const payload:IEventSearchRequest = {
          "plants": 
            plantsToString,
          "searchText": queryString,
          "locale": "ENG",
          "fetchTotalCount": true,
          "pagination": {
            "pageNumber": pageNumber,
            "pageSize": 100
          }
      }
      if (plants.length>0){
        dispatch(new RequestSearchForEventsForPlants(payload));
      }
    },[currentPlant, plants, pageNumber] )

    const handleSubmit = () => {
      let plantsToString
      if(currentPlant.plantName !== 'All') {
        plantsToString =  plants
          .filter(p=>p.shortName === currentPlant.plantName)
          .map( (item)=>{
            return item['shortName'];
          });
      } else {
        plantsToString =  plants
          .map( (item)=>{
            return item['shortName'];
          });
      }

      const payload:IEventSearchRequest = {
          "plants": 
            plantsToString
          ,
          "searchText": queryString.toString(),
          "locale": "ENG",
          "fetchTotalCount": true,
          "pagination": {
            "pageNumber": 0,
            "pageSize": 100
          }
      }
      if(plants.length>0){
        dispatch(new RequestSearchForEventsForPlants(payload));
      }
    }

    const eventColumns = useMemo(
      ()=> [
        {
          Header: () => null,
          id: "acknowledgedIcon",
        },
        {
          Header: () => PLANT,
          accessor:"plant",
          id: "plantName",
          Cell: ({row, value})=> {
            return <span style = {{fontSize:"14px"}}>{value} </span>
          }
        },
        { 
          Header: () => TIME,
          accessor: 'time',
          Cell: ({row, value})=> <span style = {{fontSize:"14px", fontWeight:'lighter'}}>{dayjs(value).format('DD/MM/YYYY') } - {dayjs(value).format('HH:mm')}</span>
        },

        { 
          Header: () => TYPE,
          accessor: 'type',
          Cell: ({row, value})=> <span style = {{fontSize:"14px"}}>{value}</span>
        },
        
        { 
          Header: () => SOURCE,
          accessor: 'source',
          Cell: ({row, value})=> <span style = {{fontSize:"14px", fontWeight:'lighter'}}>{value}</span>
        },
        { 
          Header: () => TEXT,
          accessor: 'texts',
          Cell: ({row, value})=> {

          const text =  !isNil(value)? value["ENG"]:NO_TEXT
          return <span style = {{fontSize:"14px"}}>{text}</span>}
        
        }
        
      ],[]
    )
    
    if(eventsAreLoadingStatus === EventLoadingStatus.LOADING){
      return <LoadingComponent/>
    }

    else if (eventsAreLoadingStatus === EventLoadingStatus.SUCCESS){

      return <>
      <Grid container>
            <Grid xs = {8} item>
              <TextField
                id="input-with-icon-adornment"
                onKeyPress={(event) => {
                  event.defaultPrevented = true;
                  if (event.key === 'Enter'){
                    handleSubmit();      
              }}}
                value={queryString}
                label = {`${SEARCH_FOR_EVENTS}...`}
                onChange={ (event)=>{setQueryString(event.target.value)} }
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                ),
              }}
              variant="standard"
            />
            </Grid>
          <Grid
            item
            xs = {3}
          >
            <Button 
              variant="outlined" onClick = { ()=>handleSubmit() }
              
              >
                {SEARCH}
            </Button>
          </Grid>
        </Grid>
        <TableWithPagination
            expandRows = {false}
            pageNumber = {pageNumber}
            columns = {eventColumns}
            handleSwitchPage={setPageNumber}
            renderRowSubComponent = {undefined}
            totalCount = {totalEventsCount}
            data = {events}/>
        </>
      }
      else if (eventsAreLoadingStatus === EventLoadingStatus.FAILURE){
      return <div>Failed to load events</div>
    }
    else if (eventsAreLoadingStatus === EventLoadingStatus.NOT_STARTED){
      return <div>Starting</div>
    }
    else {
      return <div>Starting</div>
    }
  }