import React, { useMemo } from "react";

import ComputerIcon from '@mui/icons-material/Computer';
import { Box, CircularProgress, Grid, Icon, IconButton, Typography } from '@mui/material';

import { useSelector } from "react-redux";
import { AppState } from "../../../core";
import { useHMIMonitoringURL } from "../../../plant";
import { useUserHasLoaded } from "../../../user/hooks";
import { ExpandableTable } from "../../Components/tables";

/**
 *  List of plants for list view
 *  next to map
 */
export const PlantListView = ()=>{
    const hmiUrl = useHMIMonitoringURL()
    const plants = useSelector((state: AppState) => state.user.plantsForOperationsRole);
    const userIsLoading = useUserHasLoaded();
    // const customerColumns = useMemo(
    //     ()=> [
    //       {
    //         Header: () => null,
    //         id: "name",
    //         accessor: 'name',
    //         Cell: ({ row, value }) => (
    //             <Box 
    //               alignItems="center" 
    //               display="flex" 
    //               {...row.getToggleRowExpandedProps()}>
    //               <IconButton
    //                 size="small"
    //             >
    //                {row.isExpanded ? (
    //                 <Icon>
    //                     <img height = {'60%'} src = {expandIcon}/>
    //                 </Icon>
    //                 ) : (
    //                   <Icon>
    //                     <img height = {'60%'} src = {closeIcon}/>
    //                 </Icon>
    //                 )}
    //                 </IconButton>
    //                   <Typography
    //                     sx={{
    //                       fontWeight: 700,
    //                       fontSize: '21.3838px',
    //                       letterSpacing: '0.1em'
    //                     }}>
    //                       <span>{value.toUpperCase()}</span>
    //                   </Typography>
    //               </Box>
    //         )
    //       },
    //       {
    //         Header: () => null,
    //         id: "plants",
    //         accessor: 'plants'
    //       }
          
    //     ],[]
    //   )

      const plantColumns = useMemo(
        ()=> [
          
          {
            Header: () => null,
            id: "name",
            accessor: 'shortName',
            Cell: ({ row, value }) => {
                return <Box 
                  alignItems="center" 
                  display="flex" >
                  <IconButton
                    size="small"
                    href={`${hmiUrl}/${value}/HMI`}
                >
                    <Icon >
                        <ComputerIcon/>
                    </Icon>
                    </IconButton>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: '21.3838px',
                          letterSpacing: '0.1em'
                        }}>
                          {value}
                      </Typography>
                  </Box>
            }
          }
          
        ],[]  
        )

      // const plantTable = useCallback(
      //   ({ row }) => (
      //      <ExpandableTable
      //       data={plants.filter(p => p.customerId === row.original.id)}
      //       columns={plantColumns}
      //       tableColor={"#F6FAFB"}
      //       showSearchFilter={false}
      //       renderRowSubComponent={undefined} 
      //       showPopupComponent={undefined} 
      //       popupComponent={undefined}              />
      //     ),
      //   [plantColumns, plants]
      // );


    return userIsLoading?<Grid 
    sx={{
      display: 'flex', 
      justifyContent: 'center'
      }} >
    <CircularProgress/>
  </Grid>:<div>
           <ExpandableTable
            data={plants}
            columns={plantColumns}
            tableColor={"#F6FAFB"}
            showSearchFilter={false}
            renderRowSubComponent={undefined} 
            showPopupComponent={undefined} 
            popupComponent={undefined}              />
    </div>
  

}
