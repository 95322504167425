import React from 'react';

import { CircularProgress, Grid, Paper, Typography } from '@mui/material';

import { isNil } from 'lodash';
import { translationWithCapitalization } from '../../core';
import { useApiUrl } from '../../settings/hook';
import { useUser } from '../../user/hooks';
import { TableProp } from './components/tableProp';
import { useGetInvoices } from './hooks/useInvoicesForPlants';

export const FinancePage = () => {
  const plants = useUser().plants;
  const apiUrl = useApiUrl();
  const NO_PLANT_SELECTED = translationWithCapitalization('noPlantSelected');

  const { invoices, error, loading: invoicesAreLoading } = useGetInvoices(apiUrl, plants);

  return invoicesAreLoading ? (
    <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </Grid>
  ) : (
    <Grid container justifyContent={'space-between'}>
      <Grid item container xs={12} md={12} direction="row" alignItems="flex-start">
        <Grid
          item
          xs={12}
          component={Paper}
          sx={{
            padding: '50px',
            overflow: 'auto',
            height: '50vw',
          }}
        >
          {invoices.length === 0 && <Typography sx={{ fontStyle: 'italic' }}>{NO_PLANT_SELECTED}</Typography>}
          {invoices.length > 0 && !isNil(invoices) && <TableProp data={invoices} />}
        </Grid>
      </Grid>
    </Grid>
  );
};
