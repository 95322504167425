import namor from 'namor'

export const range = len => {
    const arr = []
    for (let i = 0; i < len; i++) {
      arr.push(i)
    }
    return arr
  }
  
 export const newPerson = () => {
    const totalConsumed = Math.random()*69420;
    return {
      vessel: Math.floor(Math.random() * 10000000) ,//namor.generate({ words: 1, numbers: 0 }),
      sessionStart: namor.generate({ words: 1, numbers: 0 }),
      sessionEnd: Math.floor(Math.random() * 30),
      duration: Math.floor(Math.random() * 100),
      consumedPower: Math.floor(Math.random() * 100),
      total:totalConsumed.toFixed(2),
    }
  }
  
  export function makeData(...lens) {
    const makeDataLevel = (depth = 0) => {
      const len = lens[depth]
      return range(len).map(d => {
        return {
          ...newPerson(),
          subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
        }
      })
    }
  
    return makeDataLevel()
  }
  