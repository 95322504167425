import { Action } from "redux";
import { IFailureAction, ISuccessAction } from "../../core";
import { IAlarm } from "./";

export enum AlarmActionType {
  REQUEST_ALL = "[Alarm] Request All",
  REQUEST_ALL_SUCCESS = "[Alarm][Success] Request All",
  REQUEST_ALL_FAILURE = "[Alarm][Failure] Request All",

  REQUEST_ACTIVE_ALARM_COUNT_FOR_ALL_PLANTS = "[Alarm] Request Active Alarm Count For All Plants",
  REQUEST_ACTIVE_ALARM_COUNT_FOR_ALL_PLANTS_SUCCESS = "[Alarm][Success] Request Active Alarm Count For All Plants",
  REQUEST_ACTIVE_ALARM_COUNT_FOR_ALL_PLANTS_FAILURE = "[Alarm][Failure] Request Active Alarm Count For All Plants",

  REQUEST_SEARCH_ALARM_HISTORY_FOR_PLANTS = "[Alarm] Request Search Alarm History For Plants",
  REQUEST_SEARCH_ALARM_HISTORY_FOR_PLANTS_SUCCESS = "[Alarm][Success] Request Search Alarm History For Plants Success",
  REQUEST_SEARCH_ALARM_HISTORY_FOR_PLANTS_FAILURE = "[Alarm][Failure] Request Search Alarm History For Plants Failure",

  REQUEST_SEARCH_ACTIVE_ALARM_HISTORY_FOR_PLANTS = "[Alarm] Request Search Alarm History For Plants",
  REQUEST_SEARCH_ACTIVE_ALARM_HISTORY_FOR_PLANTS_SUCCESS = "[Alarm][Success] Request Search Active Alarm History For Plants Success",
  REQUEST_SEARCH_ACTIVE_ALARM_HISTORY_FOR_PLANTS_FAILURE = "[Alarm][Failure] Request Search Active Alarm History For Plants Failure"

}

// REQUEST ALL ALARMS FROM HISTORY

export class AlarmRequestAll implements Action {
  readonly type = AlarmActionType.REQUEST_ALL;

  constructor(public payload: any) {}
}

export class AlarmRequestAllSuccess implements Action, ISuccessAction {
  readonly type = AlarmActionType.REQUEST_ALL_SUCCESS;
  readonly success = true;
  constructor(public payload: IAlarm) {}
}

export class AlarmRequestAllFailure implements Action, IFailureAction {
  readonly type = AlarmActionType.REQUEST_ALL_FAILURE;
  readonly failure = true;
  constructor(public payload: any) {}
}

// COUNT FOR ALL ACTIVE ALARMS

export class RequestActiveAlarmCountForAllPlants implements Action {
  readonly type = AlarmActionType.REQUEST_ACTIVE_ALARM_COUNT_FOR_ALL_PLANTS;

  constructor(public payload: any) {}
}

export class RequestActiveAlarmCountForAllPlantsSuccess implements Action, ISuccessAction {
  readonly type = AlarmActionType.REQUEST_ACTIVE_ALARM_COUNT_FOR_ALL_PLANTS_SUCCESS;
  readonly success = true;
  constructor(public payload: IAlarm) {}
}

export class RequestActiveAlarmCountForAllPlantsFailure implements Action, IFailureAction {
  readonly type = AlarmActionType.REQUEST_ACTIVE_ALARM_COUNT_FOR_ALL_PLANTS_FAILURE;
  readonly failure = true;
  constructor(public payload: any) {}
}

// SEARCH ALARM HISTORY

export class RequestSearchAlarmHistoryForPlants implements Action {
  readonly type = AlarmActionType.REQUEST_SEARCH_ALARM_HISTORY_FOR_PLANTS;

  constructor(public payload: any) {}
}

export class RequestSearchAlarmHistoryForPlantsSuccess implements Action, ISuccessAction {
  readonly type = AlarmActionType.REQUEST_SEARCH_ALARM_HISTORY_FOR_PLANTS_SUCCESS;
  readonly success = true;
  constructor(public payload: IAlarm) {}
}

export class RequestSearchAlarmHistoryForPlantsFailure implements Action, IFailureAction {
  readonly type = AlarmActionType.REQUEST_SEARCH_ALARM_HISTORY_FOR_PLANTS_FAILURE;
  readonly failure = true;
  constructor(public payload: any) {}
}

// SEARCH ALARM HISTORY FOR ACTIVE ALARMS

export class RequestSearchActiveAlarmHistoryForPlants implements Action {
  readonly type = AlarmActionType.REQUEST_SEARCH_ACTIVE_ALARM_HISTORY_FOR_PLANTS;

  constructor(public payload: any) {}
}

export class RequestSearchActiveAlarmHistoryForPlantsSuccess implements Action, ISuccessAction {
  readonly type = AlarmActionType.REQUEST_SEARCH_ACTIVE_ALARM_HISTORY_FOR_PLANTS_SUCCESS;
  readonly success = true;
  constructor(public payload: IAlarm) {}
}

export class RequestSearchActiveAlarmHistoryForPlantsFailure implements Action, IFailureAction {
  readonly type = AlarmActionType.REQUEST_SEARCH_ACTIVE_ALARM_HISTORY_FOR_PLANTS_FAILURE;
  readonly failure = true;
  constructor(public payload: any) {}
}


export type AlarmActions =
  | AlarmRequestAll
  | AlarmRequestAllSuccess
  | AlarmRequestAllFailure
  | RequestActiveAlarmCountForAllPlants
  | RequestActiveAlarmCountForAllPlantsSuccess
  | RequestActiveAlarmCountForAllPlantsFailure
  | RequestSearchAlarmHistoryForPlants
  | RequestSearchAlarmHistoryForPlantsSuccess
  | RequestSearchAlarmHistoryForPlantsFailure
  | RequestSearchActiveAlarmHistoryForPlants
  | RequestSearchActiveAlarmHistoryForPlantsSuccess
  | RequestSearchActiveAlarmHistoryForPlantsFailure

