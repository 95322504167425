import { SignalRActions, SignalRActionType } from "./actions";
import { SignalRConnectionStatus } from "./interfaces";
import dotp from "dot-prop-immutable-chain";


export interface SignalRState {
    url: string;
    loading: string;
    health: string;
    connectionStatus: SignalRConnectionStatus;
    connectionRetries: number;
  }
  
  export const initialState: SignalRState = {
    url: "i",
    loading: "",
    health: "",
    connectionStatus: SignalRConnectionStatus.INITIAL,
    connectionRetries: 0
  };


  export const signalRReducer = (
    state = initialState,
    action: SignalRActions
  ) => {

    switch (action.type) {

      case SignalRActionType.SET_CONNECTION_STATUS: {
        return {...state, connectionStatus: 
          action.payload
        } 
      }

      case SignalRActionType.SET_CONNECTION_RETRIES: {
        return {...state, connectionRetries: 
          state.connectionRetries+1
        } }
      
      default:
        return state;
    }
  };
  
