import React, { useCallback, useState, useMemo, useEffect } from 'react';

import axios from 'axios';
import { isNil } from 'lodash';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, TableBody, TableContainer, TableHead, TableCell, TableRow, Typography, Table, CircularProgress } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import Add from '@mui/icons-material/Add';
import Icon from '@mui/material/Icon';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import verticaldot from '../../../../assets/icons/verticaldot.svg'
import { translationWithCapitalization, translationWithUppercase } from '../../../core';
import { useApiUrl } from '../../../settings/hook';
import { IPlantUserDTO } from '../interface';
import { ExpandableTable } from '../../Components/tables';
import { DeleteUserDialog } from '../table/dialog/deleteUserDialog';
import { AddUserToPlantDialog } from '../table/dialog/addUserToPlantDialog';
import { UserAccessDialog } from '../table/dialog/userAccessDialog';


interface IPlantFromDTO {
    name:string,
    id:string,
    shortName:string
}

export const PlantUsersTableWrapper = (props:any ) => {

    const {showHeader, headerGroups, headerColor, renderRowSubComponent, rows, data} = props;


return (<TableContainer
     style={{
        maxHeight: 620,

      }}
    >
        {data.length>0  &&data.map( (plantFromDTO:IPlantFromDTO, k) => <PlantUserTableComponent plantFromDTO = {plantFromDTO} key = {k}/>
          )
        }
    </TableContainer>)
}


const PlantUserTableComponent = (props:any)=>{

  const {plantFromDTO} = props;

  const [expandRows, setExpandRows] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const handleAddUserToPlantClick = ()=>{
      setRefresh(!refresh);
  }

  const FIRST_NAME = translationWithCapitalization("firstName");
  const LAST_NAME = translationWithCapitalization("lastName");
  const EMAIL = translationWithCapitalization("email");
  const NO_DATA = translationWithUppercase("noData")
  const ROLES = translationWithCapitalization("roles")


  //PlantuserColumns
  // TODO: Move out
  function SubRowAsync({ plantFromDTO }) {

      const [loading, setLoading] = useState(true);
      const [plantUsers, setPlantUsers] = useState([]);
      const apiURL = useApiUrl();


      useEffect( () => {

        setLoading(true)

          axios
          .get(`${apiURL}/Access/${plantFromDTO.id}/usersWithAccesses`)
          .then((response) => {
            setPlantUsers(response.data)
            setLoading(false)
         })
         .catch(e=> console.log(e));
  
      }, [apiURL, refresh]);
  
  
      // CRUD-functions
  
      const handleEditUserClick = ( (selectedUser:IPlantUserDTO, selectedRoles:[])=>{
  
        const URL = apiURL + `/Access/${selectedUser.id}/upsertPlantAccess`;
  
        setLoading(true)
        if(!isNil(selectedUser)&&selectedRoles.length>0){
          axios
            .post(URL, {'plantId':selectedUser.plantRoles.plant.id, 'roleIds':selectedRoles})
            .then( (response) => {
              setPlantUsers(response.data)
              setLoading(false)
          })
            .catch( (error) => {
              console.log(error)
              setLoading(false)
      
            });
          }
      } )
  
      const handleRemoveUserClick = ( (selectedUser:IPlantUserDTO)=>{
  
          const URL = apiURL + `/Access/${selectedUser.id}/revokeAccess/${selectedUser.plantRoles.plant.id}`;
            
          setLoading(true)
          axios
          .delete(URL, {})
          .then( (response) => {
            // setStatusMessage(`User with email: ${selectedUser.email} has been removed from plant ${plantName}`)
            setPlantUsers(response.data)
            setLoading(false)
            
          })
          .catch( (error) => {
            console.log(error);
            // setStatusMessage("Error deleting user")
            setLoading(false)
          })
      } )
  
      const userRoleColumns = useMemo(
        ()=> [
          {
            id:"manageRoles",
            Cell: ({ value, row }) => (
            <Box 
              sx={{
                display: 'flex', 
                flexDirection: 'row-reverse' 
              }}
            >
               <ManageUserMenu
                  selectedUser={row.original}
                  onEditPlantUserClicked={handleEditUserClick}
                  onRemovePlantUserClicked={handleRemoveUserClick}
               />
            </Box> 
            )
          },
          {
            Header:  (c) => <span
              style= {{fontWeight:"400", fontSize:"16px"}}
            > 
              {FIRST_NAME} <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => c.column.toggleSortBy(!c.column.isSortedDesc)}
                >
                    { c.column.isSorted? <KeyboardArrowDownIcon 
                      fontSize="medium"
                    /> : <KeyboardArrowUpIcon 
                    fontSize="medium"
                  /> }
                </IconButton> </span>,
                Cell: ({ value, row }) => { 
                return <span style = {{fontWeight:700, fontSize:"16px"}}>{value}</span>} ,
            accessor: 'firstName',
            id:"firstName"
          },
          {
            Header:  (c) => <span
              style= {{fontWeight:"400", fontSize:"16px"}}
            > 
              {LAST_NAME} <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => c.column.toggleSortBy(!c.column.isSortedDesc)}
                >
                    { c.column.isSorted? <KeyboardArrowDownIcon 
                      fontSize="medium"
                    /> : <KeyboardArrowUpIcon 
                    fontSize="medium"
                  /> }
                </IconButton> </span>,
                 Cell: ({ value, row }) => { 
                  return <span style = {{fontWeight:700, fontSize:"16px"}}>{value}</span>} ,
                  accessor: 'lastName',
                  id:"lastName"
          },
          {
            Header:  (c) => <span
              style= {{fontWeight:"400", fontSize:"16px"}}
            > 
              {EMAIL} <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => c.column.toggleSortBy(!c.column.isSortedDesc)}
                >
                    { c.column.isSorted? <KeyboardArrowDownIcon 
                      fontSize="medium"
                    /> : <KeyboardArrowUpIcon 
                    fontSize="medium"
                  /> }
                </IconButton> </span>,
                Cell: ({ value, row }) => { 
                  return <span style = {{fontWeight:400, fontSize:"16px"}}>{value}</span>} ,
            accessor: 'email',
            id:"email"
          },
          {
            Header:  (c) => <span
              style= {{fontWeight:"400", fontSize:"16px"}}
            > 
              {ROLES} <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => c.column.toggleSortBy(!c.column.isSortedDesc)}
                >
                    { c.column.isSorted? <KeyboardArrowDownIcon 
                      fontSize="medium"
                    /> : <KeyboardArrowUpIcon 
                    fontSize="medium"
                  /> }
                </IconButton> </span>,
            Cell: ({ value, row }) => { 
    
              var roles = !isNil(row.original.plantRoles.roles) && Array.isArray(row.original.plantRoles.roles) ? row.original.plantRoles.roles : []
    
              var r = roles.map( y => y['name'] ).join()
    
              return <span style = {{fontWeight:400, fontSize:"16px"}}>{r}</span>} ,
                accessor: 'userRoles',
                id:"userRoles"
              },
          
        ],[handleEditUserClick, handleRemoveUserClick]
      )
    
      return (
      loading ? <CircularProgress /> : <ExpandableTable
        expandRows = {false}
        showSearchFilter
        columns = {userRoleColumns}
        showHeader = {true}
        tableColor = {"white"}
        renderRowSubComponent = {undefined}
        data = {plantUsers}/>
      );
    }
  
      //Aka table with plant-users
    const UsersTable = useCallback(
        ({ plantFromDTO  }) => 
        {
          return <SubRowAsync
              plantFromDTO={plantFromDTO}
              refresh = {refresh}
        />
  
          },
        [refresh]
      );

  return <> <Table
  stickyHeader
  sx ={{
    padding:"0px",
    borderSpacing: '0px 4px',
  }}
>
    <TableBody>
      <TableRow
        sx = {{
          color:"#003366",
          fontWeight: 700,
          backgroundColor:'rgba(210, 229, 235, 0.2)',

      }}>
          <TableCell
             sx = {{
              color:"#003366",
              padding:"20px",
              fontSize: '21.3838px',
              fontWeight: 700,
          }}>
                      <Box 
                      alignItems="center" display="flex"
                      sx = {{
                         marginLeft:"50px"
                      }}
                  >
                      <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick = { ()=> { setExpandRows(!expandRows)}}
                  >
                      {expandRows ? (
                        <KeyboardArrowUpIcon/>
                      ) : (
                        <KeyboardArrowDownIcon/>
                      )}
                      </IconButton>
                          <Typography
                          sx={{
                              fontWeight: 700,
                              fontSize: '17px',
                              letterSpacing: '0.1em'
                          }}>
                              <span>{plantFromDTO.name.toUpperCase()}</span>
                          </Typography>
                      </Box>
        </TableCell>

        <TableCell
              sx = {{
              color:"#003366",
              padding:"20px",
              fontSize: '21.3838px',
              fontWeight: 700,
          }}>
            <Box 
              alignItems="center" display="flex"
              >
              <IconbuttonWithModal
                  handleModalClicked = {handleAddUserToPlantClick}
                  data = {plantFromDTO}/> 
          </Box>
        </TableCell>
    </TableRow>
  </TableBody>          

</Table>
  { expandRows? (
    <UsersTable plantFromDTO = {plantFromDTO}/>
) : null
} 
</>
}



const IconbuttonWithModal = (props) => {
    const {data, handleModalClicked} = props;
  
    const ADD_USER_TO_PLANT = translationWithCapitalization("addUserToPlant")
  
    const handleClose = ()=> {setOpen(false)}
  
    const [open, setOpen] = useState(false);
  
    return (<>
                <IconButton
                  size="small"
                  onClick={() => setOpen(!open)}
              >
                 <Add/> 
                  </IconButton>
                     <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '16px',
                        letterSpacing: '0.1em'
                      }}>
                        <span>{ADD_USER_TO_PLANT}</span>
                    </Typography>
                    <AddUserToPlantDialog
                      data={data} 
                      open={open}
                      handleAddUser = {handleModalClicked}
                      handleClose = {handleClose}
                    /> 
        </>)
  }

const ManageUserMenu = (props:any ) =>{

    const {selectedUser, onEditPlantUserClicked, onRemovePlantUserClicked} = props;

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);
    
    const [userAccessDialogOpen, setUserAccessDialogOpen] = 
      useState(false)

    const handleDeleteUserDialogClose = ()=> {
      setDeleteUserDialogOpen(false)
      setAnchorEl(null);
    }
    
    const handleUserAccessDialogClose = ()=> {
      setUserAccessDialogOpen(false)
      setAnchorEl(null);
    }
  
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
      
    };
  
    const EDIT_ROLES = translationWithUppercase("editRoles");
    const REMOVE_FROM_PLANT = translationWithUppercase("removeFromPlant"); 
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    
    return <> 
    <IconButton 
      onClick = {handleClick} >
        <Icon>
          <img src = {verticaldot}/> 
        </Icon>
    </IconButton> 
          <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <List>
            <ListItemButton 
              onClick = {()=>setUserAccessDialogOpen(true)}
              >
              <ListItemText 
                primary={EDIT_ROLES} />
            </ListItemButton>
  
            <ListItemButton 
                onClick = {()=>setDeleteUserDialogOpen(!deleteUserDialogOpen)}
              >
              <ListItemText 
                primary={REMOVE_FROM_PLANT} />
            </ListItemButton>
          </List>
        </Popover> 
  
         <DeleteUserDialog
            selectedUser={selectedUser}
            open={deleteUserDialogOpen} 
            handleClose={handleDeleteUserDialogClose}
            handleSubmit={onRemovePlantUserClicked}      
            
            />
  
        <UserAccessDialog
          selectedUser={selectedUser}
          open={userAccessDialogOpen} handleClose={handleUserAccessDialogClose} 
          handleSubmit={onEditPlantUserClicked}      
          /> 
  </>
  
  }