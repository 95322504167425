import React, { useState } from 'react';

import {  TableCell, TableRow, Typography, Table } from "@mui/material"
import { Box, Pagination, TableContainer, TableHead, TablePagination, TableBody } from '@mui/material';

import {
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useExpanded
} from 'react-table';

/**
 * @param data The data you wish to pass in
 * @param columns The columns related to the data in question
 * @param renderRowSubcomponent The subcomponent you wish to render (If any)
 * @param showSearchFilter Whether you wish to have search/filter bar on top or not
 * @param showHeader Whether you wish to have headers or not (title, name etc)
 * @param tableColor Color value in string
 * 
 * TODO: Create Enum for colors
 */
export interface ITableWithPagination {
  data:any[];
  columns:any[];
  expandRows:boolean;
  rowSubComponent:[];
  showHeader:boolean;
}

/**
 * * Generic table and rows related to tables with pagination (Server-side pagination)
 */

export const TableWithPagination = ({
  data, 
  columns, 
  showHeader=true,
  totalCount,
  pageNumber,
  showPagination=true, 
  expandRows = false, 
  renderRowSubComponent, 
  handleSwitchPage,
  tableColor = "#D2E5EB",
}) =>{

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      rows,
      state: { 
        pageSize, 
        expanded
         }
    } = useTable(
      {
        columns,
        data
      },
      useSortBy,
      useExpanded,
      usePagination,
      useRowSelect,
    )


    // Table-pagination actions

    const [rowsPerPage, setRowsPerPage] = useState(100);


      const handleChangePage = (
        newPage: number,
      ) => {
        handleSwitchPage( newPage )
      };

      const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ) => {
        setRowsPerPage(parseInt(event.target.value));
      };

    return data.length>0?<TableContainer
     style={{
        maxHeight: 620
      }}
      {...getTableProps()}
    >
     
      <Table
        size = {"medium"}
        stickyHeader
        {...getTableBodyProps()}
        sx ={{

          padding:"0px"
        }}
    >
      {showHeader&&<TableHead
      >

      {headerGroups.map((headerGroup, i) => {
        return <TableRow
        
          key = {i+200}
          sx = {{
            backgroundColor: tableColor,
          }}>

          {headerGroup.headers.map((column, k) => {return <TableCell 
            key = {k+100}>
            
            <Typography sx = {{
                fontWeight: 400,
                fontSize: '16px',
                color:"#003366",
                fontStyle:"normal"
              }}>
              {column.render("Header")}
              </Typography>
            </TableCell>})
          }
        </TableRow>
      }
      )}
      </TableHead>}

      {rows.map((row:any, key:any)=>{
          prepareRow(row)
          return <TableBody key = {key} ><TableRow {...row.getRowProps()} sx = {{
              backgroundColor: tableColor,
              color:"#003366",
                fontWeight: 700
            }}>
                {row.cells.map( (c, m)=>{
                return <TableCell 
                  key = {m + 59}  sx = {{
                  color:"#003366",
                  fontSize: '21.3838px',
                  fontWeight: 700
                }}>
                  {c.render("Cell")}
              </TableCell>
              } )} 
          </TableRow>
          <TableRow
          >
            {/* Collapsible content  i.e plants for example*/}
              {expandRows && <TableCell colSpan={6}>
                
              { row.isExpanded? (
                    renderRowSubComponent({ row })
                ) : null
                }
            </TableCell>}
          </TableRow>
        </TableBody>          
        })
      }
      </Table>
      {showPagination&&
        <TablePagination
          component="div"
          count={parseInt(totalCount)} //Total number of entities
          page={pageNumber} // Current page
          onPageChange={(value, page)=>{
            handleChangePage(parseInt(page))}}
          // rowsPerPageOptions={[100,200,500,1000]}
          rowsPerPageOptions={[]}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    }
    </TableContainer>:<span>No data</span>
}