import { Box, Typography } from '@material-ui/core';
import Button from '@mui/material/Button';
import  React from 'react';


export const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        sx = {{marginLeft:"auto"}}
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
    );
  }
  

  interface IStyledButtonProps {
    handleClick:Function,
    tabValue:number,
    displayText:string,
    activeValue:number
  }
  
  export const StyledButton = (props:IStyledButtonProps)=>{
  
    const {handleClick, tabValue, displayText, activeValue} = props;
  
    return <Button 
    sx = {{
      backgroundColor: tabValue===activeValue?"#F6FAFB":"",
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '22.4404px',
      paddingLeft:"30px",
      paddingRight:"30px",
      color:"#003366"
    }}  
      onClick = {()=>handleClick(activeValue)}>{displayText}
    </Button>
  
  }
  