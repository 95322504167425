import dotp from "dot-prop-immutable-chain";

import { IActiveAlarmFetched } from "./interfaces";
import { OperationsActions, OperationsActionType } from "./actions";

export interface OperationsState {
    activeAlarmsWithCount:IActiveAlarmFetched[],
    loading:boolean,
    totalCountAllActiveAlarms:number
}

export const initialState: OperationsState = {
    activeAlarmsWithCount: [

    ],
    loading: false,
    totalCountAllActiveAlarms: 0
};

export const operationsReducer = (
    state = initialState,
    action: OperationsActions
  ) => {
      switch (action.type) {
          case OperationsActionType.REQUEST_ALL_ACTIVE_ALARMS_FOR_PLANTS_BY_PLANT: {
            return dotp(state)
            .set("loading", true)
          }
  
          case OperationsActionType.REQUEST_ALL_ACTIVE_ALARMS_FOR_PLANTS_BY_PLANT_SUCCESS: {
            return dotp(state)
              .set("loading", false)
              .set("activeAlarmsWithCount", action.payload.plantAlarmCounts)
              .set("totalCountAllActiveAlarms", action.payload.totalAlarmCount)
              .value();
          }
  
          case OperationsActionType.REQUEST_ALL_ACTIVE_ALARMS_FOR_PLANTS_BY_PLANT_FAILURE: {
            return dotp(state)
              .set("loading", false)
          }
  
          default:
            return state;
        }
  };