/* eslint-disable */

import { Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useAPIIsConnected, useUserHasLoaded, useUserRoles } from '../../user/hooks';
import { setRoles } from '../../user/util';
import { EnergySummary } from './components/energySummary';
import { FinanceSummary } from './components/invoiceSummary';
import { NoRolesAssignedCard } from './components/noRolesAssigned';
import { OperationsSummary } from './components/operationsSummary';

export const StartPage = () => {

  const userRoles = useUserRoles();
  const userIsLoading = useUserHasLoaded();
  const apiIsConnected = useAPIIsConnected()
  const [hasNoRoles, setHasNoRoles] = useState(false);

  useEffect(() => {
    setHasNoRoles(!userIsLoading && userRoles.length === 0 && apiIsConnected)
  }, [userIsLoading, userRoles, apiIsConnected])

  const rolesTransformed = useMemo(() => {
    return setRoles(userRoles)
  }, [userRoles]);

  return (
    <Grid
      gap={2}
      container
      justifyContent="center"
    >
      {rolesTransformed.some(i => i.name == "energy") && <EnergySummary />}
      {rolesTransformed.some(i => i.name == "finance") && <FinanceSummary />}
      {rolesTransformed.some(i => i.name == "operations") && <OperationsSummary />}
      {hasNoRoles && <NoRolesAssignedCard />}
    </Grid>)
}