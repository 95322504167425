import * as qs from 'qs';
import dayjs from "dayjs";
import axios from "axios";
import {from, Observable, of } from "rxjs";
import { combineEpics, ofType } from "redux-observable";
import {PublicClientApplication} from "@azure/msal-browser";
import { catchError, withLatestFrom, mergeMap, map } from "rxjs/operators";

import {
    AlarmRequestAll,
    AlarmRequestAllSuccess,
    AlarmRequestAllFailure,
    AlarmActionType,
    RequestActiveAlarmCountForAllPlants,
    RequestActiveAlarmCountForAllPlantsSuccess,
    RequestActiveAlarmCountForAllPlantsFailure,
    RequestSearchAlarmHistoryForPlantsSuccess,
    RequestSearchAlarmHistoryForPlantsFailure,
    RequestSearchAlarmHistoryForPlants,
    RequestSearchActiveAlarmHistoryForPlantsSuccess,
    RequestSearchActiveAlarmHistoryForPlantsFailure,
    RequestSearchActiveAlarmHistoryForPlants
} from "./";

import { AppState } from "../../core";

const RequestAllAlarmsForAllPlantsEpic$ = (
  action$: Observable<any>,
  store$: Observable<any>,
  instance: PublicClientApplication
) => {
  return action$.pipe(
        ofType(AlarmActionType.REQUEST_ALL),
        withLatestFrom(store$),
        mergeMap(([action, store]: [AlarmRequestAll, AppState]) =>
        {
          return from(
               axios.get(`${store.settings.server}/api/Alarms/plants?isActive=true&pageNumber=${action.payload.activePage}&limit=100`,  {
                 params:{
                   plant:[...store.user.plants.map(p=>p.shortName)]
                  },
                  paramsSerializer: params => {
                    return qs.stringify(params)
                  }
                }   ).then( response => response.data)
          )
          .pipe(
              map((response: any) => {
                return new AlarmRequestAllSuccess(response.map(({lastEventTime, annunciationTime, lastActiveTime, ...alarm}) => (
                  {"lastEventTime": dayjs(lastEventTime).format('YYYY.MM.DD HH:mm'),
                  "annunciationTime": dayjs(annunciationTime).format('YYYY.MM.DD HH:mm'),
                  "lastActiveTime": dayjs(lastActiveTime).format('YYYY.MM.DD HH:mm'),
                  ...alarm})) )
              })
          )
              .pipe(catchError(err => of(new AlarmRequestAllFailure(err))))
        })
      );
    };

    const RequestActiveAlarmCountForAllPlantsEpic$ = (
      action$: Observable<any>,
      store$: Observable<any>,
      instance: PublicClientApplication
    ) => {
      return action$.pipe(
            ofType(AlarmActionType.REQUEST_ACTIVE_ALARM_COUNT_FOR_ALL_PLANTS),
            withLatestFrom(store$),
            mergeMap(([action, store]: [RequestActiveAlarmCountForAllPlants, AppState]) =>
            {
              return from(
                   axios.post(`${store.settings.server}/api/Alarms/activeAlarmSearchCountForPlants`,  
                   action.payload
                       ).then( response => response.data)
              )
              .pipe(
                  map((response: any) => {
                    return new RequestActiveAlarmCountForAllPlantsSuccess(response)
                  })
              )
                  .pipe(catchError(err => of(new RequestActiveAlarmCountForAllPlantsFailure(err))))
            })
          );
        };
    
        const RequestSearchAlarmHistoryForPlantsEpic$ = (
          action$: Observable<any>,
          store$: Observable<any>,
          instance: PublicClientApplication
        ) => {
          return action$.pipe(
                ofType(AlarmActionType.REQUEST_SEARCH_ALARM_HISTORY_FOR_PLANTS),
                withLatestFrom(store$),
                mergeMap(([action, store]: [RequestSearchAlarmHistoryForPlants, AppState]) =>
                {
                  return from(
                       axios.post(`${store.settings.server}/api/Alarms/alarmSearchForPlants`,  
                        action.payload,
                        {
                          headers: {
                          'Content-Type': 'application/json'
                          }
                      }
                        ).then( response => response.data)
                  )
                  .pipe(
                      map((response: any) => {
                        return new RequestSearchAlarmHistoryForPlantsSuccess(response)
                      })
                  )
                      .pipe(catchError(err => of(new RequestSearchAlarmHistoryForPlantsFailure(err))))
                })
              );
            };

            const RequestSearchActiveAlarmHistoryForPlantsEpic$ = (
              action$: Observable<any>,
              store$: Observable<any>,
              instance: PublicClientApplication
            ) => {
              return action$.pipe(
                    ofType(AlarmActionType.REQUEST_SEARCH_ACTIVE_ALARM_HISTORY_FOR_PLANTS),
                    withLatestFrom(store$),
                    mergeMap(([action, store]: [RequestSearchActiveAlarmHistoryForPlants, AppState]) =>
                    {
                      return from(
                           axios.post(`${store.settings.server}/api/Alarms/alarmSearchForPlants`,  
                            action.payload,
                            {
                              headers: {
                              'Content-Type': 'application/json'
                              }
                          }
                            ).then( response => response.data)
                      )
                      .pipe(
                          map((response: any) => {
                            return new RequestSearchActiveAlarmHistoryForPlantsSuccess(response)
                          })
                      )
                          .pipe(catchError(err => of(new RequestSearchActiveAlarmHistoryForPlantsFailure(err))))
                    })
                  );
                };


export const alarmEpics = combineEpics(
    RequestAllAlarmsForAllPlantsEpic$,
    RequestActiveAlarmCountForAllPlantsEpic$,
    RequestSearchAlarmHistoryForPlantsEpic$,
    RequestSearchActiveAlarmHistoryForPlantsEpic$
  );