import i18n from 'i18next';
import { initReactI18next, Translation } from 'react-i18next';

import HttpBackend  from 'i18next-http-backend';
import ChainedBackend from "i18next-chained-backend";
import LanguageDetector from 'i18next-browser-languagedetector';
import { TRANSLATIONS_EN } from './en/translations';
import LocalStorageBackend from "i18next-localstorage-backend";
import { TRANSLATIONS_NNO } from './nno/translations';
import { TRANSLATIONS_NOB } from './nob/translations';


i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(ChainedBackend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    react: {
      useSuspense: false
  },
    resources:{
        en:{
            translation:TRANSLATIONS_EN
        },
        nno:{
          translation:TRANSLATIONS_NNO
        },
        nob:{
          translation:TRANSLATIONS_NOB
        },
    },
    fallbackLng: 'en',
    backend: {
      backends:[
        LocalStorageBackend,
        HttpBackend
      ],
      backendOptions: [{
        expirationTime: 7 * 24 * 60 * 60 * 1000 // 7 days
      }, {
        loadPath: '/locales/{{lng}}/{{ns}}.json'
      }]
    },

    debug: true,

  });


export default i18n;