
import React, {useState, useEffect }  from "react";

import axios from "axios";
import { isNil } from "lodash";
import { CircularProgress, Grid, Paper } from '@mui/material';

import { useApiUrl } from "../../settings/hook";
import { translationWithUppercase } from "../../core";
import { AddButton } from "./components/table/addButton";
import { DropDown } from "./components/dropDown/dropDown";
import { ShipRegistryTable } from "./components/table/shipregistryTable";
import { ICreateShipDTO, IShip, IShipRegistry, IUpdateShipDTO } from "./models/interfaces";

export const ShipRegistryPage = ()=>{

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [shipregistries, setShipregistries] = useState<IShipRegistry[] | []>([]);
    const [selectedShipregistryIndex, setSelectedShipregistryIndex] = useState<number|undefined>(undefined);

    const NO_SHIP_REGISTRY_SELECTED = translationWithUppercase("noShipRegistrySelected");

    const apiURL = useApiUrl();

    // onMount
    useEffect(()=>{ 
      getShipregistries();
    },[])

    function getShipregistries(){
      setIsLoading(true)
        
      axios
        .get(`${apiURL}/ShipRegistry` ,)
        .then( (i:any) =>
          {
            setShipregistries(i.data);
            if (i.data.length===1){
              setSelectedShipregistryIndex(0);
            }
          })
          .catch()
          .finally( ()=>{
            setIsLoading(false)
          })
    }

    const handleEditShip = (data:any, id:string)=>{

        var updateShipDto:IUpdateShipDTO = {
          imo: data.imo,
          price: data.price.toString(),
          contactName: data.contactName,
          vesselName: data.vesselName,
          contactPhone: data.contactPhoneNumber,
          contactEmail: data.contactEmail,
          shipId: id
        }

        setIsLoading(true)
         // ApiCall
        axios
        .put(`${apiURL}/ShipRegistry/ship/update` , updateShipDto)
        .then(i=>
          {
            getShipregistries();
          })
          .catch(()=>{
          })
          .finally( ()=>{
            setIsLoading(false)
          })
          setIsLoading(false)
    }

    const handleAddShip = (data:any)=>{

      var createShipDto:ICreateShipDTO = {
        imo: data.imo,
        price: data.price.toString(),
        contactName: data.contactName,
        vesselName: data.vesselName,
        contactPhone: data.contactPhoneNumber,
        contactEmail: data.contactEmail,
        shipRegistryId: shipregistries[selectedShipregistryIndex]['id']
      }

        setIsLoading(true)
         // ApiCall
        axios
        .post(`${apiURL}/ShipRegistry/ship` , 
          createShipDto
        )
        .then(i=>
          {
            getShipregistries();
          })
          .catch()
          .finally( ()=>{
            setIsLoading(false)
          })
       
        setIsLoading(false)
    }

    const handleDeleteShip = (ship:IShip)=>{
       setIsLoading(true)

       const URL = apiURL + `/ShipRegistry/ship/${ship.id}`;

          setIsLoading(true)
          axios
          .delete(URL)
          .then( (response) => {
            setIsLoading(false)
            getShipregistries();
          })
          .catch( (error) => {
           
          })
          .finally(
            ()=>{
              setIsLoading(false)
            }
          )
    }
  
      return ( isLoading? 
       <Grid  
        sx={{
          display: 'flex', 
          justifyContent: 'center'
          }} >
          <CircularProgress/>
        </Grid>    :  <Grid 
            container 
            justifyContent={"space-between"} 
            
            >
      <Grid  
        item
        container xs={12} 
        md = {12} 
        direction="row"
        alignItems="flex-start"
        
        >
        <Grid 
          item xs = {12}  
          component = {Paper} 
          sx = {{
            padding:"50px",
            overflow:'auto',
            height: '50vw'
          }}>
              <Grid container item justifyContent={"flex-end"}>
                <DropDown handleSetProperty={setSelectedShipregistryIndex} listOfProperties={shipregistries} />
                <AddButton
                  handleAddShip = {handleAddShip}
                  disabled = {isNil(selectedShipregistryIndex)}
                />
              </Grid>
            
            <Grid item xs = {12}  >
              {
                !isNil(shipregistries[selectedShipregistryIndex]?.ships)&&<ShipRegistryTable
                  data = {shipregistries[selectedShipregistryIndex]?.ships}
                  clickEditShip = {handleEditShip}
                  clickDeleteShip = {handleDeleteShip}
                /> 
              }
                {
                  isNil(selectedShipregistryIndex)&&<span>{NO_SHIP_REGISTRY_SELECTED}</span>
                }
            </Grid>
        </Grid>
      </Grid>   
      </Grid>   

      )
  }
  