import { PublicClientApplication } from "@azure/msal-browser";
import axios from "axios";
import { combineEpics, ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { AppState } from "../core";
import {
  PlantRequestPlantsFromRoles, PlantRequestPlantsFromRolesFailure, PlantRequestPlantsFromRolesSuccess, UserActionType,
  UserRequestAll, UserRequestAllFailure, UserRequestAllSuccess
} from "./";

const userRequestAllEpic$ = (
  action$: Observable<any>,
  store$: Observable<any>,
  instance: PublicClientApplication
) => {
  return action$.pipe(
        ofType(UserActionType.REQUEST_ALL),
        withLatestFrom(store$),
        mergeMap(([, store]: [UserRequestAll, AppState]) =>
        {
          return from(
              axios.get(`${store.settings.server}/api/User/me`)
          ).pipe(
              map((response: any) => {
                   return new UserRequestAllSuccess(response.data);
              })
          )
              .pipe(catchError(err => of(new UserRequestAllFailure(err))))
        })
      );
    };


    const plantRequestMyPlantsFromRolesEpic$ = (
      action$: Observable<any>,
      store$: Observable<any>,
      instance: PublicClientApplication
    ) => {
      return action$.pipe(
          ofType(UserActionType.REQUEST_PLANTS_FROM_ROLES),
            withLatestFrom(store$),
            mergeMap(([, store]: [PlantRequestPlantsFromRoles, AppState]) =>
            {
              return from(
                  axios.get(`${store.settings.server}/api/Plant?roles=Operations&roles=Superuser`)
              ).pipe(
                  map((response: any) => {
                       return new PlantRequestPlantsFromRolesSuccess(response.data);
                  })
              )
                  .pipe(catchError(err => of(new PlantRequestPlantsFromRolesFailure(err))))
            })
          );
        };
  
  

export const userEpics = combineEpics(userRequestAllEpic$, plantRequestMyPlantsFromRolesEpic$);
