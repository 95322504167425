import * as qs from 'qs';
import axios from "axios";
import {from, Observable, of } from "rxjs";
import { combineEpics, ofType } from "redux-observable";
import {PublicClientApplication} from "@azure/msal-browser";
import { catchError, withLatestFrom, mergeMap, map } from "rxjs/operators";

import { AppState } from "../../core";
import { 
  ActiveAlarmsRequestAllForPlantsByPlant, 
  ActiveAlarmsRequestAllForPlantsByPlantFailure, 
  ActiveAlarmsRequestAllForPlantsByPlantSuccess, 
  OperationsActionType 
} from "./actions";

const operationsRequestActiveAlarmsForAllPlantsEpic$ = (
  action$: Observable<any>,
  store$: Observable<any>,
  instance: PublicClientApplication
) => {  return action$.pipe(
        ofType(OperationsActionType.REQUEST_ALL_ACTIVE_ALARMS_FOR_PLANTS_BY_PLANT),
        withLatestFrom(store$),
        mergeMap(([action, store]: [ActiveAlarmsRequestAllForPlantsByPlant, AppState]) =>
        {
          return from(
              axios.post(`${store.settings.server}/api/Alarms/alarmCountForPlantsByPlant`,
                action.payload
                    ).then( response => response.data)
          )
          .pipe(
              map((response: any) => {
                return new ActiveAlarmsRequestAllForPlantsByPlantSuccess(response)
              })
          )
              .pipe(catchError(err => of(new ActiveAlarmsRequestAllForPlantsByPlantFailure(err))))
        })
      );
    };
    
export const operationsEpics = combineEpics(
    operationsRequestActiveAlarmsForAllPlantsEpic$,
  );