import dotp from "dot-prop-immutable-chain";
import { IAlarm, AlarmActions, AlarmActionType } from ".";

export interface AlarmState {
    alarms:IAlarm[],
    loading:boolean,
    useTotalCountForSearch:number,
    totalCountAllActiveAlarms:number,
    activeAlarms:IAlarm[]
}

// Todo: Use Enum for loading state (loading, failure, success) instead of a boolean

export const initialState: AlarmState = {
    alarms:[],
    loading:true,
    useTotalCountForSearch:0,
    totalCountAllActiveAlarms:0,
    activeAlarms:[]
};

export const alarmReducer = (
  state = initialState,
  action: AlarmActions
) => {
    switch (action.type) {
        case AlarmActionType.REQUEST_ALL_SUCCESS: {
          return dotp(state)
            .set("alarms", action.payload)
            .set("loading", false)
            .value();
        }

        case AlarmActionType.REQUEST_ALL: {
          return dotp(state)
            .set("loading", true)
            .value();
        }

        case AlarmActionType.REQUEST_ALL_FAILURE: {
          return dotp(state)
            .set("loading", false)
            .value();
        }

        case AlarmActionType.REQUEST_ACTIVE_ALARM_COUNT_FOR_ALL_PLANTS_SUCCESS: {
          return dotp(state)
            .set("totalCountAllActiveAlarms", action.payload)
            .value();
        }


        // For generic searches, and for initial load. By default set to AlarmOptions.All

        case AlarmActionType.REQUEST_SEARCH_ALARM_HISTORY_FOR_PLANTS: {
          return dotp(state)
            .set("loading", true)
            .value();
        }

        case AlarmActionType.REQUEST_SEARCH_ALARM_HISTORY_FOR_PLANTS_FAILURE: {
          return dotp(state)
            .set("loading", false)
            .value();
        }

        case AlarmActionType.REQUEST_SEARCH_ALARM_HISTORY_FOR_PLANTS_SUCCESS: {
          return dotp(state)
            .set('alarms', action.payload.searchAlarms)
            .set('useTotalCountForSearch', action.payload.totalCount)
            .set("loading", false)
            .value();
        }


          // For showing in menu we only want active-search. Will, if AlarmOptions is set to AlarmOptions.activeAlarms display the same as REQUEST_SEARCH_ALARM_HISTORY_FOR_PLANTS

        case AlarmActionType.REQUEST_SEARCH_ACTIVE_ALARM_HISTORY_FOR_PLANTS: {
          return dotp(state)
            .value();
        }

        case AlarmActionType.REQUEST_SEARCH_ACTIVE_ALARM_HISTORY_FOR_PLANTS_FAILURE: {
          return dotp(state)
            .value();
        }

        case AlarmActionType.REQUEST_SEARCH_ACTIVE_ALARM_HISTORY_FOR_PLANTS_SUCCESS: {
          return dotp(state)
            .set('activeAlarms', action.payload.searchAlarms)
            .set('useTotalCountForSearch', action.payload.totalCount)
            .value();
        }

        default:
          return state;
      }
};