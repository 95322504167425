
// Request all plants

import { Action } from "redux";
import { IFailureAction, ISuccessAction } from "../core";

export enum SignalRActionType {
    INITIAL_PLANT_DATA = "[Plant] Plant Initial data",
    INITIAL_PLANT_DATA_SUCCESS = "[Plant][Success] Initial Plant data",
    INITIAL_PLANT_DATA_FAILURE = "[Plant][Failure] Initial Plant data",

    PLANT_UPDATE_DATA = "[Plant] Plant update data",
    PLANT_UPDATE_DATA_SUCCESS = "[Plant][Success] Plant update data",
    PLANT_UPDATE_DATA_FAILURE = "[Plant][Failure] Plant update data",

    START_CONNECTION = "[Connection] Start Connection",
    START_CONNECTION_SUCCESS = "[Connection][Success] Start Connection",
    START_CONNECTION_FAILURE = "[Connection][Failure] Start Connection",

    SET_CONNECTION_STATUS = "[Connection] Set Connection Status",

    SET_CONNECTION_RETRIES = "[Connection] Set Retries"

  }

  export class SetConnectionStatus implements Action {
    readonly type = SignalRActionType.SET_CONNECTION_STATUS;
    constructor(public payload: any) {}

  }

  export class SetConnectionRetries implements Action {
    readonly type = SignalRActionType.SET_CONNECTION_RETRIES;
    constructor(public payload: any) {}

  }
  
  export type PlantActions =
    SetConnectionStatus
  | SetConnectionRetries

