import { Action } from "redux";
import { ISuccessAction, IFailureAction } from "../core";
import { IUser } from "./";

export enum UserActionType {
  REQUEST_ALL = "[User] Request All",
  REQUEST_ALL_SUCCESS = "[User][Success] Request All",
  REQUEST_ALL_FAILURE = "[User][Failure] Request All",

  REQUEST_PLANTS_FROM_ROLES = "[Plant] Request Plants from Roles",
  REQUEST_PLANTS_FROM_ROLES_SUCCESS = "[Plant][Success] Request Plants from Roles Success",
  REQUEST_PLANTS_FROM_ROLES_FAILURE = "[Plant][Failure] Request Plants from Roles Failure",

  SET_User = "[User] Set User"
}

export class UserRequestAll implements Action {
  readonly type = UserActionType.REQUEST_ALL;
}

export class UserRequestAllSuccess implements Action, ISuccessAction {
  readonly type = UserActionType.REQUEST_ALL_SUCCESS;
  readonly success = true;
  constructor(public payload: IUser) {}
}

export class UserRequestAllFailure implements Action, IFailureAction {
  readonly type = UserActionType.REQUEST_ALL_FAILURE;
  readonly failure = true;
  constructor(public payload: any) {}
}


export class PlantRequestPlantsFromRoles implements Action {
  readonly type = UserActionType.REQUEST_PLANTS_FROM_ROLES;
}

export class PlantRequestPlantsFromRolesSuccess implements Action, ISuccessAction {
  readonly type = UserActionType.REQUEST_PLANTS_FROM_ROLES_SUCCESS;
  readonly success = true;
  constructor(public payload: any[]) {}
}

export class PlantRequestPlantsFromRolesFailure implements Action, IFailureAction {
  readonly type = UserActionType.REQUEST_PLANTS_FROM_ROLES_FAILURE;
  readonly failure = true;
  constructor(public payload: any) {}

}



export class UserSelect implements Action {
  readonly type = UserActionType.SET_User;
  constructor(public payload: string) {}
}

export type UserActions =
  | UserRequestAll
  | UserRequestAllSuccess
  | UserRequestAllFailure
  | PlantRequestPlantsFromRoles
  | PlantRequestPlantsFromRolesSuccess
  | PlantRequestPlantsFromRolesFailure
  | UserSelect;
