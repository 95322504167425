import { combineReducers } from "redux";

import {
  initialState as settingInitialState,
  settingsReducer,
  SettingsState
} from "../settings";
import { signalRReducer } from "../signalr/reducer";
import { initialState as signalRInitialState, SignalRState } from "../signalr";
import { plantReducer, PlantState, initialState as PlantInitialState } from "../plant";
import { alarmReducer, AlarmState, initialState as AlarmInitialState } from "../browse/alarmpage";
import {EventState, eventReducer, initialState as EventInitialState} from '../browse/alarmpage/events';
import { initialState as OperationsInitialState, operationsReducer, OperationsState } from '../browse/operations'
import {
  userReducer,
  UserState,
  initialState as userInitialState
} from "../user";

export interface AppState {
  settings: SettingsState;
  signalRState: SignalRState;
  plant: PlantState;
  alarm: AlarmState;
  event: EventState;
  user: UserState;
  operations: OperationsState;
}

export const initialState: Partial<AppState> = {
  settings: settingInitialState,
  plant: PlantInitialState,
  alarm: AlarmInitialState,
  event: EventInitialState,
  user: userInitialState,
  operations: OperationsInitialState

};

export const createRootReducer = () =>
  combineReducers({
    plant: plantReducer,
    signalRState: signalRReducer,
    settings: settingsReducer,
    alarm: alarmReducer,
    event: eventReducer,
    user: userReducer,
    operations:operationsReducer
  });