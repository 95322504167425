import { Action } from "redux";
import { SettingsCurrentOperator, SettingsCurrentPlant, SettingsLocale, SettingsLocales } from "./interfaces";

export enum SettingsActionType {
  SET_THEME = "[Settings] Set Theme",
  SET_THEME_SUCCESS = "[Settings][Success] Set Theme",
  SET_THEME_FAILURE = "[Settings][Failure] Set Theme",
  SET_LOCALE = "[Settings] Set Locale",
  SET_LOCALES = "[Settings] Set Locales",
  SET_NAME = "[Settings] Set Name",

  SET_CURRENT_PLANT = "[Settings] Set Current Plant",
  SET_CURRENT_PLANT_SUCCESS = "[Settings] Set Current Plant Success",
  SET_CURRENT_PLANT_FAILURE = "[Settings] Set Current Plant Failure",

  SET_CURRENT_OPERATOR = "[Settings] Set Current Operator",
  SET_CURRENT_OPERATOR_SUCCESS = "[Settings] Set Current Operator Success",
  SET_CURRENT_OPERATOR_FAILURE = "[Settings] Set Current Operator Failure",

  REFRESH_PLANT_USERS = "[User Management] Refresh Plant Users"
}

export class SettingsSetLocale implements Action {
  readonly type = SettingsActionType.SET_LOCALE;
  constructor(public payload: SettingsLocale) {}
}

export class SettingsSetLocales implements Action {
  readonly type = SettingsActionType.SET_LOCALES;
  constructor(public payload: SettingsLocales) {}
}

export class SettingsSetCurrentPlant implements Action {
  readonly type = SettingsActionType.SET_CURRENT_PLANT;
  constructor(public payload: SettingsCurrentPlant) {}
}

export class SettingsSetCurrentOperator implements Action {
  readonly type = SettingsActionType.SET_CURRENT_OPERATOR;
  constructor(public payload: SettingsCurrentOperator) {}
}

export type SettingsActions = SettingsSetLocale 
| SettingsSetLocales 
| SettingsSetCurrentPlant 
| SettingsSetCurrentOperator
