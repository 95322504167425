import React, { useState} from 'react';
import { makeStyles } from '@mui/styles';
import {  Grid, Paper } from '@mui/material';

import { AsideCard, AsideCardModal } from '../../core/components/Card/Components';

const useStyles = makeStyles((theme) => ({
    card:{
      padding:"50px"
    }
  })

,{ index: 1 }
  );
export const UtilizationPage = ()=>{
  
    const [openModal, setOpenModal] = useState(false);
    const classes = useStyles();
    return (  <Grid container justifyContent={"space-between"} >
    <Grid  
      item
      container xs={12} 
      md = {9} 
      direction="row"
      justify="flex-start"
      alignItems="flex-start">
      
      <Grid item xs = {12}  component = {Paper} className = {classes.card}>
      </Grid>
    </Grid>
    <Grid 
      container item 
      xs={12} md = {2} 
      spacing={2}
      direction="row"
      height = {"10vh"}
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs= {12} >
      <AsideCardModal
        title = {"Utilization"}
        data = {0}
        cardAction = {setOpenModal}
        actionState = {openModal}
        unit = {""}
        />
      </Grid>
      <Grid item xs= {12}>
        <AsideCard
         title = "test"/>
      </Grid>
    </Grid>
  </Grid>    
  )
}