
const common = {
    high:"høy", //Alto
    medium:"middels", //média
    alarm:"alarm",
    alarms:"alarmer",
    total:"total",
    energy:"energi",
    lastMonth:"siste måned",
    viewAllAlarms:"se alle alarmer",
    loadingPleaseWait:"laster vennligst vent...",
    time:"tid",
    accountsettings:"kontoinnstillinger",
    search:"søk",
    now:"nå",
    signOut:"logg ut",
    home:"hjem",
    settings:"innstillinger",
    notifications:"varsel",
    all:"alle",
    filterBy:"filtrer etter",
    ok:"ok",
    meteredEnergy: "målt energi",
    meteredHours: "målt timer",
    sessionStart:"start",
    sessionEnd:"slutt",
    duration:"tid",
    consumedPower:"forbrukt energi",
    harbor:"havn",
    noText:"ingen tekst funnet"
}

const glossary = {
    cancel:"avbryt",
    selected: "valgt",
    noData:"ingen data",

    users:"brukere",
    customers:"kunder"
}

const pageNotFound = {
 fourOFour:"404 page not found",
 couldNotFind:"Sorry we could not find the page you were looking for",
 whatCouldhaveCaused404: "What could have caused this to happen?",
 removedPageInQuestion:"We have removed the page in question",
 linkClickedBroken:"The link you clicked may be old/broken",
 typedAddressWrongly:"You have typed the address wrong",
 whatCanYouDoFourOFour:"What you can do?",
 notifyBrokenLink:"Notify us about any broken links",
 retypeUrlFourOFour:"Retype the URL",
 clickButtonBelowFourOFour:"Click the button below which will take you home",
 takeMeHome:"Take me home",
}

const userManagement = {
    usermanagement:"brukerhåndtering",
    inviteUser:"inviter bruker",
    noRolesAssignedToYou: "du har ikke tilgong til noen anlegg",
    pleaseContactCustomerAdmin: "vær vennlig å kontakte superbruker",
    selectCustomer:"velg bruker",
    isARequiredField:"er et nødvendig felt",
    displayNameForPlant:"visningsnavn for anlegg",
    shortNameForPlant:"kortnavn for anlegg",
    selectExistingPlant:"velg et eksisterende anlegg",
    selectExistingUser:"vel en eksisterende bruker",
    searchForUser: "søk etter bruker...",
    addUsersAndRolesFromAnExistingPlant:"legg til roller og brukere ifra et eksisterende anlegg",
    copyUsersAndRolesFrom:"dette vil kopiere brukera og rollar ifra",
    createPlantConfirmation:"Bekreft lag anlegg",
    manageAllRoles:"håndter alle roller",
    manageUserAccesses:"rediger roller for bruker",
    to:"til",

    addUserToPlant: "legg til bruker på anlegg",
    roles:"roller",
    email:"epost",
    lastName:"etternavn",
    firstName:"fornavn",
    selectUser:"velg bruker",
    editRoles:"styr brukertilgang",
    removeFromPlant:"fjern bruker ifra anlegg",
    manageUseraccess:"styr brukartilgang",
    customer:"kunde",

    remove:"fjerne",

    from:"fra",

    add: "legg til",
    user: "bruker",
    addUser:"legg til bruker",

    areYouSureYouWantTo:"er du sikker på at du vil",

    update:"update",

    userHasBeenAdddedTo: "bruker har blitt lagt til",
    errorAddingUserTo: "feil ved å legge til",
}
const operations ={
    operations:"operasjoner",
    alarmsActive:"aktive alarmer",
    noAlarms: "ingen alarmer",
    allPlants:"alle anlegg",
    lines:"linjer",
    line:"linje",
    active:"aktive",
    inactive:"ikke aktive",
    error:"feil",
    operationsOffLine:"operasjoner nede! Vennligst kontakt systemansvarlig",
    atLeastOneActiveAlarm: "anlegg har minst en aktiv alarm",
    noActiveAlarms: "anlegg har ingen aktive alarmer",
    plantNotOnline: "anlegg er ikke på nett",
    shipIsNotConnected: "ingen aktiv sesjon for denne linjen",
    noActiveAlarmsFound: "Ingen aktive alarmer funnet! Problemer med tilkopling",
    telemetryNotAvailable: "Telemetri ikke tilgjengelig! Prøv igjen senre!",
    lineHasMissingData: "linje har manglende data, venligst kontakt anleggsadminstrator",
    viewAlarmDetails:"Se alarmdetaljer",
    plant:"anlegg",
}

const shipRegistry = {
    addShip: "legg til skip",
    editShip:"rediger Ship",
    shipData: "skipsdata",
    shipRegistry: "skipsregister",
    imoNumber:"imoNumber",
    vesselName:"fartøynavn",
    shipRegistryId:"skipsregister id",
    contactPhoneNumber:"kontakt telefonnummer",
    selectAShipRegister:"velg et skipsregister...",
    noShipRegistrySelected:"ingen skipsregister valgt...",
    contactInformation:"kontaktinformasjon",
    contactName:"kontaktnamn",
    contactEmail:"kontakt E-post",
    price:"pris",
    save:"lagre"
}

const finance = {
    finance:"finans",
    lastTransactions:"siste transaksjoner",
    price:"pris",
    totalSessionPrice:"pris for hele sesjonen",
    noPlantSelected: "ingen anlegg valgt, eller faktura funnet"
}

const settings = {
    languages:"språk",
    accountSettings:"kontoinnstillinger",
    accountsettings:"kontoinnstillinger",
}

const emission = {
    emission:"utslipp",
}

const alarms ={
    allAlarms:"alle alarmer",
    activeAlarms:"aktive alarmer",
    inactiveAlarms:"inaktive alarmer",
    selectAlarmStatus:"velg alarm status",

    searchForAlarms:"søk for alarmer",
    viewAllAlarms:"se alle alarmer",
    priority:"prioritet ",
    alarmText: "alarm tekst",
    activeStatus:"aktiv status",
    status:"status",
    notAcknowledgedAlarm:"ikke kvittert",
    acknowledgedBy: "kvittert av"

}

const events ={
    searchForEvents:"søk for hendelser",
    source: "kilde",
    events:"hendelser",
    time:"tid",
    type:"type",
    eventTexts: "tekst",
}

export const TRANSLATIONS_NOB = {
    ...settings,
    ...pageNotFound,
    ...common,
    ...userManagement,
    ...shipRegistry,
    ...glossary,
    ...alarms,
    ...events,
    ...operations,
    ...finance,
    ...emission,
    utilization:"utnyttingsgrad",
    energy:"energi",
    operators:"operatører",
    customers:"kunder",
    alarm:"alarm",
    plants:"anlegg",
    vessel:"skip",
    total:"total",
    viewAll: "se alle",
    ipsum:"O Lorem Ipsum é um texto modelo da indústria tipográfica e de impressão. O Lorem Ipsum tem vindo a ser o texto padrão usado por estas indústrias desde o ano de 1500, quando uma misturou os caracteres de um texto para criar um espécime de livro. Este texto não só sobreviveu 5 séculos, mas também o salto para a tipografia electrónica, mantendo-se essencialmente inalterada. Foi popularizada nos anos 60 com a disponibilização das folhas de Letraset, que continham passagens com Lorem Ipsum, e mais recentemente com os programas de publicação como o Aldus PageMaker que incluem versões do Lorem Ipsum. "
   };