import React from 'react';

import { InputBase } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

 interface IGlobalFilter {
   globalFilter?:string,
   setGlobalFilter:Function,
   preGlobalFilteredRows:[]
 }

 /**
 * @param Globalfilter The string character you enter into the input field
 * @param setGlobalFilter Function for setting the filter
 * @param preGlobalFilteredRows Rows before any filtering has been carried out
 */

export const GlobalFilter  = ({preGlobalFilteredRows, globalFilter, setGlobalFilter }: IGlobalFilter) => {

    return ( <div style = {{
      display:"flex",
      flexWrap: "wrap",
      gap:'15px'
    }}>
        <SearchIcon />
        <div>
          < InputBase
              fullWidth
              value={globalFilter || ''}
              placeholder={'SEARCH'}
              onChange={e => {
                setGlobalFilter(e.target.value || undefined)
              }}
          />
        </div>
      </div>
    )
  }