import * as qs from 'qs';
import {from, Observable, of } from "rxjs";
import { combineEpics, ofType } from "redux-observable";
import { catchError, withLatestFrom, mergeMap, map } from "rxjs/operators";
import {PublicClientApplication} from "@azure/msal-browser";

import {
    EventRequestAll,
    EventRequestAllSuccess,
    EventRequestAllFailure,
    EventActionType,
    RequestSearchForEventsForPlants,
    RequestSearchForEventsForPlantsSuccess,
    RequestSearchForEventsForPlantsFailure
} from "./";

import axios from "axios";
import dayjs from "dayjs";
import { AppState } from "../../../core";

const RequestEventsForAllPlantsEpic$ = (
  action$: Observable<any>,
  store$: Observable<any>,
  instance: PublicClientApplication
) => {
  return action$.pipe(
        ofType(EventActionType.REQUEST_ALL),
        withLatestFrom(store$),
        mergeMap(([action, store]: [EventRequestAll, AppState]) =>
        {
          return from(
               axios.get(`${store.settings.server}/api/Events/plants?limit=100&pageNumber=${action.payload.activePage}`,  {
                 params:{
                   plant:[...store.user.plants.map(p=>p.shortName)]
                  },
                  paramsSerializer: params => {
                    return qs.stringify(params)
                  }
                }   ).then( response => response.data)
          )
          .pipe(
              map((response: any) => {
                return new EventRequestAllSuccess(response.map(({time, ...event}) => (
                  {"time": dayjs(time).format('YYYY.MM.DD HH:mm'),
                  ...event})) )
              })
          )
              .pipe(catchError(err => of(new EventRequestAllFailure(err))))
        })
      );
    };

    const RequestEventSearchForPlantsEpic$ = (
      action$: Observable<any>,
      store$: Observable<any>,
      instance: PublicClientApplication
    ) => {
      return action$.pipe(
            ofType(EventActionType.REQUEST_SEARCH_EVENTS_FOR_PLANTS),
            withLatestFrom(store$),
            mergeMap(([action, store]: [RequestSearchForEventsForPlants, AppState]) =>
            {
              return from(
                   axios.post(`${store.settings.server}/api/Events/eventSearchForPlants`,  
                    action.payload,
                    {
                      headers: {
                      'Content-Type': 'application/json'
                      }
                  }
                       ).then( response => response.data)
              )
              .pipe(
                  map((response: any) => {
                    return new RequestSearchForEventsForPlantsSuccess(response)
                  })
              )
                  .pipe(catchError(err => of(new RequestSearchForEventsForPlantsFailure(err))))
            })
          );
        };



    
export const eventEpics = combineEpics(
    RequestEventsForAllPlantsEpic$,
    RequestEventSearchForPlantsEpic$
  );