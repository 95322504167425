import { useSelector } from "react-redux";

import { AppState } from "../../core";

export const useOperationsActiveAlarmsAreLoading = () =>
  useSelector((state:AppState)=> state.operations.loading )

export const useActiveAlarmsWithCount = () =>
  useSelector((state: AppState) => state.operations.activeAlarmsWithCount);

  export const useTotalCountAllActiveAlarms = () =>
  useSelector((state: AppState) => state.operations.totalCountAllActiveAlarms);