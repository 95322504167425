import { Action } from "redux";

import { IActiveAlarmFetched } from "./";
import { IFailureAction, ISuccessAction } from "../../core";

export enum OperationsActionType {
  REQUEST_ALL_ACTIVE_ALARMS_FOR_PLANTS_BY_PLANT = "[Operations] Request All Active Alarms For Plants By Plant",
  REQUEST_ALL_ACTIVE_ALARMS_FOR_PLANTS_BY_PLANT_SUCCESS = "[Operations][Success] Request All Active Alarms For Plants By Plant",
  REQUEST_ALL_ACTIVE_ALARMS_FOR_PLANTS_BY_PLANT_FAILURE = "[Operations][Failure] Request All Active Alarms For Plants By Plant",
}

export class ActiveAlarmsRequestAllForPlantsByPlant implements Action {
  
  readonly type = OperationsActionType.REQUEST_ALL_ACTIVE_ALARMS_FOR_PLANTS_BY_PLANT;
  
  constructor(public payload: any) {}
}

export class ActiveAlarmsRequestAllForPlantsByPlantSuccess implements Action, ISuccessAction {
  readonly type = OperationsActionType.REQUEST_ALL_ACTIVE_ALARMS_FOR_PLANTS_BY_PLANT_SUCCESS;
  readonly success = true;
  constructor(public payload: IActiveAlarmFetched) {}
}

export class ActiveAlarmsRequestAllForPlantsByPlantFailure implements Action, IFailureAction {
  readonly type = OperationsActionType.REQUEST_ALL_ACTIVE_ALARMS_FOR_PLANTS_BY_PLANT_FAILURE;
  readonly failure = true;
  constructor(public payload: any) {}
}

export type OperationsActions =
  | ActiveAlarmsRequestAllForPlantsByPlant
  | ActiveAlarmsRequestAllForPlantsByPlantSuccess
  | ActiveAlarmsRequestAllForPlantsByPlantFailure
