import React from 'react';

import { Box, Pagination, TableBody, TableContainer, TableHead, TableCell, TableRow, Typography, Table } from '@mui/material';

import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useExpanded
} from 'react-table';

import { AddCustomerDialog } from '../../usermanagement/table/dialog/addCustomerDialog';
import { isNil } from 'lodash';

/**
 * @param data The data you wish to pass in
 * @param columns The columns related to the data in question
 * @param renderRowSubcomponent The subcomponent you wish to render (If any)
 * @param showSearchFilter Whether you wish to have search/filter bar on top or not
 * @param showHeader Whether you wish to have headers or not (title, name etc)
 * @param tableColor Color value in string
 * 
 * TODO: Create Enum for colors
 */
export interface IExpandableTable {
  data:any[];
  columns:any[];
  expandRows:boolean;
  rowSubComponent:[];
  showSearchFilter:boolean;
  showHeader:boolean;
}

/**
 * * Generic table and rows related to nested tables, table-inception (See userManagement for example-implementation)
 */

export const ExpandableTable = ({data, columns, showHeader=false, expandRows = false, showSearchFilter = false,  renderRowSubComponent, tableColor = "#D2E5EB", headerColor = ""}) =>{

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      rows,
      
      state: { 
        globalFilter,
         }
    } = useTable(
      {
        columns,
        data
      },
      useGlobalFilter,
      useSortBy,
      useExpanded,
      usePagination,
      useRowSelect,
    )

    return <TableContainer
     style={{
        maxHeight: 820,

      }}
      {...getTableProps()}
    >
      <Table
        stickyHeader
        {...getTableBodyProps()}
        sx ={{
          padding:"0px",
          borderSpacing: '0px 4px'
        }}
    >
      {showHeader&&<TableHead
        
      >

      {headerGroups.map((headerGroup, i) => {
        return <TableRow
          key = {i}

          >

          {headerGroup.headers.map((column, k) => {return <TableCell 
             sx = {{backgroundColor:headerColor}}
              key = {k}>
            
            <Typography sx = {{
                fontWeight: 400,
                fontSize: '16px',
                color:"#003366",
                fontStyle:"normal"
              }}>
              {column.render("Header")}
              </Typography>
            </TableCell>})
          }
        </TableRow>
      }
      )}
      </TableHead>}

      {rows.map((row:any, key:any)=>{
          prepareRow(row)

          return <TableBody key = {key}>
            <TableRow  {...row.getRowProps()} 
              sx = {{
              backgroundColor: tableColor,
                color:"#003366",
                fontWeight: 700
            }}>
                {row.cells.map( (c, k)=>{
                return <TableCell
                  
                  key = {k + 59}  sx = {{
                    color:"#003366",
                    padding:"20px",
                    fontSize: '21.3838px',
                    fontWeight: 700,
                }}>
                  {c.render("Cell")}
              </TableCell>
              } )} 
          </TableRow>
         
            {/* Collapsible content  i.e plants for example*/}
                
              { row.isExpanded? (
                    renderRowSubComponent({ row })
                ) : null
                }
                
        </TableBody>          
        })
      }
     
      </Table>
    </TableContainer>
}