import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { Typography } from '@material-ui/core';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import ClearIcon from '@mui/icons-material/Clear';
import CreateIcon from '@mui/icons-material/Create';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Button, Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { AppState, translationWithCapitalization, translationWithUppercase } from '../..';
import { SummaryCard } from '../../../browse/startpage/components';


export const NotFoundPage = () => {
    const history = useHistory();

      const FOUR_O_FOUR = translationWithUppercase("fourOFour")
      const COULD_NOT_FIND = translationWithCapitalization("couldNotFind")
      const WHAT_COULD_HAVE_CAUSED = translationWithCapitalization("whatCouldhaveCaused404")
      const REMOVED_PAGE_IN_QUESTION = translationWithCapitalization("removedPageInQuestion")
      const LINK_CLICKED_BROKEN = translationWithCapitalization("linkClickedBroken")
      const WRONG_ADDRESS = translationWithCapitalization("typedAddressWrongly")
      const WHAT_CAN_YOU_DO = translationWithCapitalization("whatCanYouDoFourOFour")
      const NOTIFY_BROKEN_LINK = translationWithCapitalization("notifyBrokenLink")
      const RETYPE_URL_FOUR_O_FOUR = translationWithCapitalization("retypeUrlFourOFour")
      const CLICK_BUTTON_BELOW = translationWithCapitalization("clickButtonBelowFourOFour")
      const TAKE_ME_HOME = translationWithCapitalization("takeMeHome")


    const handleOnSubmit = () => {
      history.push(`/`);
    };
  
    return (
      <Grid 
          gap={2}
          container 
          justifyContent="center"
          >
            <Typography 
              variant="h1" 
              component="h1" 
              sx = {{
                fontFamily: '"Comic Sans"',
              }}
              >
              {FOUR_O_FOUR}
            </Typography>
      


            <SummaryCard>
            <Typography
                variant="h3" 
                component="h3" >
                {COULD_NOT_FIND}
              </Typography>

              <br/>
                <Typography
                  variant="h4" 
                  component="h4" >
                    {WHAT_COULD_HAVE_CAUSED}
                </Typography>
                  <List
                      aria-labelledby="decorated-list-demo"
                      sx={{ '--List-decorator-size': '32px' }}
                    >
                    <ListItem>
                      <ListItemIcon>
                      <ClearIcon />
                    </ListItemIcon>
                        {REMOVED_PAGE_IN_QUESTION}
                    </ListItem>
                    <ListItem>
                    <ListItemIcon>
                      <HeartBrokenIcon sx={{ color: "pink" }}/>
                    </ListItemIcon>
                        {LINK_CLICKED_BROKEN}
                    </ListItem>
                    <ListItem>
                    <ListItemIcon>
                      <QuestionMarkIcon color = "success"/>
                    </ListItemIcon>
                        {WRONG_ADDRESS}
                    </ListItem>
                  </List>
                      <Typography
                        variant="h4" 
                        component="h4" >
                     {WHAT_CAN_YOU_DO}
                    </Typography>
                    <List
                        aria-labelledby="decorated-list-demo"
                        sx={{ '--List-decorator-size': '32px' }}
                      >

                      <ListItem>
                      <ListItemIcon>
                      <EmailIcon />
                    </ListItemIcon>
                        {NOTIFY_BROKEN_LINK}
                      </ListItem>
                      <ListItem>
                      <ListItemIcon>
                        <CreateIcon />
                      </ListItemIcon>
                      {RETYPE_URL_FOUR_O_FOUR}
                    </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        <HomeIcon />
                      </ListItemIcon>
                         {CLICK_BUTTON_BELOW}
                      </ListItem>

                    </List>
                    <Button 
                      onClick = {handleOnSubmit}
                      variant="contained">
                    {TAKE_ME_HOME}
                  </Button>
                </SummaryCard>
            
        </Grid> 
    );
  };
  