import axios, { AxiosError } from "axios";
import dayjs from "dayjs";
import qs from "qs";
import { useEffect, useState } from "react";
import { IPlant } from "../../../plant";
import { useCurrentPlant } from "../../../settings/hook";
import { IPlantDTO } from "../../usermanagement";

/**
 * Retrieves all invoices for a given set of plants...
 * could probably be made more generic
 * @param apiUrl 
 * @param plants 
 * @returns 
 */
export const useGetInvoices = (apiUrl:string, plants:IPlantDTO[]) => {
    const [invoices, setInvoices] = useState<IInvoice[]|[]>([]);
    const [error, setError] = useState<AxiosError>();
    const [loading, setLoading] = useState<boolean>(true);
    const [plantsToQuery, setPlantsToQuery] = useState<IPlant[] | IPlantDTO[]>([])
    const currentPlant = useCurrentPlant();

  useEffect(() => {
    if(currentPlant && currentPlant.plantName !== 'All') {
        const cPlants: IPlantDTO[] = []
        const cPlant: IPlantDTO = {
          id: currentPlant.plantId,
          shortName: currentPlant.plantName,
          name: ''
        }
        cPlants.push(cPlant)
        setPlantsToQuery(cPlants)
      } else {
        setPlantsToQuery(plants)
      }
  }, [currentPlant, plants])

    const fetchData = () => {
        setLoading(false);
        if(plantsToQuery.length === 0) return
    axios.get(`${apiUrl}/plant/invoice/plants`,  {
      params:{
        plant:[...plantsToQuery.map(p=>p.shortName)]
       },
       paramsSerializer: (params:any) => {
         return qs.stringify(params)
       }
     }   )
    .then( (response:any) => {
      var arr:IInvoice[] = []

      for (const key of Object.keys(response.data)) {
              var obj:IInvoice = {...response.data[key],
                "sessionStart": dayjs(response.data[key].sessionStart).format('YYYY.MM.DD HH:mm'),
              "price": response.data[key].price.toFixed(2),
              "duration": response.data[key].elapsedHours,
              "line": response.data[key].lineName,
              "consumedPower": response.data[key].consumedPower.toFixed(2), 
              "totalSessionPrice":response.data[key].totalSessionPrice.toFixed(2),
              "sessionEnd": dayjs(response.data[key].sessionEnd).format('YYYY.MM.DD HH:mm'),
              }
              arr.push(obj)
      }

      setInvoices(arr);
    }
  )
    .catch( (e:AxiosError)=>{
      setError(e);
    } )
    .finally( ()=>{
      setLoading(false);
    } )
    };

    useEffect(() => {
        fetchData();
    }, [apiUrl,plantsToQuery]);

    // custom hook returns value
    return { invoices, error, loading };
};
