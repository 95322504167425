
export const USER_MANAGEMENT_ENDPOINTS = {
    ADD_USER_TO_PLANT: "",
    REVOKE_PLANT_ACCESS_FOR_USER_BY_ID: "{plant}/revokeAllAccess/{userId}",

    ADD_USER_TO_DATABASE:"",
    ADD_PLANT_TO_DATABASE:"/api/Access/upsertUser",
    ​CLONE_PLANT_ACCESS: "/api​/Access​/clonePlantAccess",

    REMOVE_USER_FROM_PLANT:"",

    GET_ALL_ROLE_DEFINITIONS:"/Access/roles",

    GET_ALL_USERS:"/Access/allUsers",

    GET_ALL_CUSTOMERS_WITH_PLANTS_FOR_SUPERUSER:"/Access/customer/superuser",


}