import { Action } from "redux";
import { ISuccessAction, IFailureAction } from "../core";
import { IPlant } from "./";

// TODO: Rename all plant-things to operations

export enum PlantActionType {
  REQUEST_ALL = "[Plant] Request All",
  REQUEST_ALL_SUCCESS = "[Plant][Success] Request All",
  REQUEST_ALL_FAILURE = "[Plant][Failure] Request All",

  SET_PLANT = "[Plant] Set Plant",
  SET_HMI_MONITORING_BASE_URL = "[HMI] Set HMI Monitoring Base URL"
}

//#region Plant request
export class PlantRequestAll implements Action {
  readonly type = PlantActionType.REQUEST_ALL;
}

export class PlantRequestAllSuccess implements Action, ISuccessAction {
  readonly type = PlantActionType.REQUEST_ALL_SUCCESS;
  readonly success = true;
  constructor(public payload: IPlant[]) {}
}

export class PlantRequestAllFailure implements Action, IFailureAction {
  readonly type = PlantActionType.REQUEST_ALL_FAILURE;
  readonly failure = true;
  constructor(public payload: any) {}
}

//#endregion


export class SetHMIMonitoringBaseURL implements Action {
  readonly type = PlantActionType.SET_HMI_MONITORING_BASE_URL;
  constructor(public payload: any) {}

}

export type PlantActions =
  | PlantRequestAll
  | PlantRequestAllSuccess
  | PlantRequestAllFailure
  | SetHMIMonitoringBaseURL

