import { CardContent, Grid, Paper } from '@mui/material';
import React from 'react';

export const SummaryCard = ({ children }: any) => {
  return (<Grid
    item
    zeroMinWidth
    sx={{
      padding: "50px"
    }}
    component={Paper}
  >
    <CardContent>
      {children}
    </CardContent>

  </Grid>)
}