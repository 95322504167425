import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { translationWithUppercase } from '../../../../core';

import { IShipRegistry } from '../../interfaces';
import { selectStyles } from './dropDownStyles';

interface IDropDownprops{
    handleSetProperty:Function;
    listOfProperties: IShipRegistry[];
}

interface ISelectProps{
    value:string|number;
    label:string;
    initialValue?:number|undefined;
}

/**
 * TODO: Use GUID as ID, and then find index (number) from shipregitry using that?
 */

export const DropDown = (props:IDropDownprops)=>{
    const {handleSetProperty, listOfProperties} = props;
    const [options, setOptions] = useState<ISelectProps[]|any[]>([]);

    const SELECT_A_SHIP_REGISTER = translationWithUppercase("selectAShipRegister");

    useEffect( ()=>{
        let temparray:ISelectProps[] = [];

        listOfProperties.map( (shipReg,key:number)=>{

            var o:ISelectProps= {
                value: key,
                label: shipReg.name
            }
            temparray.push(o);
        } )
        setOptions(temparray);

    },[])

    return  <ReactSelect onChange =  { (e)=>{ 
                handleSetProperty(e.value)}}
                placeholder = {listOfProperties.length === 1 ? listOfProperties[0].name : SELECT_A_SHIP_REGISTER}
                styles={selectStyles}
                options = {options}
            />
}