import React, { useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';
import {
  AppBar,
  Button,
  Grid,
  Icon,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  Popover,
  Toolbar,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { AppState, translationWithCapitalization } from '..';
import eventIcon from '../../../assets/icons/eventicon.svg';
import errorIcon from '../../../assets/icons/hvit_Error.svg';
import userIcon from '../../../assets/icons/hvit_User.svg';
import errorIconRed from '../../../assets/icons/red_Error.svg';
import logoPNG from '../../../assets/img/logo.png';
import '../../../public/translations/i18n';
import { AlarmOptions, IAlarmSearchRequest, RequestActiveAlarmCountForAllPlants } from '../../browse/alarmpage';
import { routers } from '../../constants/constants';
import { useApiUrl, useCurrentPlant, useFetchActiveAlarmsFromCosmosDB } from '../../settings/hook';
import { usePlantsForOperationRoles, useUser, useUserHasLoaded } from '../../user/hooks';

import { IPlantOverview } from '../../plant';
import { StatusForApiCall } from '../apiCall';

const drawerWidth = 240;

const useStyles = makeStyles(
  (theme: any) => ({
    root: {
      display: 'flex',
      gap: '5px',
    },
    // necessary for content to be below app bar
    content: {
      flexGrow: 1,
      transition: 'margin-left .2s',
      marginLeft: (contentWidth: number) => `${contentWidth}px`,
    },

    page: {
      width: '100%',
    },

    list: { paddingTop: '0px', paddingBottom: '0px' },

    toolBar: {
      zIndex: theme.drawer.zIndex + 2,
      elevation: 0,
      backgroundColor: 'primary.main',
    },

    drawer: {
      border: 'none',
      zIndex: theme.drawer.zIndex,
      width: drawerWidth,
      overflow: 'hidden',
    },

    drawerPaper: {
      zIndex: theme.drawer.zIndex,
      width: drawerWidth,
      overflow: 'hidden',
      border: 'none',
    },
  }),
  { index: 1 },
);

export const MainHeader = () => {
  const history = useHistory();
  const classes = useStyles();
  const plants = usePlantsForOperationRoles();
  const userData = useUser();
  const dispatch = useDispatch();
  const userIsLoading = useUserHasLoaded();
  const currentPlant = useCurrentPlant();
  const numberOfActiveAlarms = useSelector((state: AppState) => state.alarm.totalCountAllActiveAlarms);
  const activeAlarms = useSelector((state: AppState) => state.alarm.activeAlarms);

  useEffect(() => {
    if (plants.length > 0) {
      var plantsToString = plants
        .filter(p => p.shortName.includes(currentPlant.plantName))
        .map(item => {
          return item['id'];
        });

      var payload: IAlarmSearchRequest = {
        plants: [...plantsToString],
        searchText: '',
        locale: 'ENG',
        fetchTotalCount: true,
        pagination: {
          pageNumber: 0,
          pageSize: 100,
        },
        isActive: AlarmOptions.ActiveAlarms,
      };

      dispatch(new RequestActiveAlarmCountForAllPlants(payload));
    }
  }, [userIsLoading, plants, currentPlant]);

  return (
    <>
      <AppBar elevation={0}>
        <Toolbar>
          <a href="/">
            <img src={logoPNG} alt="Logo" />
          </a>
          <Grid container justifyContent="center">
            <Typography
              sx={{
                marginLeft: '20px',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '24px',
                lineHeight: '33px',
              }}
            >
              {userData.customer}
            </Typography>
          </Grid>
          <Grid
            container
            spacing={1}
            justifyContent="flex-end"
            sx={{
              width: '80%',
            }}
          >
            <Grid item>
              <IconButton
                onClick={() => {
                  return history.push(routers.EVENTS_PAGE);
                }}
              >
                <Icon>
                  <img src={eventIcon} height={25} width={25} style={{}} />
                </Icon>
              </IconButton>
            </Grid>
            <Grid item>{plants.length > 0 && <WarningButton />}</Grid>
            {/* <Grid item>
              <IconButton >
                <Icon>
                  <img src={notificationIconWhite} height={25} width={25} style={{
                  }}/>
                </Icon>
              </IconButton>
            </Grid>   */}
            <Grid item>
              <UserButton />
            </Grid>
            <Grid item sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '18px',
                  margin: 'auto',
                }}
              >
                {userData.name}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

interface IWarningButtonProps {}

const WarningButton = (props: any) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const plants = usePlantsForOperationRoles();
  const currentPlant = useCurrentPlant();

  const [plantsFiltered, setPlantsFiltered] = useState([]);
  const apiUrl = useApiUrl();

  useEffect(() => {
    let pFiltered = currentPlant.plantId === 'All' ? plants : plants.filter(x => x.id.includes(currentPlant.plantId));
    setPlantsFiltered(pFiltered);
  }, [plants, currentPlant]);

  const ALL_ALARMS = t('viewAllAlarms');

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { status, data: fetchedAlarmsWithCount } = useFetchActiveAlarmsFromCosmosDB(
    plantsFiltered,
    apiUrl + '/Alarms/alarmSearchForPlants',
    10000,
  );

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (status === StatusForApiCall.SUCCESS) {
    return (
      <>
        <Button
          onClick={handleClick}
          aria-describedby={id}
          style={{
            color: 'black',
          }}
        >
          <Typography sx={{ color: 'white', opacity: '50%' }}>{fetchedAlarmsWithCount.totalCount}</Typography>
          <Icon>
            {fetchedAlarmsWithCount.totalCount > 0 ? (
              <img src={errorIconRed} height={25} width={25} style={{}} />
            ) : (
              <img
                src={errorIcon}
                height={25}
                width={25}
                style={{
                  opacity: '50%',
                }}
              />
            )}
          </Icon>
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={{ p: 2 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {Array.isArray(fetchedAlarmsWithCount.searchAlarms) &&
            fetchedAlarmsWithCount.searchAlarms.map((alarm, key) => {
              return (
                <ListItemButton
                  sx={{ p: 2 }}
                  key={key}
                  onClick={() => {
                    history.push(routers.ALARM_PAGE);
                  }}
                >
                  <ListItemIcon>
                    <Icon>
                      <img src={errorIconRed} height={24} width={24} />
                    </Icon>
                  </ListItemIcon>
                  <Typography>
                    {alarm.plant.toUpperCase()} - {dayjs(alarm.lastEventTime).format('DD/MM/YYYY')} -{' '}
                    {dayjs(alarm.lastEventTime).format('HH:mm')}
                  </Typography>
                </ListItemButton>
              );
            })}
          <ListItemButton
            sx={{ p: 2 }}
            onClick={() => {
              history.push(routers.ALARM_PAGE);
            }}
          >
            <Typography>{ALL_ALARMS.toUpperCase()}</Typography>
          </ListItemButton>
        </Popover>
      </>
    );
  }

  if (status === StatusForApiCall.FAILURE) {
    return <div></div>;
  } else {
    return <div></div>;
  }
};

const UserButton = (props: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const history = useHistory();
  const { instance } = useMsal();
  const { t } = useTranslation();

  return (
    <>
      <Button
        onClick={handleClick}
        aria-describedby={id}
        style={{
          color: 'black',
        }}
      >
        <Icon>{<img src={userIcon} height={25} width={25} style={{}} />}</Icon>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ p: 2 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List>
          <ListItemButton
            onClick={() => {
              history.push('/accountsettings');
            }}
          >
            <Typography>{translationWithCapitalization('accountSettings')}</Typography>
          </ListItemButton>
          <ListItemButton onClick={() => signOutClickHandler(instance)}>
            <Typography>{translationWithCapitalization('signOut')}</Typography>
          </ListItemButton>
        </List>
      </Popover>
    </>
  );
};

export const convertListOfPlantObjectsToListOfPlantIds = (plants: IPlantOverview[]): string[] => {
  return plants.map(item => {
    return item['shortName'];
  });
};

function signOutClickHandler(instance: any) {
  instance.logoutPopup({
    postLogoutRedirectUri: '/',
    mainWindowRedirectUri: '/', // redirects the top level app after logout
  });
}
