import { useTranslation } from "react-i18next";
import { capitalize } from "lodash";

export const translationWithCapitalization = (word:string) => {
  const {t} = useTranslation();
  const wordTranslated = t(word);
  return capitalize(wordTranslated);
}

export const translationWithUppercase = (word:string) => {
  const {t} = useTranslation();
  return t(word).toUpperCase();
}

import { LocaleType } from ".";
import { ISortable, Text } from "../browse/interfaces";
import { ALTERNATIVE_LOCALES_NAME } from "../constants/constants";

export const convertId = (name: string) => name.replace(/\./g, "__");

export const convertToIdKeyValueObj = (
  array: { [key: string]: any }[],
  keyName = "id",
  ...restKeyNames: string[]
): { [key: string]: any } => {
  const compositeKeyArray = [keyName, ...restKeyNames];
  return array.reduce(
    (acc, v) => ({
      ...acc,
      [getObjectValuesFromKeys(v, compositeKeyArray)]: v
    }),
    {}
  );
};

const getObjectValuesFromKeys = (obj: any, keys: string[]) => {
  const values = keys.map(key => obj[key]);
  const jointValue = values.join("-");
  return convertId(jointValue);
};

/**
 *
 * @param id
 */
export const getReduxStatePath = (id: string) => {
  const matchResult = id.match(/^([^.]*\.){2}[^.]*/g);
  if (!matchResult) return id;

  const rootId = matchResult[0];
  const ancestorIds = [];
  let tmpId = rootId;
  do {
    ancestorIds.push(convertId(tmpId));
    tmpId = id.substring(0, id.indexOf(".", tmpId.length + 1));
  } while (tmpId.length !== 0);

  if (rootId !== id) {
    ancestorIds.push(convertId(id));
  }

  return ancestorIds.join(".children.");
};

export const getDescriptionWithLocale = (
  obj: { texts: { [key: string]: Text } } | undefined,
  locale: LocaleType,
  defaultValue = ""
) => {
  const content = obj?.texts?.[`Description-${locale}`]?.content;
  if (content) return content;

  const alternativeLocale = locale && ALTERNATIVE_LOCALES_NAME[locale];
  return (
    obj?.texts?.[`Description-${alternativeLocale}`]?.content ?? defaultValue
  );
};

export const sortList = <T extends ISortable>(locale: LocaleType) => (
  a: T,
  b: T
) => {
  const res1 = a.sortOrder - b.sortOrder;
  if (res1 !== 0) return res1;

  return getDescriptionWithLocale(a, locale).localeCompare(
    getDescriptionWithLocale(b, locale)
  );
};

/*********************** URL **************************/
export const getUrlSearchParams = (url: string) => {
  const index = url.indexOf("?");
  const searchPart = index === -1 ? "" : url.substring(index);
  return new URLSearchParams(searchPart);
};

export const getBranchIdFromUrl = (url: string) => {
  if (url.indexOf("?") === -1) {
    if (url.endsWith("/")) {
      const elements = url.split("/");
      return elements[elements.length - 2];
    } else {
      return url.substring(url.lastIndexOf("/") + 1);
    }
  } else {
    return url.substring(url.lastIndexOf("/") + 1, url.indexOf("?"));
  }
};

/**
 * Kun siste del av navnet (Energy) hvis description mangler.
 eg GSR.Main.Energy
 * @param objectId 
 */
export const getDefaultObjectDescription = (objectId: string) =>
  objectId.split(".").slice(-1)[0];
